import { selectors as sclSelectors } from 'smashcut-client-lib';
import { actionType } from 'smashcut-client-lib/constants';
import { types as discussionTypes } from '../../reducers/dashboard/discussionTypes';
import { subscribeToProjects } from 'smashcut-client-lib/actions/subscribeToProjects';
import {
  discussionMessageReceived,
  loadChannels,
  insertDiscussionData
} from 'smashcut-client-lib/actions/discussionsActions';
import moment from 'moment';

const prefix = 'SELECT_PROGRAM';

export const types = {
  SHOW_POPUP: `${prefix}/SHOW_POPUP`,
  HIDE_POPUP: `${prefix}/HIDE_POPUP`,
  SELECT_USER_PROGRAM: `${prefix}/SELECT_USER_PROGRAM`,
  INITIATE_PROGRAM_CHANGE: `${prefix}/INITIATE_PROGRAM_CHANGE`,
  PROGRAM_CHANGED: `${prefix}/PROGRAM_CHANGED`
};

const initialState = {
  isPopupVisible: false,
  isLoading: false
};

export const selectProgramReducer = (state = initialState, action) => {
  let nextState = state;
  switch (action.type) {
    case types.SHOW_POPUP:
      nextState = { ...state, isPopupVisible: true };
      break;

    case types.SELECT_USER_PROGRAM:
    case types.HIDE_POPUP:
      nextState = { ...state, isPopupVisible: false };
      break;

    case types.INITIATE_PROGRAM_CHANGE:
      nextState = { ...state, isLoading: true };
      break;

    case types.PROGRAM_CHANGED:
      nextState = { ...state, isLoading: false };
      break;
  }
  return nextState;
};

function selectProgram(userProgramId) {
  return (dispatch, getState, { api, lessonApi }) => {
    dispatch({ type: types.INITIATE_PROGRAM_CHANGE });

    const state = getState();
    const user = sclSelectors.getCurrentUser(state);
    api.user
      .setCurrentUserProgramId(user.id, userProgramId)
      .then(result => {
        const userChangedProps = result.data.setCurrentUserProgramId;
        dispatch({
          type: actionType.UPDATE_ENTITY_USER,
          id: userChangedProps.id,
          payload: {
            currentUserProgramId: userChangedProps.currentUserProgramId
          }
        });
        return userChangedProps.currentUserProgramId;
      })
      .then(userProgramId => {
        subscribeToProjects(lessonApi, userProgramId, dispatch);

        api.discussions.unsubscribe();

        dispatch({
          type: discussionTypes.CLEAR_ALL
        });

        api.discussions.subscribe(
          user.id,
          userProgramId,
          insertDiscussionData(dispatch),
          discussionMessageReceived(dispatch, api, getState),
          channels => {
            loadChannels(dispatch, user, channels);
          }
        );
      })
      .then(() => dispatch({ type: types.PROGRAM_CHANGED }));

    dispatch({ type: types.SELECT_USER_PROGRAM });
  };
}

function showPopup() {
  return { type: types.SHOW_POPUP };
}

function hidePopup() {
  return { type: types.HIDE_POPUP };
}

export const actions = {
  hidePopup,
  selectProgram,
  showPopup
};

function getOptions(state) {
  const userPrograms = sclSelectors.getCurrentUserPrograms(state);
  if (!userPrograms || !userPrograms.length) {
    return [];
  }

  const programInstanceIds = {};
  const sortedItems = userPrograms
    .filter(up => {
      if (programInstanceIds[up.programInstance.id]) {
        return false;
      }
      programInstanceIds[up.programInstance.id] = true;
      return true;
    })
    .map(p => ({
      id: p.id,
      label: p.programInstance.title,
      hasEnded: !!p.programInstance.hasEnded,
      programInstanceId: p.programInstanceId,
      ...getStartAndEndDate(p)
    }))
    .filter(item => !!item.startDate)
    .sort(sortByDatesAscending)
    .reverse();

  return sortedItems;

  // --------------------------------------------
  function getStartAndEndDate(userProgram) {
    if (!userProgram) {
      console.warn('SelectProgram: userProgram is falsy');
      return null;
    }
    const programInstance = userProgram.programInstance;
    const program = programInstance.program;
    if (!program.paces) {
      console.warn('SelectProgram: Paces not found', programInstance);
      return null;
    }
    const pace = program.paces[programInstance.pace];
    if (!pace) {
      console.warn('SelectProgram: Pace not found', programInstance);
      return null;
    }
    const startDate = moment(programInstance.startDate);
    if (!startDate.isValid()) {
      console.warn(
        'SelectProgram: Error parsing startDate from program instance',
        programInstance
      );
      return null;
    }

    const endDate = moment(startDate).add(pace.weeks.length * 7, 'days');
    return { startDate, endDate };
  }

  function sortByDatesAscending(a, b) {
    if (a.startDate < b.startDate) {
      return -1;
    }
    if (a.startDate > b.startDate) {
      return 1;
    }
    if (a.endDate < b.endDate) {
      return -1;
    }
    if (a.endDate > b.endDate) {
      return 1;
    }
    return 0;
  }
}

function getSelectedOptionId(state) {
  const currentUserProgram = sclSelectors.getCurrentUserProgram(state);
  return currentUserProgram && currentUserProgram.id;
}

function getIsPopupVisible(state) {
  return state.selectProgram.isPopupVisible;
}

function getProgramChangeStatus(state) {
  return state.selectProgram.isLoading;
}

export const selectors = {
  getIsPopupVisible,
  getOptions,
  getSelectedOptionId,
  getProgramChangeStatus
};
