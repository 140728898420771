export const SORT = {
  OLDEST: 'OLDEST',
  NEWEST: 'NEWEST'
};

export const FILTER = {
  ALL: 'ALL',
  VIDEOS: 'VIDEOS',
  IMAGES: 'IMAGES',
  SCREENPLAYS: 'SCREENPLAYS',
  PDF: 'PDF',
  GLB: 'GLB',
  OBJECT3D: 'OBJECT3D',
  PROJECTS: 'PROJECTS',
  MY_FILES: 'MY_FILES'
};

export const UPLOAD_TYPE = {
  VIDEO: 'VIDEO',
  SCREENPLAY: 'SCREENPLAY',
  UNKNOWN: 'UNKNOWN',
  IMAGE: 'IMAGE',
  GLB: 'GLB',
  OBJECT3D: 'OBJECT3D',
  MY_FILE: 'MY_FILE',
  INTRO_VIDEO: 'INTRO_VIDEO',
  VIDEO_MESSAGE: 'VIDEO_MESSAGE'
};

export const FILE_TYPE = {
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE',
  MULTI_IMAGE: 'MULTI_IMAGE',
  FOUNTAIN: 'FOUNTAIN',
  PDF: 'PDF',
  GLB: 'GLB',
  OBJECT3D: 'OBJECT3D',
  UNKNOWN: 'UNKNOWN',
  NONSUPPORTED: 'NONSUPPORTED',
  FOLDER: 'FOLDER'
};
