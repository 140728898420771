export const stripSpecialCharacters = s => {
  let result = '';
  for (let i = 0; i < s.length; i++) {
    const charCode = s.charCodeAt(i);
    if (
      (charCode >= 65 && charCode <= 90) ||
      (charCode >= 97 && charCode <= 122) ||
      (charCode >= 48 && charCode < 57) ||
      charCode == '32' ||
      charCode == '46'
    ) {
      result += s[i];
    }
  }
  return result;
};
