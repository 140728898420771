import { gql } from '@apollo/client';

export function testDbStructure(apolloClient) {
  const query = gql`
    query testDbStructure {
      testDbStructure
    }
  `;

  return async () => {
    await apolloClient.query({
      query
    });
  };
}
