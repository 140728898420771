import { loadLoggedInUser } from './loadLoggedInUser';
import { insertUserData } from './insertUserData';
import { objectsToMap } from 'common/objectsToMap';

const formatEntities = entities => {
  const notNullEntities = entities.map(entity =>
    Object.keys(entity).reduce((acc, itemKey) => {
      if (entity[itemKey]) {
        acc[itemKey] = entity[itemKey];
      }
      return acc;
    }, {})
  );
  return objectsToMap(notNullEntities);
};

export const loadUserData = userId => {
  return (dispatch, getState, { api }) => {
    if (!userId) {
      return;
    }
    return api
      .loadUserData(userId)
      .then(data => {
        const {
          userPrograms,
          programInstances,
          projects,
          screenplays,
          imageProjects,
          nonSupportedFileProjects,
          object3DProjects,
          programs
        } = data;
        const userData = {
          userPrograms: formatEntities(
            userPrograms.map(up => ({
              ...up,
              user: up.user.id
            }))
          ),
          programInstances: formatEntities(programInstances),
          projects: formatEntities(projects),
          screenplays: formatEntities(screenplays),
          imageProjects: formatEntities(imageProjects),
          nonSupportedFileProjects: formatEntities(nonSupportedFileProjects),
          object3DProjects: formatEntities(object3DProjects),
          programs:
            programs &&
            programs.reduce((acc, pr) => {
              const id = pr.id;
              let item = {};
              item['id'] = id;
              item['versions'] = {
                [pr.version]: pr
              };
              acc[id] = item;
              return acc;
            }, {}),
          users: objectsToMap(userPrograms.map(up => up.user))
        };
        dispatch(insertUserData(userData));
        return data.user;
      })
      .then(user => dispatch(loadLoggedInUser(user)))
      .then(res => res.payload.user);
  };
};
