import { gql } from '@apollo/client';

export const addConfirm = apolloClient => (item, type) => {
  const pl = item.gotoLessonPayload;

  const mutation = gql`
    mutation addConfirm($input: AddConfirmInput!) {
      addConfirm(input: $input)
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        programId: pl.programId,
        courseId: pl.courseId,
        lessonId: pl.lessonId,
        mainAreaId: pl.mainAreaId,
        itemId: pl.itemId,
        type
      }
    }
  });
};
