import { gql } from '@apollo/client';
import { objectsToMap } from 'common/objectsToMap';
import { omit, keys, isEmpty } from 'lodash';

const GET_DISCUSSIONS_DATA = gql`
  query getDiscussionsDataQuery($userId: String, $currentEnrolleeId: String) {
    discussionsData(userId: $userId, currentEnrolleeId: $currentEnrolleeId) {
      messages {
        id
        isStaffMessage
        isFileSharingMessage
        programInstanceId
        lastTimeEdited
        files {
          id
          baseUrl
          thumbnailUrl
          vttUrl
          commentSectionId
          created
          description
          downloadUrl
          duration
          fileType
          hlsUrl
          uploadType
          dashUrl
          projectLink
          folder
          isPreloaded
          status
          owner {
            email
            fullName
            id
            isStaff
          }
          imageIndex
          images {
            uploadId
            downloadUrl
          }
          title
        }
        video {
          id
          ownerId
          uploadType
          uploadPath
          fileName
          fileType
          fileSize
          fileMimeType
          uploadDurationMs
          description
          downloadUrl
          status
        }
        authorId
        receiverId
        created
        text
        isGroupMessage
        sharedComment {
          author {
            avatar
            fullName
          }
          comment {
            created
            msg
          }
        }
      }
      lastReads {
        id
        userId
        receiverId
        lastRead
      }
      groupId
      channels {
        id
        name
        avatar
        isGroupChat
        isAdmin
        isMentor
        isStaff
        roleLabel
      }
    }
  }
`;

const listenDiscussionMessageSubscription = gql`
  subscription onDiscussionMessage($userId: String) {
    onDiscussionMessage(userId: $userId) {
      id
      authorId
      receiverId
      isStaffMessage
      created
      text
      isGroupMessage
      isFileSharingMessage
      programInstanceId
      lastTimeEdited
      sharedComment {
        author {
          avatar
          fullName
        }
        comment {
          created
          msg
        }
      }
      files {
        id
        baseUrl
        commentSectionId
        created
        dashUrl
        description
        downloadUrl
        duration
        fileType
        hlsUrl
        ownerId
        projectLink
        spriteUrl
        thumbnailUrl
        title
        uploadType
        vttUrl
        projectLink
        folder
        isPreloaded
        images {
          downloadUrl
          uploadId
        }
      }
      video {
        id
        ownerId
        uploadType
        uploadPath
        fileName
        fileType
        fileSize
        fileMimeType
        uploadDurationMs
        description
        downloadUrl
        status
      }
    }
  }
`;

let messagesSubscriptions = {};

const unsubscribeFromDiscussions = () => {
  keys(messagesSubscriptions).forEach(userId => {
    if (messagesSubscriptions[userId]) {
      messagesSubscriptions[userId].unsubscribe();
      delete messagesSubscriptions[userId];
    }
  });
};

const subscribeToMessages = apolloClient => (userId, messageCallback) => {
  messagesSubscriptions[userId] = apolloClient
    .subscribe({
      query: listenDiscussionMessageSubscription,
      variables: { userId }
    })
    .subscribe({
      next(data) {
        const message = data.data.onDiscussionMessage;
        if (message.created) {
          messageCallback(message);
        }
      },
      error(err) {
        console.error('Error subscribing to notifications: ', err);
      }
    });
};

const subscribe = apolloClient => async (
  userId,
  currentEnrolleeId,
  callback,
  messageCallback,
  channelsCallback
) => {
  if (!currentEnrolleeId) {
    return;
  }

  subscribeToMessages(apolloClient)(userId, messageCallback);
  const discussionsDataResult = await apolloClient.query({
    query: GET_DISCUSSIONS_DATA,
    variables: { userId, currentEnrolleeId },
    fetchPolicy: 'network-only'
  });

  if (!discussionsDataResult) {
    return;
  }

  const discussionsData = discussionsDataResult.data.discussionsData || {};
  const { messages, lastReads, channels, groupId } = discussionsData;
  if (!lastReads) {
    console.log(JSON.stringify(discussionsDataResult));
  }
  const mapLastReads = (lastReads || []).map(lr => ({
    ...lr,
    id: lr.receiverId
  }));

  channelsCallback(channels);

  subscribeToMessages(apolloClient)(groupId, messageCallback);

  const discussionMessagesMap = objectsToMap(messages);
  const lastReadsMap = objectsToMap(mapLastReads);
  callback(discussionMessagesMap, lastReadsMap);
};

const updateLastRead = apolloClient => async (
  receiverId,
  userId,
  programInstanceId
) => {
  const mutation = gql`
    mutation updateDiscussionLastRead(
      $receiverId: ID!
      $userId: ID!
      $programInstanceId: String
    ) {
      updateDiscussionLastRead(
        receiverId: $receiverId
        userId: $userId
        programInstanceId: $programInstanceId
      ) {
        id
        userId
        receiverId
        lastRead
      }
    }
  `;

  return apolloClient
    .mutate({
      mutation,
      variables: {
        receiverId,
        userId,
        programInstanceId
      }
    })
    .then(response => response.data.updateDiscussionLastRead);
};

const messageFragment = `
        id
        authorId
        receiverId
        isStaffMessage
        isFileSharingMessage
        created
        text
        isGroupMessage
        lastTimeEdited
        programInstanceId
        files {
          id
          baseUrl
          commentSectionId
          created
          dashUrl
          description
          downloadUrl
          duration
          fileType
          hlsUrl
          ownerId
          projectLink
          spriteUrl
          thumbnailUrl
          title
          uploadType
          status
          vttUrl
        }
        video {
          id
          ownerId
          uploadType
          uploadPath
          fileName
          fileType
          fileSize
          fileMimeType
          uploadDurationMs
          description
          downloadUrl
          status
        }
        sharedComment {
          authorId
          comment {
            id
          }
        }
      `;

const addMessage = apolloClient => (
  receiverId,
  authorId,
  text,
  files,
  video,
  isGroupMessage,
  programInstanceId,
  sharedComment
) => {
  const mutation = gql`
    mutation addDiscussionMessage($input: DiscussionMessageInput) {
      addDiscussionMessage(input: $input) {
        ${messageFragment}
      }
    }
  `;
  const input = {
    receiverId,
    authorId,
    text,
    isGroupMessage,
    programInstanceId
  };
  if (sharedComment) {
    input.sharedComment = sharedComment;
  }

  if (!isEmpty(files)) {
    const mapFile = f => {
      const newFile = {
        ...f,
        images: (f.images || []).map(i => omit(i, '__typename'))
      };
      delete newFile.__typename;
      return newFile;
    };

    input.files = files.map(mapFile);
  }

  if (video) {
    const v = { ...video };
    delete v.__typename;
    input.video = v;
  }

  return apolloClient
    .mutate({
      mutation,
      variables: { input }
    })
    .then(response => response.data.addDiscussionMessage);
};

const updateMessage = apolloClient => (id, text, programInstanceId, userId) => {
  const mutation = gql`
    mutation updateDiscussionMessage($input: UpdateDiscussionMessageInput) {
      updateDiscussionMessage(input: $input) {
        ${messageFragment}
      }
    }
  `;

  const input = {
    id,
    text,
    programInstanceId,
    userId
  };

  return apolloClient
    .mutate({
      mutation,
      variables: { input }
    })
    .then(response => response.data.updateDiscussionMessage);
};

const shareMedia = apolloClient => (
  userId,
  userChannelIds,
  groupChannelId,
  text,
  files,
  programInstanceId,
  shouldNotify
) => {
  const mutation = gql`
    mutation shareMedia($input: ShareMediaInput) {
      shareMedia(input: $input)
    }
  `;

  const input = {
    authorId: userId,
    files,
    text,
    userChannelIds,
    groupChannelId,
    programInstanceId,
    shouldNotify
  };

  if (!isEmpty(files)) {
    const mapFile = f => {
      const newFile = {
        ...f,
        images: (f.images || []).map(i => omit(i, '__typename'))
      };
      delete newFile.__typename;
      return newFile;
    };

    input.files = files.map(mapFile);
  }

  return apolloClient
    .mutate({
      mutation,
      variables: { input },
      refetchQueries: [
        {
          query: GET_DISCUSSIONS_DATA,
          variables: { userId, currentEnrolleeId: programInstanceId }
        }
      ]
    })
    .then(response => response.data.shareMedia);
};

const deleteMessage = apolloClient => (
  userId,
  messageId,
  programInstanceId
) => {
  const mutation = gql`
    mutation deleteDiscussionMessage(
      $userId: ID!
      $messageId: ID!
      $programInstanceId: String
    ) {
      deleteDiscussionMessage(
        userId: $userId
        messageId: $messageId
        programInstanceId: $programInstanceId
      )
    }
  `;

  return apolloClient
    .mutate({
      mutation,
      variables: {
        userId,
        messageId,
        programInstanceId
      }
    })
    .then(response => response.data.deleteDiscussionMessage);
};

const initDiscussionApi = apolloClient => ({
  discussions: {
    addMessage: addMessage(apolloClient),
    updateMessage: updateMessage(apolloClient),
    deleteMessage: deleteMessage(apolloClient),
    shareMedia: shareMedia(apolloClient),
    subscribe: subscribe(apolloClient),
    unsubscribe: unsubscribeFromDiscussions,
    updateLastRead: updateLastRead(apolloClient)
  }
});

export default initDiscussionApi;
