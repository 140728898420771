import classnames from 'classnames';
import FocusLock from 'react-focus-lock';
import keyboardHandlerManager from 'common/KeyboardHandlerManager';
import MarkDownDisplay from 'components/common/MarkDownDisplay';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import styles from './RelatedVideoPlayer.scss';
import VideoPlayerWithNotes from 'components/common/VideoPlayerWithNotes';
import { actions, selectors } from 'reducers/relatedVideoPlayer';
import { connect } from 'react-redux';
import { MainButton, SecondaryButton } from 'components/common/button';
import { truncate, get } from 'lodash';
import { withDeviceDetector } from 'components/common/withDeviceDetector';
import { convertTime } from '../../common/convertTime';
import { selectors as syllabusSelectors } from 'reducers/dashboard/syllabus';
import Modal from 'react-modal';
import moment from 'moment';
import SelectBoxMobile from 'components/mobile/SelectBoxMobile';
import getBlobDuration from 'get-blob-duration';

const getDuration = video => {
  const calcDuration =
    video.started &&
    video.finished &&
    moment(moment(video.finished).diff(moment(video.started))).format(
      'm[m] s[s]'
    );
  return video.duration ? convertTime(video.duration) : calcDuration;
};

export const RelatedVideoPlayer = props => {
  let {
    hidePlayer,
    data,
    resolvedVideo,
    isMobile,
    subTitles,
    markers,
    resolveAsset
  } = props;

  const [expanded, setExpanded] = useState(false);
  const [shouldTogglePlay, setShouldTogglePlay] = useState({});
  const [duration, setDuration] = useState(0);

  const visible = get(data, 'visible');

  const togglePlay = () => {
    setShouldTogglePlay({});
  };

  useEffect(() => {
    const onKeyUp = e => {
      switch (e.key) {
        case ' ':
        case 'Space':
          togglePlay();
          break;

        case 'x':
        case 'X':
        case 'Escape':
          hidePlayer();
          break;
      }
      return false;
    };
    keyboardHandlerManager.addKeyboardHandler(onKeyUp);

    return () => keyboardHandlerManager.removeKeyboardHandler(onKeyUp);
  }, [visible]);

  const video = {
    ...resolvedVideo,
    autoPlay: true,
    subTitles
  };

  useEffect(() => {
    if (
      video.uploadType === 'INTRO_VIDEO' ||
      video.uploadType === 'VIDEO_MESSAGE'
    ) {
      (async function() {
        const videoBlobDuration = await getBlobDuration(video.downloadUrl);
        setDuration(convertTime(videoBlobDuration));
      })();
      return;
    }

    setDuration(getDuration(video));
  }, [video]);

  if (!visible) {
    return null;
  }

  const handleTranscriptClick = url =>
    resolveAsset(url).then(url => window.open(url));

  const isIntroVideo = video.uploadType === 'INTRO_VIDEO';
  const isVideoMessage = video.uploadType === 'VIDEO_MESSAGE';
  const superTitle =
    isIntroVideo || isVideoMessage || get(data, 'title')
      ? get(data, 'title')
      : 'Watch Assignment';
  const title = isIntroVideo ? '' : get(data, 'title');
  const subTitle = get(data, 'item.subtitle') || '';
  const description = get(data, 'item.description') || '';

  const renderPlayer = () => {
    if (video.type === 'youtube') {
      return (
        <iframe
          id="ytplayer"
          type="text/html"
          className={styles.ytplayer}
          src={video.url}
          frameBorder="0"
          allowFullScreen
        />
      );
    }
    if (video.type === 'vimeo') {
      return (
        <iframe
          id="vimeoplayer"
          type="text/html"
          className={styles.vimeoplayer}
          src={video.url}
          frameBorder="0"
          allowFullScreen
          allow={`${video.autoPlay && 'autoplay; encrypted-media'}`}
        />
      );
    }

    if (isIntroVideo || isVideoMessage) {
      return (
        <div
          className={classnames(styles.videoHolder, {
            [styles.introVideo]: isIntroVideo
          })}
        >
          <video src={video.downloadUrl} controls autoPlay />
        </div>
      );
    }

    return (
      <VideoPlayerWithNotes
        video={video}
        markers={markers}
        shouldTogglePlay={shouldTogglePlay}
      />
    );
  };

  const TRUNCATE_LENGTH = 500;

  const videoPlayerComponent = () => (
    <>
      <div className={styles.contentContainer}>
        <div className={styles.headerTitle} role="heading">
          <div className={styles.titleContainer}>{superTitle}</div>
          <MainButton
            label="Close"
            aria-label="Close"
            onClick={() => hidePlayer()}
            data-cy="closeButton"
            className={styles.closeButton}
          />
        </div>
        <div
          className={classnames(styles.playerAndInfo, {
            [styles.introVideo]: isIntroVideo
          })}
        >
          <div
            className={classnames(styles.videoPlayerContainer, {
              [styles.fixedHeight]:
                video.type === 'vimeo' || video.type === 'youtube',
              [styles.introVideo]: isIntroVideo
            })}
          >
            {renderPlayer()}
          </div>
          <div
            className={classnames(styles.description, {
              [styles.hidden]: isIntroVideo,
              [styles.customPlayer]:
                video.type !== 'vimeo' &&
                video.type !== 'youtube' &&
                !isIntroVideo &&
                !isVideoMessage
            })}
          >
            <MarkDownDisplay className={styles.header} markDown={title} />
            <span className={styles.subTitle}>{subTitle}</span>
            <div className={styles.buttons}>
              {!!duration && (
                <div className={styles.lessonDuration}>
                  <span>
                    <i className="fas fa-video" />
                  </span>
                  <span>{duration}</span>
                </div>
              )}
              {get(data, 'item.transscriptUrl') && (
                <>
                  <div className={styles.separator} />
                  <SecondaryButton
                    label="View Transcript"
                    onClick={() =>
                      handleTranscriptClick(data.item.transscriptUrl)
                    }
                  />
                </>
              )}
            </div>
            <MarkDownDisplay
              markDown={
                expanded
                  ? description
                  : truncate(description, {
                      length: TRUNCATE_LENGTH,
                      separator: ' '
                    })
              }
              className={styles.descText}
            />
            <div className={styles.bottomButtons}>
              {description.length > TRUNCATE_LENGTH && (
                <SecondaryButton
                  label={`Show ${expanded ? 'Less' : 'More'}`}
                  aria-label={`Show ${expanded ? 'Less' : 'More'}`}
                  icon={`fas fa-angle-${expanded ? 'up' : 'down'}`}
                  iconPosition="left"
                  onClick={() => setExpanded(!expanded)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  if (isMobile) {
    return (
      <SelectBoxMobile
        title={title}
        content={<div className={styles.mobilePlayer}>{renderPlayer()}</div>}
        onClose={() => hidePlayer()}
      />
    );
  }

  return (
    <FocusLock>
      <div className={styles.topContainer} data-cy="relatedVideoPlayer">
        <Modal
          isOpen={true}
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              zIndex: 111300
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              border: 'none',
              background: 'none',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)'
            }
          }}
        >
          {videoPlayerComponent()}
        </Modal>
      </div>
    </FocusLock>
  );
};

RelatedVideoPlayer.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    visible: PropTypes.bool,
    item: PropTypes.object
  }),
  resolvedVideo: PropTypes.object
};

export const mapStateToProps = state => {
  const isAssignment = data => get(data, 'item.assignmentType');
  const isResolvedVideo = data => get(data, 'item.baseUrl');
  const isIntroVideo = data => get(data, 'item.uploadType') === 'INTRO_VIDEO';
  const isVideoMessage = data =>
    get(data, 'item.uploadType') === 'VIDEO_MESSAGE';

  // -----------------------------

  const data = selectors.getRelatedVideoPlayer(state);

  if (isAssignment(data)) {
    const assignment = data.item;

    if (assignment) {
      return {
        data,
        resolveAsset: syllabusSelectors.getSyllabusResolveAssetFn(state),
        resolvedVideo: assignment.resolvedVideo,
        subTitles: assignment.subTitles || [],
        markers: (assignment.annotations || []).map(item => ({
          ...item,
          markerType: 'note'
        }))
      };
    }
  } else if (isResolvedVideo(data)) {
    return {
      data,
      resolvedVideo: data.item,
      subTitles: data.subTitles || []
    };
  } else if (isIntroVideo(data) || isVideoMessage(data)) {
    return {
      data,
      resolvedVideo: data.item
    };
  }
  // TODO other type of items? annotation video, glossary video
  return {
    data: {
      visible: false
    }
  };
};

export const mapDispatchToProps = {
  hidePlayer: actions.hidePlayer
};

export default withDeviceDetector(
  connect(mapStateToProps, mapDispatchToProps)(RelatedVideoPlayer)
);
