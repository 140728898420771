import { logAndSendError } from 'utils/sentryHelper';

export const toggleDiscussionTab = programInstanceId => (
  dispatch,
  getState,
  { adminApi }
) => {
  adminApi
    .toggleDiscussionTab(programInstanceId)
    .catch(e => logAndSendError(e, 'error toggling discussion tab '));
};
