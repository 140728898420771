import React from 'react';
import theme from './ButtonAndLink.scss';
import { MainButton } from 'components/common/button';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { SecondaryButton } from 'components/common/button';
import { simulateClickOnFocusedElement } from 'utils/simulateClickOnFocusedElement';

const ButtonAndLink = ({
  buttonLabel,
  onClickButton,
  questionLabel,
  linkLabel,
  onClickLink,
  onClickReset,
  isLoginPage,
  disabled
}) => (
  <div
    className={classnames(theme.buttonAndLink, {
      [theme.forgotPassword]: !isLoginPage
    })}
  >
    <React.Fragment>
      {isLoginPage && (
        <a
          aria-label="Forgot Password"
          onClick={onClickReset}
          tabIndex="0"
          onKeyUp={simulateClickOnFocusedElement}
        >
          Forgot Password?
        </a>
      )}
      <MainButton
        onClick={onClickButton}
        label={buttonLabel}
        data-cy="submitButton"
        className={classnames(theme.loginButton, {
          [theme.forgotPassword]: !isLoginPage
        })}
        aria-label={buttonLabel}
        disabled={disabled}
      />
    </React.Fragment>
    {linkLabel === 'Cancel' ? (
      <SecondaryButton
        onClick={onClickLink}
        label={linkLabel}
        aria-label={linkLabel}
        className={theme.cancelButton}
      />
    ) : (
      <div className={theme.linkHolder}>
        <span className={theme.question}>{questionLabel}</span>
        <span
          onClick={onClickLink}
          className={theme.link}
          role="Link"
          aria-label={linkLabel}
        >
          {linkLabel}
        </span>
      </div>
    )}
  </div>
);

export default ButtonAndLink;
