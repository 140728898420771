import { gql } from '@apollo/client';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { selectors as crewSelectors } from '../reducers/dashboard/crews';
import { selectors as sclSelectors } from 'smashcut-client-lib';
import { types } from '../reducers/dashboard/crews';
import { values } from 'lodash';

let unsubscribeCrewMessagesHandlers = {};
let unsubscribeCrewLastSeensHandlers = {};

export const GET_CREW = gql`
  query getCrew($id: String!) {
    crew(id: $id) {
      id
      archived
      name
      notes
      conferenceId
      instructorId
      instructor {
        id
        avatar
        fullName
        roleLabel
      }
      members {
        id
        fullName
        avatar
        roleLabel
        isStaff
        isAdmin
        isMentor
        crews {
          id
          name
        }
      }
      memberDetails {
        userId
        role
      }
      projects {
        id
        assignmentId
        assignment {
          id
          assignmentType
          title
          responseType
          description
          instructions
          instructionsPdfUrl
          courseId
          lessonId
          dueDays
        }
        assets {
          id
          created
          baseUrl
          commentCount
          downloadUrl
          fileType
          name
          ownerId
          thumbnailUrl
          duration
          status
        }
        submission {
          date
          userProgramId
          submitterUserId
        }
        lessonRecord {
          id
          status
          assignmentResponse {
            type
            projectId
          }
          review {
            authorId
            created
            type
            feedback {
              text
              file {
                id
              }
            }
          }
        }
      }
      program {
        id
        title
        startDate
        weekStartDay
        pace
        programContent {
          id
          pace {
            id
            weeks {
              id
              lessons {
                course
                lesson
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_VIDEO_CALL_MESSAGES = gql`
  query getVideoCallMessages($id: String!) {
    crew(id: $id) {
      id
      videoCallMessages {
        id
        created
        sender
        lastUpdated
        content
      }
    }
  }
`;

const CREATE_CREW = gql`
  mutation createCrew($input: CreateCrewInput) {
    createCrew(input: $input)
  }
`;

const ARCHIVE_CREW = gql`
  mutation archiveCrew($input: UpdateCrewInput) {
    archiveCrew(input: $input) {
      id
      archived
    }
  }
`;

const DELETE_CREW = gql`
  mutation deleteCrew($id: ID!) {
    deleteCrew(id: $id)
  }
`;

const ADD_CREW_MEMBERS = gql`
  mutation addCrewMembers($crewId: ID!, $users: [ID]!) {
    addCrewMembers(crewId: $crewId, users: $users)
  }
`;

const REMOVE_CREW_MEMBERS = gql`
  mutation removeCrewMembers($crewId: ID!, $users: [ID]!) {
    removeCrewMembers(crewId: $crewId, users: $users)
  }
`;

const UPDATE_MEMBER_ROLE = gql`
  mutation updateMemberRole($crewId: ID!, $userId: String, $role: String) {
    updateMemberRole(crewId: $crewId, userId: $userId, role: $role)
  }
`;

const UPDATE_CREW_NOTES = gql`
  mutation updateCrewNotes($crewId: ID!, $notes: String) {
    updateCrewNotes(crewId: $crewId, notes: $notes)
  }
`;

const UPDATE_CREW = gql`
  mutation updateCrew($input: UpdateCrewInput) {
    updateCrew(input: $input) {
      id
      name
    }
  }
`;

const UPDATE_CREW_NAME = gql`
  mutation updateCrewName($id: ID!, $name: String) {
    updateCrewName(id: $id, name: $name) {
      id
      name
    }
  }
`;

const REMOVE_CREW_FILE = gql`
  mutation removeCrewFile($crewId: ID!, $projectId: ID!, $myFileId: ID!) {
    deleteMyFile(id: $myFileId)
    removeCrewFile(crewId: $crewId, projectId: $projectId, myFileId: $myFileId)
  }
`;

const SUBMIT_PROJECT = gql`
  mutation submitCrewProject($input: SubmitCrewProjectInput) {
    submitCrewProject(input: $input)
  }
`;

const INIT_CREW_VIDEO_MEETING = gql`
  mutation initializeCrewVideoMeeting($crewId: ID!) {
    initializeCrewVideoMeeting(crewId: $crewId) {
      id
      opentokSessionId
      opentokToken
    }
  }
`;

const SAVE_CREW_VIDEO_CALL_MESSAGE = gql`
  mutation saveCrewVideoCallChatMessage(
    $crewId: ID!
    $senderId: ID!
    $message: String
  ) {
    saveCrewVideoCallChatMessage(
      crewId: $crewId
      senderId: $senderId
      message: $message
    )
  }
`;

const UPDATE_CREW_LAST_SEEN = gql`
  mutation updateCrewLastSeen($input: UpdateCrewLastSeenInput!) {
    updateCrewLastSeen(input: $input) {
      id
      crewId
      userId
      chatId
      time
    }
  }
`;

const createStandardMutator = apolloClient => (
  type,
  query,
  errorMessage,
  refetchQueries
) => {
  function* handler(args) {
    try {
      console.log(`[crew saga] [${type}] `, args);
      const result = yield call(apolloClient.mutate, {
        mutation: query,
        variables: args,
        refetchQueries
      });
      yield put({
        type: type + '_SUCCESS',
        result
      });
    } catch (e) {
      console.warn(`[error] [${type}]`, e);
      yield put({
        type: type + '_FAILURE',
        error: errorMessage
      });
    }
  }
  return takeEvery(type, handler);
};

export function* updateMemberRole(apolloClient, { crewId, userId, role }) {
  try {
    console.log('[crew saga] [update member role]', crewId, userId, role);
    const result = yield call(apolloClient.mutate, {
      mutation: UPDATE_MEMBER_ROLE,
      optimisticResponse: {
        updateMemberRole: 'ok'
      },
      update: store => {
        const data = store.readQuery({
          query: GET_CREW,
          variables: {
            id: crewId
          }
        });
        const newData = {
          ...data,
          crew: {
            ...data.crew,
            memberDetails: data.crew.memberDetails || []
          }
        };
        const exists = newData.crew.memberDetails.find(
          md => md.userId == userId
        );

        newData.crew.memberDetails = exists
          ? newData.crew.memberDetails.map(md => ({
              ...md,
              role: md.userId == userId ? role : md.role
            }))
          : newData.crew.memberDetails.concat([
              {
                userId,
                role,
                __typename: 'MemberDetail'
              }
            ]);

        store.writeQuery({
          query: GET_CREW,
          variables: {
            id: crewId
          },
          newData
        });
      },
      variables: {
        crewId,
        userId,
        role
      }
    });
    yield put({
      type: types.UPDATE_MEMBER_ROLE_SUCCESS,
      result
    });
  } catch (e) {
    console.warn('nok', e);
    yield put({
      type: types.UPDATE_MEMBER_ROLE_FAILURE,
      error: 'An error occurred while updating crew member, please try again'
    });
  }
}

export function* updateCrew(apolloClient, { input }) {
  try {
    console.log('[crew saga] [update crew]', input);
    const result = yield call(apolloClient.mutate, {
      mutation: UPDATE_CREW,
      variables: {
        input
      }
    });
    yield put({
      type: types.UPDATE_CREW_SUCCESS,
      result
    });
  } catch (e) {
    console.warn('nok', e);
    yield put({
      type: types.UPDATE_CREW_FAILURE,
      error: 'An error occurred while updating crew, please try again'
    });
  }
}

export function* updateCrewLastSeen(
  apolloClient,
  { crewId, userId, chatId, time }
) {
  try {
    console.log(
      '[crew saga] [update crew last seen]',
      crewId,
      userId,
      chatId,
      time
    );
    const response = yield apolloClient.mutate({
      mutation: UPDATE_CREW_LAST_SEEN,
      variables: {
        input: {
          crewId,
          userId,
          chatId,
          time
        }
      }
    });
    const lastSeen = response.data.updateCrewLastSeen;
    yield put({
      type: types.UPDATE_CREW_LAST_SEEN_SUCCESS,
      lastSeen
    });
  } catch (e) {
    console.warn('nok', e);
    yield put({
      type: types.UPDATE_CREW_LAST_SEEN_FAILURE
    });
  }
}

export function* updateCrewName(apolloClient, { id, name }) {
  try {
    console.log('[crew saga] [update crew name]', id, name);
    const result = yield call(apolloClient.mutate, {
      mutation: UPDATE_CREW_NAME,
      optimisticResponse: {
        updateCrewName: {
          id,
          name,
          __typename: 'Crew'
        }
      },
      variables: {
        id,
        name
      }
    });
    yield put({
      type: types.UPDATE_CREW_NAME_SUCCESS,
      result
    });
  } catch (e) {
    console.warn('nok', e);
    yield put({
      type: types.UPDATE_CREW_NAME_FAILURE,
      error: 'An error occurred while updating crew name, please try again'
    });
  }
}

export function* getLastSeens(crewsApi, { crewId, userId, callback }) {
  yield call(crewsApi.getLastSeens, crewId, userId, callback);
}

export function* subscribeToMessages(crewsApi, { crewId, callback }) {
  if (unsubscribeCrewMessagesHandlers[crewId]) {
    console.log('Already subscribed to messages for crew ', crewId);
  } else {
    const detachCrew = yield call(
      crewsApi.subscribeToMessages,
      crewId,
      callback
    );
    unsubscribeCrewMessagesHandlers[crewId] = detachCrew;
  }
}

export function* unsubscribeFromMessages(crewsApi, { crewId }) {
  if (!crewId) {
    values(unsubscribeCrewMessagesHandlers).forEach(handler => {
      handler.unsubscribe();
    });
    unsubscribeCrewMessagesHandlers = {};
  }
  if (!unsubscribeCrewMessagesHandlers[crewId]) {
    console.log('Not subscribed to messages for crew ', crewId);
  } else {
    unsubscribeCrewMessagesHandlers[crewId].unsubscribe();
    delete unsubscribeCrewMessagesHandlers[crewId];
  }
}

export function* sendCrewMessage(
  crewsApi,
  { chatId, crewId, userId, message, file, video, privateRecipientId }
) {
  try {
    const f = file && file.length && file[0];

    console.log(
      '[crew saga] [send message]',
      chatId,
      crewId,
      userId,
      message,
      f,
      video
    );

    yield call(
      crewsApi.sendMessage,
      crewId,
      userId,
      chatId,
      message,
      f,
      video,
      privateRecipientId
    );

    yield put({
      type: types.SEND_CREW_MESSAGE_SUCCESS
    });
  } catch (e) {
    console.warn('nok', e);
    yield put({
      type: types.SEND_CREW_MESSAGE_FAILURE,
      error: 'An error occurred while sending crew message, please try again'
    });
  }
}

export function* removeCrewMessage(crewsApi, { crewId, messageId }) {
  try {
    console.log('[crew saga] [remove message]', crewId, messageId);

    yield call(crewsApi.removeCrewMessage, crewId, messageId);

    yield put({
      type: types.REMOVE_CREW_MESSAGE_SUCCESS
    });
  } catch (e) {
    console.warn('nok', e);
    yield put({
      type: types.REMOVE_CREW_MESSAGE_FAILURE,
      error: 'An error occurred while removing crew message, please try again'
    });
  }
}

export function* resolveDownloadUrl(api, { url }) {
  const downloadUrl = yield call(api.getContentBucketDownloadUrl, {
    originalFileName: url
  });
  yield put({
    type: types.RESOLVE_DOWNLOAD_URL_SUCCESS,
    downloadUrl
  });
}

export function* initializeCrewVideoMeeting(apolloClient, { crewId }) {
  try {
    console.log('[crew saga] [ initializeCrewVideoMeeting ]', crewId);
    const result = yield call(apolloClient.mutate, {
      mutation: INIT_CREW_VIDEO_MEETING,
      variables: {
        crewId
      }
    });
    yield put({
      type: types.INITIALIZE_CREW_VIDEO_MEETING_SUCCESS,
      meeting: result.data.initializeCrewVideoMeeting
    });
  } catch (e) {
    console.warn('nok', e);
    yield put({
      type: types.INITIALIZE_CREW_VIDEO_MEETING_FAILURE,
      error:
        'An error occurred while initializing crew video meeting, please try again'
    });
  }
}

export function* getVideoCallMessages(apolloClient, { crewId }) {
  try {
    console.log('[crew saga] [ getVideoCallMessages ]', crewId);
    const result = yield call(apolloClient.query, {
      query: GET_VIDEO_CALL_MESSAGES,
      variables: {
        id: crewId
      },
      fetchPolicy: 'network-only'
    });
    yield put({
      type: types.GET_VIDEO_CALL_MESSAGES_SUCCESS,
      messages: result.data.crew.videoCallMessages
    });
  } catch (e) {
    console.warn('nok', e);
    yield put({
      type: types.GET_VIDEO_CALL_MESSAGES_FAILURE,
      error:
        'An error occurred while getting video meeting messages, please try again'
    });
  }
}

export function* crewsSaga({ apis }) {
  const createMutator = createStandardMutator(apis.apolloClient);
  yield all([
    createMutator(types.ARCHIVE_CREW, ARCHIVE_CREW, 'error archiving crew'),

    createMutator(types.CREATE_CREW, CREATE_CREW, 'error creating crew'),

    createMutator(types.DELETE_CREW, DELETE_CREW, 'error deleting crew'),

    createMutator(
      types.ADD_CREW_MEMBERS,
      ADD_CREW_MEMBERS,
      'error adding crew members'
    ),

    createMutator(
      types.REMOVE_CREW_MEMBERS,
      REMOVE_CREW_MEMBERS,
      'error removing crew members'
    ),

    createMutator(
      types.UPDATE_CREW_NOTES,
      UPDATE_CREW_NOTES,
      'error updating notes'
    ),

    createMutator(
      types.REMOVE_CREW_FILE,
      REMOVE_CREW_FILE,
      'error removing crew file',
      ['getProject']
    ),

    createMutator(
      types.SUBMIT_PROJECT,
      SUBMIT_PROJECT,
      'error submitting project file',
      ['getProject']
    ),

    createMutator(
      types.SAVE_VIDEO_CALL_MESSAGE,
      SAVE_CREW_VIDEO_CALL_MESSAGE,
      'error saving message'
    ),

    takeEvery(types.UPDATE_CREW, updateCrew.bind(this, apis.apolloClient)),

    takeEvery(
      types.UPDATE_CREW_LAST_SEEN,
      updateCrewLastSeen.bind(this, apis.apolloClient)
    ),

    takeEvery(
      types.UPDATE_CREW_NAME,
      updateCrewName.bind(this, apis.apolloClient)
    ),

    takeEvery(
      types.UPDATE_MEMBER_ROLE,
      updateMemberRole.bind(this, apis.apolloClient)
    ),
    takeEvery(
      types.SUBSCRIBE_TO_LAST_SEENS,
      getLastSeens.bind(this, apis.crewsApi)
    ),
    takeEvery(
      types.SUBSCRIBE_TO_MESSAGES,
      subscribeToMessages.bind(this, apis.crewsApi)
    ),
    takeEvery(
      types.UNSUBSCRIBE_FROM_MESSAGES,
      unsubscribeFromMessages.bind(this, apis.crewsApi)
    ),
    takeEvery(
      types.SEND_CREW_MESSAGE,
      sendCrewMessage.bind(this, apis.crewsApi)
    ),
    takeEvery(
      types.REMOVE_CREW_MESSAGE,
      removeCrewMessage.bind(this, apis.crewsApi)
    ),
    takeEvery(
      types.RESOLVE_DOWNLOAD_URL,
      resolveDownloadUrl.bind(this, apis.api)
    ),
    takeEvery(
      types.INITIALIZE_CREW_VIDEO_MEETING,
      initializeCrewVideoMeeting.bind(this, apis.apolloClient)
    ),
    takeEvery(
      types.GET_VIDEO_CALL_MESSAGES,
      getVideoCallMessages.bind(this, apis.apolloClient)
    )
  ]);
}
