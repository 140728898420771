import { actionType } from '../constants';
import {
  getComment,
  getNextCommentId,
  getPreviousCommentId
} from '../selectors';
import { types as lessonTypes } from 'reducers/lesson';
import { types as discussionTypes } from 'reducers/dashboard/discussionTypes';
import { types as noteDisplayTypes } from 'reducers/noteDisplay';
import { MAIN_AREA_TYPE } from '../utils/gotoLessonHelpers';
import { resolveContentImage } from 'common/resolveContentImage';

const videoPlayerReducer = (state, action, fullState) => {
  let nextState = state,
    comment,
    commentId,
    skipSpeed,
    video;

  switch (action.type) {
    case actionType.VIDEO_SKIP_FORWARD:
      switch (state.skipSpeed) {
        case 2:
          skipSpeed = 4;
          break;
        case 4:
          skipSpeed = 8;
          break;
        case 8:
          skipSpeed = 0;
          break;
        default:
          skipSpeed = 2;
          break;
      }
      nextState = {
        ...state,
        skipSpeed
      };
      break;

    case actionType.VIDEO_SKIP_BACKWARDS:
      switch (state.skipSpeed) {
        case -2:
          skipSpeed = -4;
          break;
        case -4:
          skipSpeed = -8;
          break;
        case -8:
          skipSpeed = 0;
          break;
        default:
          skipSpeed = -2;
          break;
      }
      nextState = {
        ...state,
        skipSpeed
      };
      break;

    case actionType.VIDEO_SKIP_STOP:
      if (state.skipSpeed === 0) {
        // act like play/pause when skipSpeed is 0
        toggleIsPlaying();
      } else {
        // reset skipSpeed when it is not 0
        nextState = {
          ...state,
          skipSpeed: 0,
          shouldPause: {}
        };
      }
      break;

    // watch a project from an assignment
    case lessonTypes.SELECT_PROJECT:
      if (action.projectType === MAIN_AREA_TYPE.PROJECT) {
        video = action.project;
        nextState = {
          ...state,
          inPoint: undefined,
          outPoint: undefined,
          video
        };
      }

      if (action.projectType === MAIN_AREA_TYPE.SCREENPLAY && state.isPlaying) {
        toggleIsPlaying();
      }
      break;

    // watch a project from the project view
    case actionType.selectProject:
      let p = action.project;
      nextState = {
        ...state,
        inPoint: undefined,
        outPoint: undefined,
        videoId: action.id, // when the project is not loaded yet, we will need the id to assign the project, see INSERT_ENTITY_PROJECTS below
        video: p
      };
      break;

    case lessonTypes.LAUNCH_LESSON_VIDEO:
      nextState = {
        ...state,
        videoId: null,
        inPoint: undefined,
        outPoint: undefined,
        video: prepareLessonVideo(action.video)
      };
      break;

    case lessonTypes.LOAD_LESSON_SUCCESS:
      if (action.data && action.data.video) {
        nextState = {
          ...state,
          videoId: null,
          inPoint: undefined,
          outPoint: undefined,
          video: prepareLessonVideo(action.data.video)
        };
      }
      break;
    case lessonTypes.CLEAR_CURRENT_LESSON:
      nextState = {
        ...state,
        videoId: null,
        video: null
      };
      break;

    case noteDisplayTypes.HIDE_NOTE_DISPLAY:
      nextState = {
        ...state
      };
      delete nextState.currentNote;
      if (state.wasPlayingBeforePause) {
        nextState.shouldPlay = {};
        nextState.skipSpeed = 0;
        nextState.wasPlayingBeforePause = false;
      }
      break;

    case actionType.MARKER_SHOW:
      // console.log('marker show', action.marker)
      break;

    case actionType.MARKER_HIDE:
      // console.log('marker hide', action.marker)
      break;

    case actionType.MARKER_CLICK:
      // console.log('marker click', action.marker)
      if (state.currentNote === action.marker) {
        nextState = {
          ...state
        };
        delete nextState.currentNote;
        if (state.wasPlayingBeforePause) {
          nextState.shouldPlay = {};
          nextState.skipSpeed = 0;
          nextState.wasPlayingBeforePause = false;
        }
      } else {
        nextState = {
          ...state,
          currentNote: action.marker,
          shouldAnimateButtonsForCommentId: action.marker.commentId
        };

        if (state.isPlaying) {
          nextState.shouldPause = {};
          nextState.wasPlayingBeforePause = true;
        }
      }
      break;

    case actionType.INSERT_ENTITY_PROJECTS:
    case actionType.UPDATE_ENTITY_PROJECTS:
      // when selectProject happened before the project was loaded
      // or when the project is updated after it has been selected
      if (action.id === state.videoId) {
        nextState = {
          ...state,
          video: action.entity
        };
      }
      break;

    case actionType.playVideoComment:
    case actionType.playVideoReply:
    case actionType.startAddingComment:
    case actionType.startRecordingVideo:
    case actionType.startReplying:
      nextState = {
        ...state,
        shouldPause: {},
        wasPlayingBeforePause: state.isPlaying
      };
      break;

    case actionType.addTextComment:
    case actionType.addTextReply:
    case actionType.addVideoComment:
    case actionType.addVideoReply:
    case actionType.cancelEditingComment:
    case actionType.cancelRecordVideo:
    case actionType.cancelReplying:
    case actionType.submitEditedComment:
    case actionType.submitEditedReply:
    case actionType.videoCommentEnded:
      if (state.wasPlayingBeforePause) {
        nextState = {
          ...state,
          shouldPlay: {},
          skipSpeed: 0,
          wasPlayingBeforePause: false
        };
      }
      break;

    case actionType.setInPoint:
      nextState = {
        ...state,
        inPoint: action.position || state.currentTime
      };
      break;

    case actionType.setOutPoint:
      nextState = {
        ...state,
        outPoint: action.position || state.currentTime
      };
      if (nextState.outPoint < nextState.inPoint) {
        nextState.inPoint = nextState.outPoint - 0.2;
      }
      break;

    case actionType.shareMedia:
    case discussionTypes.OPEN_SHARE_MEDIA_MODAL:
      nextState = {
        ...state,
        isSharingMedia: true
      };
      break;

    case discussionTypes.SHARE_MEDIA_SUCCESS:
    case discussionTypes.SHARE_MEDIA_FAILURE:
    case actionType.cancelShareMedia:
      nextState = {
        ...state,
        isSharingMedia: false
      };
      break;

    case actionType.resetPoints:
      nextState = {
        ...state,
        inPoint: undefined,
        outPoint: undefined,
        shouldRewindAt: undefined,
        shouldRewindTo: undefined,
        shouldStopAt: undefined
      };
      break;

    case actionType.gotoComment:
      comment = getComment(fullState, action.id);
      if (comment) {
        nextState = {
          ...state,
          gotoTime: isNaN(comment.time) ? 0 : comment.time
        };
      }
      break;

    case actionType.playVideoRange:
      nextState = {
        ...state,
        gotoTime: isNaN(action.from) ? 0 : action.from,
        shouldPlay: {}
      };
      if (action.loop) {
        nextState.shouldRewindAt = action.to;
        nextState.shouldRewindTo = action.from;
        nextState.shouldStopAt = undefined;
      } else {
        nextState.shouldRewindAt = undefined;
        nextState.shouldRewindTo = undefined;
        nextState.shouldStopAt = action.to;
      }
      break;

    case actionType.playCommentRange:
      comment = getComment(fullState, action.id);
      nextState = {
        ...state,
        gotoTime: isNaN(comment.time) ? 0 : comment.time,
        shouldPlay: {},
        playingRangeForComment: action.id
      };
      if (action.loop) {
        nextState.shouldRewindAt = comment.time + comment.duration;
        nextState.shouldRewindTo = comment.time;
        nextState.shouldStopAt = undefined;
        nextState.loopingComment = action.id;
      } else {
        nextState.shouldRewindAt = undefined;
        nextState.shouldRewindTo = undefined;
        nextState.shouldStopAt = comment.time + comment.duration;
        nextState.loopingComment = undefined;
      }
      break;

    case actionType.gotoNextComment:
      commentId = getNextCommentId(fullState);
      comment = getComment(fullState, commentId);
      if (comment && !isNaN(comment.time)) {
        nextState = {
          ...state,
          gotoTime: comment.time
        };
      }
      break;

    case actionType.gotoPreviousComment:
      commentId = getPreviousCommentId(fullState);
      comment = getComment(fullState, commentId);
      if (comment && !isNaN(comment.time)) {
        nextState = {
          ...state,
          gotoTime: comment.time
        };
      }
      break;

    case actionType.gotoTimecode:
      nextState = {
        ...state,
        gotoTime: action.seconds
      };
      break;

    case actionType.seekVideoPosition:
      let { position } = action;
      if (isNaN(position)) {
        // console.warn('current time cannot be set to NaN, using 0 instead');
        position = 0;
      }
      nextState = {
        ...state,
        currentTime: position
      };
      if (action.isReplay) {
        nextState.gotoTime = position;
      } else {
        // when the seek that happens after setting gotoTime ends, it comes here
        // reset the gotoTime so that it can be called again
        nextState.gotoTime = undefined;
      }
      if (action.causedByUser) {
        nextState.shouldRewindAt = undefined;
        nextState.shouldStopAt = undefined;
        nextState.playingRangeForComment = undefined;
      }
      break;

    case actionType.updateCurrentTime:
      let { value } = action;
      if (isNaN(value)) {
        // console.warn('current time cannot be set to NaN, using 0 instead');
        value = 0;
      }
      nextState = {
        ...state,
        currentTime: value // TODO This should probably go elsewhere. There is no need to
        // update the video player with every change of currentTime
      };

      if (state.shouldStopAt && value >= state.shouldStopAt) {
        nextState.shouldPause = {};
        nextState.shouldStopAt = undefined;
      }

      if (state.shouldRewindAt && value >= state.shouldRewindAt) {
        nextState.gotoTime = state.shouldRewindTo;
      }

      break;

    case actionType.updateDuration:
      nextState = {
        ...state,
        duration: action.value
      };
      break;

    case actionType.playerIsPlaying:
      nextState = {
        ...state,
        isPlaying: true
      };
      delete nextState.currentNote;
      if (action.isReplay) {
        nextState.shouldPlay = {};
        nextState.skipSpeed = 0;
        nextState.gotoTime = action.time;
      }
      if (action.causedByUser) {
        nextState.shouldRewindAt = undefined;
        nextState.shouldStopAt = undefined;
        nextState.playingRangeForComment = undefined;
      }
      break;

    case actionType.playerIsPaused:
      nextState = {
        ...state,
        isPlaying: false
      };
      if (action.isReplay) {
        nextState.shouldPause = {};
        nextState.gotoTime = action.time;
      }
      break;

    case actionType.toggleIsPlaying:
      toggleIsPlaying();
      break;

    case actionType.playerResized:
      nextState = {
        ...state,
        playerWidth: action.width,
        playerHeight: action.height
      };
      break;
  }
  return nextState;

  // ----------------------------

  function toggleIsPlaying() {
    nextState = { ...state };
    if (state.isPlaying) {
      nextState.shouldPause = {};
    } else {
      nextState.shouldPlay = {};
      nextState.skipSpeed = 0;
    }
    nextState.shouldRewindAt = undefined;
    nextState.shouldStopAt = undefined;
    nextState.playingRangeForComment = undefined;
  }
};

export function prepareLessonVideo(videoData) {
  // console.log('prepareLessonVideo', videoData);
  if (videoData.__typename === 'Project') {
    return videoData;
  } else if (videoData.__typename === 'LessonVideo') {
    return {
      ...videoData.resolvedVideo,
      thumbnailUrl: resolveContentImage(videoData.thumbnailUrl, 1920),
      subTitles: videoData.subTitles,
      pdfUrl: videoData.pdfUrl,
      object3DUrl: videoData.object3DUrl
    };
  } else {
    console.error('Unexpected video type', videoData);
  }
}

export default videoPlayerReducer;
