import { gql } from '@apollo/client';

export function deployProgram(apolloClient) {
  const QUERY = gql`
    query deployProgram($input: DeployProgramInput!) {
      deployProgram(input: $input)
    }
  `;

  return async input => {
    const response = await apolloClient.query({
      query: QUERY,
      variables: { input },
      refetchQueries: ['getAdminData']
    });
    return response.data.deployProgram;
  };
}
