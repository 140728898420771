import { selectors as sclSelectors } from 'smashcut-client-lib';
import { makeDate } from 'smashcut-client-lib/utils/makeDate';
import { projectThumbnailUrl } from 'utils/projectThumbnailUrl';
import { intersection, trim } from 'lodash';
import moment from 'moment';
import { calcDueDate, getDueDateMoment } from '../../common/programDateUtils';
import { getMainAreaType } from '../../smashcut-client-lib/utils/gotoLessonHelpers';

const PREFIX = 'DASHBOARD_PROJECTS_FOR_REVIEW';

export const types = {
  START_PROJECT_REVIEW: `${PREFIX}/START_PROJECT_REVIEW`
};

const initialState = {
  reviewItem: null
};

export const projectsForReviewReducer = (state = initialState, action) => {
  let nextState = state;

  switch (action.type) {
    case types.START_PROJECT_REVIEW:
      nextState = {
        ...state,
        reviewItem: action.item
      };
      break;
  }
  return nextState;
};

function startProjectReview(item) {
  return { type: types.START_PROJECT_REVIEW, item };
  // see projectsForReviewSagas
}

export const actions = {
  startProjectReview
};

const defaultValueArr = [];

function getProjectsForReview(state, userId) {
  const currentUser = userId
    ? sclSelectors.getUser(state, userId)
    : sclSelectors.getCurrentUser(state);
  if (!(currentUser && currentUser.isMentor)) {
    return defaultValueArr;
  }

  const userProgramsOfCurrentUser = Object.keys(currentUser.programs || {})
    .map(id => sclSelectors.getUserProgramEntity(state, id))
    .filter(up => up);

  const studentUserProgramIds = userProgramsOfCurrentUser.reduce((acc, up) => {
    return { ...acc, ...up.students };
  }, {});

  const studentUserPrograms = Object.keys(studentUserProgramIds)
    .map(id => sclSelectors.getUserProgramEntity(state, id))
    .filter(up => up);

  const pairs = studentUserPrograms
    .reduce((acc, up) => {
      return acc.concat(
        getLatestLessonRecords(up).map(lr => ({
          userProgram: up,
          lessonRecord: lr,
          project: getProject(state, lr)
        }))
      );
    }, [])
    .filter(
      item =>
        item.userProgram &&
        item.lessonRecord &&
        item.project &&
        (item.lessonRecord.status === 'SUBMITTED' ||
          item.lessonRecord.status === 'REVIEWED' ||
          item.lessonRecord.status === 'REJECTED')
    );

  // sort by date descending
  pairs.sort((a, b) => {
    return new Date(b.project.date) - new Date(a.project.date);
  });

  const items = pairs
    .map(({ project, userProgram, lessonRecord }) => {
      const user = sclSelectors.getUserEntity(state, userProgram.user);
      const programInstance =
        sclSelectors.getProgramInstanceEntity(
          state,
          userProgram.programInstance
        ) || {};
      const fullProgram = sclSelectors.getProgramEntity(
        state,
        programInstance.program
      );
      if (!(user && fullProgram)) {
        console.warn(
          'user, fullProgram not found',
          userProgram,
          user,
          fullProgram
        );
        return null;
      }

      const program = fullProgram.versions[programInstance.programVersion];
      const course = program.courses[project.courseId] || { lessons: [] };
      const lesson = course.lessons.find(l => l.id === project.lessonId) || {
        assignments: []
      };
      const assignment = lesson.assignments.find(
        a => a.id === project.assignmentId
      );
      if (!assignment) {
        // this can happen when an assignment has been moved or
        // its id has been changed during content editing
        console.warn('project not found', userProgram, project);
        return null;
      }
      //
      // match assignment disciplines and mentor disciplines
      if (assignment.disciplines && assignment.disciplines.length) {
        const mentorUserProgram = userProgramsOfCurrentUser
          .filter(up => up.programInstance === userProgram.programInstance)
          .shift();
        const mentorDisciplines = userProgram.mentors[mentorUserProgram.id]
          .split(',')
          .map(trim);
        if (
          intersection(assignment.disciplines, mentorDisciplines).length === 0
        ) {
          /*
          console.log(
            'project removed, no matching discipline',
            assignment.disciplines,
            mentorDisciplines
          );
          */
          return null;
        }
      }

      // TODO That's awkward. Should we store the due date in the lesson record?
      const getDueDate = dueDays => {
        return getDueDateMoment(
          project.lessonId,
          project.courseId,
          programInstance,
          program.paces[programInstance.pace],
          dueDays
        ).toDate();
      };

      const thumbnail = projectThumbnailUrl(project);

      return {
        id: project.id,
        avatar: user.avatar,
        commentCount: sclSelectors.getCounts(state, project.id) || 0,
        courseName: course.name,
        datePosted: project.date,
        dueDate: assignment.dueDays >= 0 && getDueDate(assignment.dueDays),
        duration: project.duration || 0,
        mainAreaType: getMainAreaType(lessonRecord.assignmentResponse.type),
        programTitle: programInstance.title,
        programStart: programInstance.startDate,
        programLength: program.paces[programInstance.pace].weeks.length,
        project,
        projectDescription: project.description || '',
        projectName: project.name || '',
        projectTitle: assignment.title,
        projectDisciplines:
          (assignment.disciplines && assignment.disciplines.join(', ')) || '',
        projectType: 'video',
        reviewDueDate:
          assignment.dueDays >= 0 && getDueDate(assignment.dueDays + 3),
        review: lessonRecord.review,
        reviewFeedback:
          (lessonRecord.review && lessonRecord.review.feedback) || '',
        reviewGuide: assignment.reviewGuide,
        status: lessonRecord.status,
        thumbnail: thumbnail,
        email: user.email,
        fullName: user.fullName
      };
    })
    .filter(p => p);

  return items;
}

function getLatestLessonRecords(userProgram) {
  const lessonRecords = Object.keys(
    (userProgram && userProgram.lessonRecords) || {}
  ).map(id => userProgram.lessonRecords[id]);
  const groupedByAssignment = lessonRecords.reduce((acc, lr) => {
    const id = lr.userProgramId + lr.courseId + lr.lessonId + lr.assignmentId;
    acc[id] = acc[id] || [];
    acc[id].push(lr);
    return acc;
  }, {});
  const latest = Object.keys(groupedByAssignment).map(id => {
    const lessonRecords = groupedByAssignment[id];
    lessonRecords.sort(byCreatedAscending);
    return lessonRecords.pop();
  });
  return latest;
}

function getProject(state, lessonRecord) {
  const { projectId, type } =
    (lessonRecord && lessonRecord.assignmentResponse) || {};
  switch (type) {
    case 'VIDEO':
      return sclSelectors.getProjectEntity(state, projectId);
    case 'SCREENPLAY':
      return sclSelectors.getScreenplayEntity(state, projectId);
    case 'CONFIRM':
      return undefined;
    default:
      console.warn(
        'Unexpected assignment response',
        lessonRecord.assignmentResponse
      );
      return undefined;
  }
}

function byCreatedAscending(a, b) {
  return new Date(a.created) - new Date(b.created);
}

function getReviewItem(state) {
  return state.dashboard.projectsForReview.reviewItem;
}

export const selectors = {
  getProjectsForReview,
  getReviewItem
};
