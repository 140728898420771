import React from 'react';
import PropTypes from 'prop-types';
import styles from './VideoPlayer.scss';
import VideoMarkers from './VideoMarkers';
import 'videojs-seek-buttons';
import 'videojs-vtt-thumbnails';
import { withDeviceDetector } from 'components/common/withDeviceDetector';
import VideoMobileControl from './VideoMobileControl';
import { get } from 'lodash';
import videoJsPlayerWrapperFactory from './videoJsPlayerWrapperFactory';

function getVideoUrl(props) {
  if (props && props.video) {
    return props.video.baseUrl;
  }
}

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.videoHolderId = props.videoHolderId || 'smashcutVideoHolder';
    this.videoHolderElement = null;

    this.wrapper = videoJsPlayerWrapperFactory(
      props.appConfig,
      this.videoHolderId
    );
  }

  handlePlay(e) {
    this.props.actions.playerIsPlaying(e.time, false);
  }

  handlePaused(e) {
    this.props.actions.playerIsPaused(e.time, false);
  }

  handlePlaybackFinished(e) {
    this.props.actions.playerIsPaused(e.time, false);
  }

  handleSeeked() {
    this.props.actions.seekVideoPosition(this.wrapper.getCurrentTime(), false);
    // Hack: the player does not remove the poster image when you seek, so we
    // have to do it manually
    this.wrapper.clearPosterImage();
  }

  getSavedVideoPositions = () => {
    let positions;
    try {
      positions = JSON.parse(localStorage.getItem('videoPositions') || '{}');
    } catch (e) {
      positions = {};
    }
    return positions;
  };

  updateSavedVideoPositions = positions =>
    localStorage.setItem('videoPositions', JSON.stringify(positions));

  handleDurationChange() {
    const { duration } = this.state;
    const newDuration = this.wrapper.getDuration();

    if (duration !== newDuration) {
      this.setState({ duration: newDuration });
      this.props.actions.updateDuration(newDuration);
    }
    console.log('handleDurationChange', duration, newDuration);
  }

  handleTimeChanged(e) {
    const videoId = get(this.props, 'video.id');
    const currentTime = this.wrapper.getCurrentTime();
    const duration = this.wrapper.getDuration();
    const positions = this.getSavedVideoPositions();
    if (duration > 0 && currentTime > duration - 5) {
      // console.log('reached end of video, resetting saved position');
      positions[videoId] = 0;
      this.updateSavedVideoPositions(positions);
    } else if (currentTime > 5 && currentTime !== positions[videoId]) {
      // console.log('saving video position', currentTime);
      positions[videoId] = currentTime;
      this.updateSavedVideoPositions(positions);
    }
    this.props.actions.updateCurrentTime(currentTime);
  }

  handlePlayerResize(e) {
    this.props.actions.playerResized(
      e.target.clientWidth,
      e.target.clientHeight
    );
  }

  handleFullScreenChange() {
    const drawerEl = this.videoHolderElement.querySelector('[class*=drawer]');

    if (drawerEl) {
      if (this.wrapper.isFullScreen()) {
        drawerEl.style.bottom = '64px';
      } else {
        drawerEl.style.bottom = '0';
      }
    }
  }

  handleShowControls() {
    if (this.wrapper.isFullScreen()) {
      const drawerEl = this.videoHolderElement.querySelector('[class*=drawer]');

      if (drawerEl) {
        drawerEl.style.bottom = '64px';
      }
    }
  }

  handleHideControls() {
    if (this.wrapper.isFullScreen()) {
      const drawerEl = this.videoHolderElement.querySelector('[class*=drawer]');

      if (drawerEl) {
        drawerEl.style.bottom = '0';
      }
    }
  }

  handleLoadedData() {
    const positions = this.getSavedVideoPositions();
    const savedPosition = positions[this.props.video.id];
    if (savedPosition) {
      this.wrapper.seek(savedPosition);
    }
  }

  handleShare() {
    this.props.actions.shareMedia();
  }

  _resetAndInitPlayer(props) {
    this.wrapper.initPlayer(props.video, props.lessonNav).then(isNewPlayer => {
      if (isNewPlayer) {
        this.wrapper.addEventHandlers(this);
      }
      this.handleDurationChange();
      this.handleTimeChanged();
      this.props.actions.playerReady();
    });
  }

  componentDidMount() {
    if (this.props.video) {
      this._resetAndInitPlayer(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    let newVideoUrl = getVideoUrl(props);

    if (newVideoUrl && newVideoUrl !== getVideoUrl(this.props)) {
      this._resetAndInitPlayer(props);
    } else {
      if (props.gotoTime !== this.props.gotoTime && !isNaN(props.gotoTime)) {
        this.wrapper.seek(props.gotoTime);
      }

      if (props.shouldPlay !== this.props.shouldPlay) {
        this.wrapper.play();
      }

      if (props.shouldPause !== this.props.shouldPause) {
        this.wrapper.pause();
      }

      if (props.shouldTogglePlay !== this.props.shouldTogglePlay) {
        this.wrapper.isPlaying() ? this.wrapper.pause() : this.wrapper.play();
      }

      if (props.skipSpeed !== this.props.skipSpeed) {
        this.wrapper.setSkipSpeed(props.skipSpeed);
      }
    }
  }

  componentWillUnmount() {
    this.videoHolderElement.removeEventListener(
      'smashcutplayerui',
      this.handleSmashcutPlayerUiEvent
    );
    this.wrapper.destroy();
  }

  // these events are triggered by the user
  handleSmashcutPlayerUiEvent = e => {
    // console.log('handleSmashcutPlayerUiEvent', e, e.detail.action);
    switch (e.detail.action) {
      case 'play-previous':
        e.stopPropagation();
        this.props.actions.gotoPreviousLesson &&
          this.props.actions.gotoPreviousLesson();
        break;
      case 'play-next':
        e.stopPropagation();
        this.props.actions.gotoNextLesson &&
          this.props.actions.gotoNextLesson();
        break;
      case 'play-again':
      case 'play':
        e.stopPropagation();
        this.props.actions.playerIsPlaying(this.wrapper.getCurrentTime(), true);
        break;
      case 'pause':
        e.stopPropagation();
        this.props.actions.playerIsPaused(this.wrapper.getCurrentTime(), true);
        break;
      case 'scrubbing-start':
        e.stopPropagation();
        // user started scrubbing (mouse down)
        this.props.actions.seekStart && this.props.actions.seekStart();

        break;
      case 'scrubbing-change':
        e.stopPropagation();
        // user moves seek position (mouse move)
        break;
      case 'scrubbing-end':
        {
          e.stopPropagation();
          let eventPosition = e.detail.position;
          let position = this.wrapper.getPercentagePosition(eventPosition);
          this.props.actions.seekVideoPosition(position, true);
        }

        break;
    }
  };

  // The player keyboard handling messes up our
  // own keyboard handling, esp. the space bar.
  // In case you need keyboard handling by the player
  // make sure, our own space bar still works
  disableKeyboardEventsForPlayer(event) {
    let ne = event.nativeEvent;
    if (ne.ctrlKey || ne.altKey || ne.metaKey) {
      // let these through, eg. Ctrl-R
    } else {
      event.preventDefault();
    }
  }

  handleMarkerClick = marker => {
    this.wrapper.seek(marker.time);
    this.props.actions.markerClick({ ...marker, originalData: marker });
  };

  handleMarkerChange = (marker, change) => {
    // console.log('marker changed', marker, change);
    if (change.time) {
      this.props.actions.setInPoint(change.time);
    }
    if (change.duration) {
      this.props.actions.setOutPoint(
        (change.time || this.props.inPoint) + change.duration
      );
    }
  };

  render() {
    const { children, isMobile, disabled, closeDisabled, fromUrl } = this.props;
    const { duration } = this.state;
    return (
      <div className={styles.videoPlayerAndMarkersContainer}>
        <>
          {isMobile && (
            <VideoMobileControl
              player={this.wrapper}
              closeDisabled={closeDisabled}
              fromUrl={fromUrl}
            />
          )}
          <div data-vjs-player>
            <video
              id={this.videoHolderId}
              onKeyDown={this.disableKeyboardEventsForPlayer}
              ref={el => (this.videoHolderElement = el)}
              className="video-js"
              playsInline={isMobile}
            />
          </div>
          {children}
        </>
        <VideoMarkers
          className={styles.markersContainer}
          markers={this.props.markers}
          videoLength={duration}
          inPoint={this.props.inPoint}
          outPoint={this.props.outPoint}
          playingComment={this.props.playingRangeForComment}
          onMarkerClick={marker => !disabled && this.handleMarkerClick(marker)}
          onMarkerChange={this.handleMarkerChange}
        />
      </div>
    );
  }
}

export default withDeviceDetector(VideoPlayer);

VideoPlayer.propTypes = {
  actions: PropTypes.shape({
    playerIsPaused: PropTypes.func.isRequired,
    playerIsPlaying: PropTypes.func.isRequired,
    updateDuration: PropTypes.func.isRequired,
    updateCurrentTime: PropTypes.func.isRequired,
    playerResized: PropTypes.func.isRequired,
    seekVideoPosition: PropTypes.func.isRequired
  }),
  appConfig: PropTypes.object.isRequired,
  gotoTime: PropTypes.number,
  lessonNav: PropTypes.object,
  markers: PropTypes.array,
  annotations: PropTypes.array,
  shouldPause: PropTypes.object,
  shouldPlay: PropTypes.object,
  shouldTogglePlay: PropTypes.object,
  video: PropTypes.shape({
    url: PropTypes.string,
    jobId: PropTypes.number
  }),
  videoHolderId: PropTypes.string
};
