import { signUrl } from '../../smashcut-player-lib/signUrl';

export const getContentBucketDownloadUrl = (
  storageService,
  isProd
) => async asset => {
  const assetName = asset.originalFileName || asset.name;
  const maybeGeneration =
    isProd && asset.generation ? `&versionId=${asset.generation}` : '';
  // TODO make dynamic
  const url = `https://smashcut-program-assets.s3.amazonaws.com/${encodeURIComponent(
    assetName
  )}`;
  const signedUrl = await signUrl(url);
  return `${signedUrl}${maybeGeneration}`;
};
