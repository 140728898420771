import { gql } from '@apollo/client';
import { objectsToMap } from 'common/objectsToMap';

const lessonsFragment = `
          lessons {
            id
            description
            thumbnailUrl
            title
            assignments {
              id
              title
              thumbnailUrl
              annotations {
                id
                text
                title
                teaser
                relatedVideos {
                  id
                  title
                  videoUrl
                  resolvedVideo {
                    id
                    videoUrl
                  }
                }
              }
              assignmentType
              assignmentTypeLabel
              courseId
              crewProject {
                id
                crewId
                assignmentId
              }
              description
              dueDate
              dueDays
              disciplines
              externalUrl
              help
              status
              isCrewAssignment
            }
            supplementals {
              id
              text
              title
            }
            previousLesson {
              id
              thumbnailUrl
              gotoLessonPayload {
                programId
                courseId
                lessonId
                mainAreaId
                tabName
                itemType
                itemId
              }
              title
            }
            nextLesson {
              id
              thumbnailUrl
              gotoLessonPayload {
                programId
                courseId
                lessonId
                mainAreaId
                tabName
                itemType
                itemId
              }
              title
            }
            videos {
              id
              baseUrl
              videoUrl
              title
            }
            info {
              id
            }
          }

`;

const loadUserDataQuery = gql`
  query loadUserData($input: LoadUserDataInput!) {
    userData(input: $input) {
      userPrograms {
        id
        isInstructor
        user {
          id
          avatar
          currentUserProgramId
          settings {
            handIconIndex
          }
          email
          fullName
          isAdmin
          isMentor
          isPrivate
          isStaff
          isHidden
          isFreeAccount
          lastTimeOnline
          notificationsLastOpenTime
          roleLabel
        }
        projects {
          id
        }
        notes {
          userId
          note
        }
        programInstance
        applicantDetails {
          audio
          camera
          other
          software
          whyFilmmaker
          workUrls
          howDidYouHearAbout
        }
        applicationTimestamp
        application {
          status
          paymentResult
        }
        mentors {
          id
          discipline
        }
        lessonRecords {
          id
          userProgramId
          lessonId
          courseId
          assignmentId
          created
          status
          assignmentResponse {
            type
            projectId
          }
          review {
            authorId
            created
            type
            feedback {
              text
              file {
                id
              }
            }
          }
        }
      }
      programInstances {
        id
        hasEnded
        isTrial
        isDiscussionTabDisabled
        isUsingCrewFilesInsteadOfAssets
        pace
        program
        programVersion
        startDate
        weekStartDay
        availabilityDate
        title
        trialWeeks
      }
      projects {
        assignmentId
        baseUrl
        status
        spriteUrl
        description
        date
        dashUrl
        duration
        commentCount
        hlsUrl
        lessonId
        id
        crew {
          id
          name
          instructor {
            id
          }
        }
        name
        responseType
        vttUrl
        userProgramId
        thumbnailUrl
      }
      screenplays {
        id
        assignmentId
        assignmentKey
        commentCount
        courseId
        created
        description
        downloadUrl
        fileName
        lessonId
        name
        ownerId
        responseType
        status
        uploadId
        userProgramId
      }
      imageProjects {
        id
        assignmentId
        assignmentKey
        commentCount
        courseId
        created
        description
        images {
          uploadId
          downloadUrl
        }
        lessonId
        name
        ownerId
        responseType
        status
        userProgramId
      }
      nonSupportedFileProjects {
        id
        assignmentId
        courseId
        created
        lessonId
        description
        downloadUrl
        fileName
        fileSize
        status
        userProgramId
      }
      object3DProjects {
        id
        assignmentId
        courseId
        created
        lessonId
        description
        downloadUrl
        fileName
        fileSize
        status
        userProgramId
      }

      programs {
        lastUpdate

        preStart {
          id
          introText
          hint
          parentId
          parentType
          tourVideoUrl
          canNavigate
          tourVideo {
            id
            baseUrl
            dashUrl
            description
            duration
            hlsUrl
            spriteUrl
            thumbnailUrl
            title
            type
            url
            videoUrl
            vttUrl
          }
          welcomeVideoUrl
          welcomeVideoThumbnailUrl
          welcomeVideo {
            id
            baseUrl
            dashUrl
            description
            duration
            hlsUrl
            spriteUrl
            thumbnailUrl
            title
            type
            url
            videoUrl
            vttUrl
          }
          ${lessonsFragment}
        }
        courses {
          id
          ${lessonsFragment}
        }
        paces {
          id
          hint
          isModuleBased
          parentId
          parentType
          weeks {
            id
            shortTitle
            lms {
              course
            }
            lessons {
              course
              lesson
            }
          }
        }
        id
        programId
        version
      }
      user {
        id
        avatar
        currentUserProgramId
        email
        enrollments {
          id
          applicantDetails {
            audio
            camera
            other
            software
            whyFilmmaker
            workUrls
            howDidYouHearAbout
          }
          application {
            status
            paymentResult
          }
          applicationTimestamp
          isInstructor
          paymentToken
          paymentPlanCode
          program {
            id
            title
          }
          students {
            id
          }
          mentors {
            discipline
          }
          notes {
            userId
            note
          }
          user {
            id
            fullName
          }
          statistics {
            projectCount
            commentCount
            uploadCount
          }
        }
        fullName
        firstName
        lastName
        isAdmin
        isMentor
        isPrivate
        isStaff
        isHidden
        isFreeAccount
        lastTimeOnline
        location
        notificationsLastOpenTime
        privacy
        roleLabel
        shortBio
        smsPhoneNumber
        social {
          facebook
          instagram
          twitter
          vimeo
          website
          youtube
        }
        crews {
          id
          name
          unreadMessageCount
        }
        settings {
          emailNotifications
          smsNotifications
          handIconIndex
          meetingDuration
        }
        introVideo {
          id
          created
          downloadUrl
          fileMimeType
          fileName
          fileSize
          fileType
          finished
          isVisible
          ownerId
          started
          status
          uploadDurationMs
          uploadId
          uploadPath
          uploadType
        }
      }
    }
  }
`;

export const loadUserData = apolloClient => userId =>
  apolloClient
    .query({
      query: loadUserDataQuery,
      variables: { input: { userId } },
      fetchPolicy: 'network-only'
    })
    .then(response => {
      const {
        userPrograms,
        programInstances,
        projects,
        programs,
        screenplays,
        imageProjects,
        nonSupportedFileProjects,
        object3DProjects,
        user
      } = response.data.userData;
      const formattedPrograms = programs.map(pr => ({
        ...pr,
        encodedVideos: objectsToMap(pr.encodedVideos),
        courses: objectsToMap(pr.courses),
        paces: objectsToMap(pr.paces)
      }));
      const formattedUserPrograms = userPrograms.map(up => ({
        ...up,
        projects: objectsToMap(up.projects, true),
        students: objectsToMap(up.students, true),
        mentors: objectsToMap(up.mentors, 'discipline'),
        lessonRecords: objectsToMap(up.lessonRecords),
        notes:
          up.notes &&
          up.notes.reduce((acc, item) => {
            acc[item.userId] = item.note;
            return acc;
          }, {})
      }));
      return {
        projects,
        screenplays,
        imageProjects,
        nonSupportedFileProjects,
        object3DProjects,
        programs: formattedPrograms,
        userPrograms: formattedUserPrograms,
        programInstances,
        user
      };
    });
