import config from 'config';

export function isTest(maybeFlag) {
  try {
    const url = new URL(window.location.href);
    const testQueryContent = url.searchParams.get('test');
    const hasTestQuery = maybeFlag
      ? testQueryContent === maybeFlag
      : !!testQueryContent;

    return config.env !== 'prod' && hasTestQuery;
  } catch (e) {
    // just ignore
  }
  return false;
}
