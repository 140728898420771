import React from 'react'; // eslint-disable-line
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';

const GET_USER = gql`
  query search($userId: String!) {
    user(id: $userId) {
      id
      avatar
      fullName
    }
  }
`;

const ComponentWithUserData = ({ data, renderComponent }) => {
  if (!data) {
    return null;
  }

  const { user } = data;
  return user && renderComponent ? renderComponent(user) : null;
};

export default graphql(GET_USER, {
  skip: ownProps => !ownProps.userId,
  options: ownProps => {
    return {
      variables: {
        userId: ownProps.userId
      }
    };
  }
})(ComponentWithUserData);
