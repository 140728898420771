import * as partnerConfig from 'themes/partnerConfig';
import React from 'react';
import { connect } from 'react-redux';
import {
  getCurrentUser,
  getCurrentUserProgramHasEnded,
  getCurrentUserProgram,
  getCurrentUserPrograms
} from 'smashcut-client-lib/selectors';
import _, { get, mapValues, filter, head, isEmpty } from 'lodash';
import moment from 'moment';
import { isTest } from '../../common/isTest';

const invert = visible =>
  Object.keys(visible).reduce((acc, key) => {
    acc[key] = !visible[key];
    return acc;
  }, {});

export const withHiddenTabs = Component => {
  const mapStateToProps = state => {
    const userPrograms = getCurrentUserPrograms(state);
    const currentUserProgram = getCurrentUserProgram(state);
    const user = getCurrentUser(state);
    const isAdmin = get(user, 'isAdmin');
    const isMentor = get(user, 'isMentor');
    const isInstructor = get(currentUserProgram, 'isInstructor');
    const programHasEnded = getCurrentUserProgramHasEnded(state);
    const acceptedOrEnrolledPrograms = _(userPrograms)
      .filter(
        up =>
          up.application &&
          (up.application.status == 'ACCEPTED' ||
            up.application.status == 'ENROLLED')
      )
      .map(up => ({
        ...up,
        startTimeStamp: isTest('pre-program')
          ? moment()
              .add(-1, 'minute')
              .unix()
          : (moment(up.programInstance.availabilityDate).isValid()
              ? moment(up.programInstance.availabilityDate)
              : moment(up.programInstance.startDate)
            ).unix()
      }))
      .sortBy('startTimeStamp')
      .value();

    const currentDate = moment();
    const currentTimeStamp = currentDate.unix();
    const programsStartingAfter = filter(
      acceptedOrEnrolledPrograms,
      p => p.startTimeStamp > currentTimeStamp && p.id == currentUserProgram.id
    );
    const upcomingProgram = head(programsStartingAfter);
    const shouldShowPreProgram =
      !isMentor &&
      !isAdmin &&
      !isEmpty(upcomingProgram) &&
      upcomingProgram.id == currentUserProgram.id;
    const shouldShowNoProgram = isEmpty(userPrograms);
    const shouldShowLms = partnerConfig.showLms;
    const shouldHideLmsFromStudents = partnerConfig.shouldHideLmsFromStudents;
    const shouldShowDiscussions = !get(
      currentUserProgram,
      'programInstance.isDiscussionTabDisabled'
    );

    return {
      currentUserProgram,
      isInstructor,
      isMentor,
      programHasEnded,
      user,
      shouldHideLmsFromStudents,
      shouldShowDiscussions,
      shouldShowLms,
      shouldShowPreProgram,
      shouldShowNoProgram,
      upcomingProgram
    };
  };

  const mapDispatchToProps = {};
  const withRedux = connect(mapStateToProps, mapDispatchToProps);

  return withRedux(props => {
    const visible = calcVisible(props);
    return <Component {...props} hidden={invert(visible)} />;
  });
};

// export for testing
export function calcVisible({
  isMentor,
  programHasEnded,
  shouldHideLmsFromStudents,
  shouldShowDiscussions,
  shouldShowLms,
  shouldShowPreProgram,
  shouldShowNoProgram
}) {
  const visibleBase = {
    activities: false,
    notifications: false,
    preStart: false,
    welcomeNoProgram: false,
    syllabus: true,
    myFiles: true,
    conferences: isMentor,
    projectsForReview: isMentor,
    crews: isMentor,
    students: isMentor,
    projects: !isMentor,
    community: !isMentor,
    scheduleMeetings:
      !isMentor &&
      !programHasEnded &&
      shouldShowLms &&
      !shouldHideLmsFromStudents,
    upcomingVideoMeetings:
      !programHasEnded &&
      shouldShowLms &&
      (isMentor || !shouldHideLmsFromStudents),
    discussions: shouldShowDiscussions
  };

  const allValuesFalse = mapValues(visibleBase, () => false);

  if (shouldShowPreProgram) {
    return {
      ...allValuesFalse,
      preStart: true,
      discussions: true,
      myFiles: true
    };
  }

  if (shouldShowNoProgram) {
    return {
      ...allValuesFalse,
      welcomeNoProgram: true,
      myFiles: true
    };
  }
  return visibleBase;
}
