import _, { get, isEmpty } from 'lodash';
import classnames from 'classnames';
import ComponentWithUserData from './ComponentWithUserData';
import formatDuration from 'common/formatDuration';
import moment from 'moment';
import onClickOutside from 'react-onclickoutside';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import React from 'react';
import SmashcutAvatar from './SmashcutAvatar';
import styles from './BadgeNotifications.scss';
import { actions } from 'reducers/dashboard/notifications';
import { connect } from 'react-redux';
import { getCurrentUser } from 'smashcut-client-lib/selectors';
import { actions as officeHourActions } from 'reducers/officeHoursReducer';
import { gotoUserProfile } from '../../utils/gotoUserProfile';
import { simulateClickOnFocusedElement } from 'utils/simulateClickOnFocusedElement';
import { withDeviceDetector } from 'components/common/withDeviceDetector';
import { SecondaryButton } from 'components/common/button';
import {
  addBodyScrollClass,
  removeBodyScrollClass
} from 'utils/updateBodyScrollClass';
import { OnlyOnComputerAlert } from 'components/common/OnlyOnComputerAlert';
import { isAbsoluteUrl } from '../../utils/isAbsoluteUrl';

const ITEMS_PER_PAGE = 10;

const NotificationWidget = ({ notification, onHover, onClick }) => {
  const nowUtc = moment.utc();
  const created = moment(notification.created);
  const ago = nowUtc.diff(created);
  const agoString = formatDuration(ago);
  const colorCode = notification.colorCode || 0;
  const bigImage = notification.bigImage || {};
  const content =
    notification.contents.badge || notification.contents.web || {};

  return (
    <div
      className={classnames(
        styles.notificationItem,
        styles['color' + colorCode],
        bigImage.type == 'thumbnail' && styles.withThumbnail
      )}
      onMouseEnter={() => onHover && onHover(notification)}
      onClick={() => onClick && onClick(notification)}
      onKeyUp={simulateClickOnFocusedElement}
      role="listitem"
      tabIndex="0"
    >
      <div className={styles.date}>{agoString}</div>
      <div
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: content.title }}
      />
      <div className={styles.message}>
        {notification.icon && (
          <span
            className={classnames(
              notification.icon,
              styles.notificationIcon,
              styles['color' + colorCode]
            )}
          />
        )}

        <span dangerouslySetInnerHTML={{ __html: content.message }} />
      </div>

      {bigImage.type == 'avatar' && (
        <SmashcutAvatar
          className={styles.avatar}
          holderClassName={styles.avatarHolder}
          src={notification.bigImage.reference}
          width={32}
          height={32}
        />
      )}
      {bigImage.type == 'thumbnail' && (
        <img
          className={styles.thumbnail}
          style={{ width: 57, height: 32 }}
          src={notification.bigImage.reference}
        />
      )}
      {bigImage.type == 'icon' && (
        <i
          className={classnames(
            styles.icon,
            notification.bigImage.reference,
            styles['color' + colorCode]
          )}
        />
      )}

      {!notification.seen && <div className={styles.unseenMarker} />}
    </div>
  );
};

class BadgeNotifications extends React.Component {
  state = {
    open: false,
    counter: 0,
    page: 1,
    isOpenOnlyOnComputerDialog: false
  };

  UNSAFE_componentWillReceiveProps(np) {
    if (np.officeHoursSetupComplete && !this.props.officeHoursSetupComplete) {
      this.setState({ open: false });
    }

    if (np.officeHoursError && !this.props.officeHoursError) {
      alert('Error setting up LMS session, please try again!');
    }
  }

  handleClickOutside = () => {
    this.setState({ open: false });
  };

  componentWillUnmount() {
    clearInterval(this.updateInterval);
  }

  togglePanel = e => {
    e.preventDefault();
    e.stopPropagation();

    const { open } = this.state;
    const {
      user,
      setNotificationsLastOpenTime,
      notificationsPanelClosed
    } = this.props;
    this.setState({ open: !open, page: 1 });
    if (open) {
      clearInterval(this.updateInterval);
      this.updateInterval = undefined;
      notificationsPanelClosed();
    } else {
      setNotificationsLastOpenTime(user.id, moment().unix());
      this.updateInterval = setInterval(() => {
        this.setState({ counter: this.state.counter + 1 });
      }, 1000);
    }
  };

  openNotification = notification => {
    const {
      history: { push },
      isMobile
    } = this.props;
    if (isMobile && notification.isComputerOnlyNotification) {
      addBodyScrollClass();
      this.setState({ isOpenOnlyOnComputerDialog: true });
    } else {
      this.setState({ open: false });

      isAbsoluteUrl(notification.link)
        ? window.open(notification.link)
        : push(`/${notification.link}`);
    }
  };

  showNotificationsSettings = () => {
    this.setState({ open: false });
    this.props.showNotificationsSettings();
  };

  seeAll = () => {
    const {
      history: { push }
    } = this.props;
    this.setState({ open: false });
    push('/dashboard/notifications');
  };

  handleJoinLms = () => {
    const {
      lmsInProgress,
      upcomingLms,
      settingUpOfficeHours,
      history: { push }
    } = this.props;
    const lms = lmsInProgress || upcomingLms;
    const { firstUserId, secondUserId, from, to } = lms;

    if (!settingUpOfficeHours) {
      this.props.setupOfficeHours(
        firstUserId,
        secondUserId,
        from.format(),
        to.format(),
        push
      );
    }
  };

  onListScroll = ev => {
    const o = ev.target;
    if (o.offsetHeight + o.scrollTop >= o.scrollHeight) {
      this.setState({ page: this.state.page + 1 });
    }
  };

  filteredNotification = notifications => {
    return notifications && !isEmpty(notifications)
      ? notifications.reduce((acc, notification) => {
          const isComputerOnlyNotification =
            notification.type === 'NEW_LMS_BOOKED' ||
            notification.type === 'LMS_CANCELLED' ||
            notification.type === 'UPCOMING_LMS_WEB' ||
            notification.type === 'CREW_PROJECT_GOT_REVIEWED' ||
            notification.type === 'CREW_PROJECT_GOT_REJECTED' ||
            notification.type === 'CREW_PROJECT_ASSET_ADDED';
          acc.push({
            ...notification,
            isComputerOnlyNotification
          });
          return acc;
        }, [])
      : [];
  };

  render() {
    const {
      user,
      unseenCount,
      oldNotifications,
      newNotifications,
      markNotificationSeen,
      tooltipNotification,
      settingUpOfficeHours,
      isMobile
    } = this.props;

    const { open, isOpenOnlyOnComputerDialog } = this.state;
    const cappedUnseenCount = unseenCount < 100 ? unseenCount : '99+';
    const truncatedOldNotifications = _(oldNotifications)
      .take(this.state.page * ITEMS_PER_PAGE)
      .value();
    const filteredNewNotifications = this.filteredNotification(
      newNotifications
    );
    const filteredTruncatedOldNotifications = this.filteredNotification(
      truncatedOldNotifications
    );

    return (
      <React.Fragment>
        <div
          className={styles.badgeHolder}
          onClick={this.togglePanel}
          onDoubleClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onKeyUp={simulateClickOnFocusedElement}
          role="button"
          aria-label="List notifications"
          aria-expanded={open}
          tabIndex="0"
        >
          <i className={classnames('fas fa-bell', styles.badge)} />
          {unseenCount > 0 && (
            <div className={styles.countHolder}>
              {!isMobile ? cappedUnseenCount : ''}
            </div>
          )}
          {open && (
            <div className={styles.notificationsHolder}>
              <div className={styles.notificationsHeader}>
                <div className={styles.notificationsLabel}>Notifications</div>
                <div
                  className={styles.settingsHolder}
                  onClick={this.showNotificationsSettings}
                  onKeyUp={simulateClickOnFocusedElement}
                  role="button"
                  aria-label="Open notification settings"
                  tabIndex="0"
                >
                  Settings{' '}
                  <i className={classnames(styles.cog, 'fas fa-cog')} />
                </div>
                <SecondaryButton
                  icon="fas fa-times"
                  iconPosition="right"
                  className={styles.closeButton}
                  onClick={() => this.setState({ open: false })}
                />
                <div className={styles.arrow} />
              </div>

              <div
                className={styles.listHolder}
                onScroll={ev => this.onListScroll(ev)}
                role="list"
              >
                {this.props.upcomingLms && (
                  <div
                    className={styles.upcomingLMS}
                    onClick={this.handleJoinLms}
                    role="link"
                    aria-label="Join Now"
                    tabIndex="0"
                  >
                    <ComponentWithUserData
                      userId={this.props.lmsOtherUser}
                      renderComponent={user => (
                        <div className={styles.avatarAndName}>
                          <SmashcutAvatar
                            src={user.avatar}
                            width={24}
                            height={24}
                            onClick={e => gotoUserProfile(e, user.id)}
                            aria-label={`${user.fullName} profile (New Window)`}
                            tabIndex="0"
                          />
                          <span className={styles.name}>
                            LMS with <strong>{user.fullName}</strong>
                          </span>
                        </div>
                      )}
                    />

                    <div className={styles.right}>
                      {settingUpOfficeHours && (
                        <ProgressBar
                          type="circular"
                          mode="indeterminate"
                          className={styles.progressBar}
                        />
                      )}
                      {!settingUpOfficeHours && (
                        <span>
                          starts in{' '}
                          <strong>
                            {formatDuration(
                              this.props.upcomingLms.from.diff(moment())
                            )}
                          </strong>
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {this.props.lmsInProgress && (
                  <div
                    className={styles.lmsInProgress}
                    onClick={this.handleJoinLms}
                    role="link"
                    aria-label="Join Now"
                    tabIndex="0"
                  >
                    <ComponentWithUserData
                      userId={this.props.lmsOtherUser}
                      renderComponent={user => (
                        <div className={styles.avatarAndName}>
                          <SmashcutAvatar
                            src={user.avatar}
                            width={24}
                            height={24}
                            onClick={e => gotoUserProfile(e, user.id)}
                            role="link"
                            tabIndex="0"
                            aria-label={`${user.fullName} profile (New Window)`}
                          />
                          <span className={styles.name}>
                            LMS with <strong>{user.fullName}</strong>
                          </span>
                        </div>
                      )}
                    />

                    <div className={styles.right}>
                      {settingUpOfficeHours && (
                        <ProgressBar
                          type="circular"
                          mode="indeterminate"
                          className={styles.progressBar}
                        />
                      )}
                      {!settingUpOfficeHours && (
                        <span>
                          JOIN NOW <i className="fas fa-chevron-right" />
                        </span>
                      )}
                    </div>
                  </div>
                )}
                {!isEmpty(filteredNewNotifications) && (
                  <div className={styles.notificationsPartitionHeader}>
                    Newest
                  </div>
                )}
                {filteredNewNotifications.map(n => (
                  <NotificationWidget
                    key={n.id}
                    notification={n}
                    onHover={n =>
                      !n.seen && markNotificationSeen(user.id, n.id)
                    }
                    onClick={this.openNotification}
                  />
                ))}
                {!isEmpty(filteredTruncatedOldNotifications) && (
                  <div className={styles.notificationsPartitionHeader}>
                    Older
                  </div>
                )}
                {filteredTruncatedOldNotifications.map(n => (
                  <NotificationWidget
                    key={n.id}
                    notification={n}
                    onHover={n =>
                      !n.seen && markNotificationSeen(user.id, n.id)
                    }
                    onClick={this.openNotification}
                  />
                ))}
                {filteredNewNotifications.length === 0 &&
                  filteredTruncatedOldNotifications.length === 0 && (
                    <span className={styles.noNotification}>
                      Notifications provide you with updates about activity
                      related to your account and projects. As soon there’s
                      something to report, it will appear here.
                    </span>
                  )}
              </div>
              <div
                className={styles.seeAllHolder}
                onClick={this.seeAll}
                role="button"
                aria-label="See All Notification"
                onKeyUp={simulateClickOnFocusedElement}
                tabIndex="0"
              >
                See All Notifications
              </div>
            </div>
          )}
          {tooltipNotification && (
            <div
              className={classnames(
                styles.tooltip,
                styles[`color` + tooltipNotification.colorCode]
              )}
            >
              <div className={styles.tooltipArrow} />
              {tooltipNotification.contents.tooltip.message}
            </div>
          )}
        </div>
        <OnlyOnComputerAlert
          visible={isMobile && isOpenOnlyOnComputerDialog}
          message="You need to be on your computer to see this notification."
          onClick={() => {
            removeBodyScrollClass();
            this.setState({ isOpenOnlyOnComputerDialog: false });
          }}
        />
      </React.Fragment>
    );
  }
}

const getUnseenCount = state => state.dashboard.notifications.unseenCount;

const getOldNotifications = state =>
  state.dashboard.notifications.oldNotifications;

const getNewNotifications = state =>
  state.dashboard.notifications.newNotifications;

const getUpcomingLms = state => state.dashboard.notifications.upcomingLms;

const getLmsInProgress = state => state.dashboard.notifications.lmsInProgress;

const getTooltipNotification = state => {
  const n = state.dashboard.notifications.tooltipNotification;
  return isStillValid(n) ? n : undefined;

  // -----------------------------

  function isStillValid(n) {
    if (!n) {
      return true;
    }
    if (n.type !== 'UPCOMING_LMS_TOOLTIP') {
      return true;
    }
    if (!(n.data && n.data.to)) {
      return true;
    }
    const endDate = moment(n.data.to);
    if (!endDate.isValid()) {
      return true;
    }
    return moment().isBefore(endDate);
  }
};

export const mapStateToProps = state => {
  const user = getCurrentUser(state);
  const upcomingLms = getUpcomingLms(state);
  const lmsInProgress = getLmsInProgress(state);
  const lms = lmsInProgress || upcomingLms;
  let lmsOtherUser;
  if (lms) {
    if (lms.firstUserId == user.id) {
      lmsOtherUser = lms.secondUserId;
    } else {
      lmsOtherUser = lms.firstUserId;
    }
  }
  return {
    user,
    upcomingLms,
    lmsOtherUser,
    lmsInProgress,
    unseenCount: getUnseenCount(state),
    oldNotifications: getOldNotifications(state),
    newNotifications: getNewNotifications(state),
    tooltipNotification: getTooltipNotification(state),
    officeHoursSetupComplete: get(
      state,
      'dashboard.upcomingLms.officeHoursSetupComplete'
    ),
    settingUpOfficeHours: get(
      state,
      'dashboard.upcomingLms.settingUpOfficeHours'
    ),
    officeHoursError: get(state, 'dashboard.upcomingLms.officeHoursError'),
    officeHoursId: get(state, 'dashboard.upcomingLms.officeHoursId')
  };
};

export const mapDispatchToProps = {
  markNotificationSeen: actions.markNotificationSeen,
  setNotificationsLastOpenTime: actions.setNotificationsLastOpenTime,
  notificationsPanelClosed: actions.notificationsPanelClosed,
  showNotificationsSettings: actions.showNotificationsSettings,
  hideNotificationsSettings: actions.hideNotificationsSettings,
  setupOfficeHours: officeHourActions.setupOfficeHours
};

const ClickOutsideBadgeNotifications = onClickOutside(BadgeNotifications);

export default withDeviceDetector(
  connect(mapStateToProps, mapDispatchToProps)(ClickOutsideBadgeNotifications)
);
