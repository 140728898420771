export function projectThumbnailUrl(p) {
  if (!p || !p.thumbnailUrl) {
    return '';
  }
  if (p.thumbnailUrl.indexOf('http') == 0) {
    return p.thumbnailUrl;
  }
  if (p.baseUrl) {
    return p.baseUrl + p.thumbnailUrl;
  }
  return `${p.url}/thumbnails/${p.thumbnailUrl}`;
}
