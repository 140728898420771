import React from 'react';
import styles from './FileTransfer.scss';
import { saveAs } from 'file-saver';
import { connect } from 'react-redux';
import { actions } from 'reducers/dashboard/myFiles';
import { selectors } from 'reducers/dashboard/myFiles';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { isEqual, isEmpty } from 'lodash';
import axios from 'axios';
import classnames from 'classnames';
import { DoneAnimation } from 'components/common/DoneAnimation';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';

const downloadFile = url =>
  new Promise((resolve, reject) => {
    JSZipUtils.getBinaryContent(url, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });

class DownloadFilesModal extends React.Component {
  state = {
    name: '',
    percentCompleted: 0,
    leaving: false
  };

  closeDialog() {
    this.setState({ leaving: true });
    setTimeout(() => {
      this.setState({ name: '', error: false, percentCompleted: 0 });
      this.props.closeDownloadFileDialog();
    }, 600);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      !isEmpty(newProps.files) &&
      !isEqual(this.props.files, newProps.files)
    ) {
      const files = newProps.files.map(f => ({
        ...f,
        name: f.name || f.title
      }));

      Promise.all(
        files.map(async file => {
          if (file.images && file.images.length) {
            await this.multiImageDownload(file);
            return;
          }

          if (file.isPreloaded) {
            await this.downloadPreloadedFile(file);
          } else {
            await this.downloadMedia(file);
          }
          this.setState({ name: file.name });
        })
      ).catch(e => console.log('Error downloading file', e));
    }
    if (!newProps.open) {
      this.setState({ leaving: false });
    }
  }

  async multiImageDownload(file) {
    const zip = new JSZip();

    try {
      file.images.forEach(image => {
        const url = image.downloadUrl;
        const filename = url.replace(/.*\//g, '');
        zip.file(filename, downloadFile(url), { binary: true });
      });

      const blob = await zip.generateAsync({ type: 'blob' }, metadata => {
        this.setState({
          name: metadata.currentFile,
          percentCompleted: metadata.percent || 0
        });
      });

      if (blob) {
        saveAs(blob, `${file.name}.zip`);
      } else {
        console.log('error:', blob);
      }
    } catch (e) {
      console.log('error:', e);
    }

    this.closeDialog();
  }

  async downloadMedia(file, e) {
    e && e.stopPropagation();
    try {
      return await this.downloadBlob(file.id, file.name, file.downloadUrl);
    } catch (e) {
      console.warn('cant download file: ', file, ' error: ', e);
      this.setState({ error: true });
    }
  }

  async downloadPreloadedFile(file, e) {
    e && e.stopPropagation();
    try {
      const downloadUrl = await this.props.resolvePreloadedFileDownloadUrl(
        file.downloadUrl
      );
      await this.downloadBlob(
        file.id,
        file.downloadUrl.split('/').pop(),
        downloadUrl
      );
    } catch (e) {
      console.warn('cant download file: ', file, ' error: ', e);
      this.setState({ error: true });
    }
  }

  downloadBlob = async (fileId, fileName, downloadUrl) => {
    // ?x-some-key=some-value needed because https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req/856948#856948

    const response = await axios({
      url: downloadUrl + '?x-some-key=some-value',
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress: progressEvent => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        this.setState({ percentCompleted });
      }
    });
    saveAs(response.data, fileName);
    this.closeDialog();
  };

  render() {
    const { open } = this.props;
    const { name, percentCompleted, leaving, error } = this.state;
    return (
      <DownloadStatusDisplay
        open={open}
        name={name}
        percentCompleted={percentCompleted}
        leaving={leaving}
        error={error}
      ></DownloadStatusDisplay>
    );
  }
}

export const DownloadStatusDisplay = ({
  open,
  name,
  percentCompleted,
  leaving,
  error
}) => {
  return open ? (
    <div className={styles.overLay}>
      <div
        className={classnames(styles.filesContainer, {
          [styles.leaving]: leaving
        })}
      >
        <div
          className={classnames(styles.title, {
            [styles.leaving]: leaving
          })}
        >
          <span>
            {leaving
              ? error
                ? 'Downloading File failed!'
                : 'File downloaded successfully!'
              : 'Downloading File'}
          </span>
          <DoneAnimation visible={leaving} error={error} />
        </div>
        <div
          className={classnames(styles.container, {
            [styles.leaving]: leaving
          })}
        >
          <div className={styles.fileInfo}>
            <span>{name}</span>
            {error ? (
              <span className={styles.error}>Error</span>
            ) : (
              <span>{Math.floor(percentCompleted)} %</span>
            )}
          </div>
          <div className={classnames(styles.statusBar, error && styles.error)}>
            <ProgressBar
              type="linear"
              mode="determinate"
              value={percentCompleted}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
};

const mapStateToProps = state => {
  const { open, files } = selectors.getDownloadFileDialog(state);
  return {
    open,
    files
  };
};

const mapDispatchToProps = {
  closeDownloadFileDialog: actions.closeDownloadFileDialog,
  resolvePreloadedFileDownloadUrl: actions.resolvePreloadedFileDownloadUrl
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadFilesModal);
