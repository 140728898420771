import Main from './components/common/Main';
import React, { lazy, Suspense } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import LoginPage from './login/components/LoginPage';
import { CenteredProgressbar } from 'components/common/CenteredProgressbar';
import { isMobile } from 'components/common/withDeviceDetector';
import { isMobile as reactDeviceDetectIsMobile } from 'react-device-detect';
import * as partnerConfig from 'themes/partnerConfig';

const Dashboard = lazy(() => import('./components/dashboard'));
const About = lazy(() => import('./components/dashboard/About'));
const AccountDetails = lazy(() => import('./components/accountDetails'));
const Admin = lazy(() => import('./admin'));
const Availability = lazy(() => import('./components/availability'));
const Profile = lazy(() => import('./components/dashboard/profile'));
const LogOut = lazy(() => import('./components/dashboard/Logout'));
const CrewHub = lazy(() => import('./components/crewHub'));
const Project = lazy(() => import('./video'));
const CreateAccount = lazy(() =>
  import('./login/components/CreateAccountPage')
);
const VideoConference = lazy(() => import('./components/videoConference'));
const VideoConferenceGrid = lazy(() =>
  import('./components/videoConference/GridPopup')
);
const VideoMeeting = lazy(() => import('./officeHours'));
const MobileAccessBlocked = lazy(() =>
  import('./components/common/MobileAccessBlockedPage')
);
const Enroll = lazy(() => import('./components/application/Enroll'));
const CourseLobby = lazy(() => import('./components/courseLobby'));
const Lesson = lazy(() => import('./components/lesson'));
const Register = lazy(() => import('./components/application/Register'));
const ResetPass = lazy(() => import('./login/components/ResetPasswordPage'));
const SelectProgram = lazy(() =>
  import('./components/selectProgram/SelectProgramPage')
);
const ShortLinkRedirection = lazy(() =>
  import('./components/ShortLinkRedirection')
);
const NoProjectsHint = lazy(() => import('./components/common/NoProjectsHint'));

export const MyRoute = ({
  isAdmin,
  isAdminOnly,
  isAuthed,
  notAvailableForMobile,
  isProtected,
  isMentor,
  isMentorOnly,
  isDisabled,
  path,
  Component,
  ...rest
}) => (
  <Route
    path={path}
    render={props => {
      let {
        location: { pathname }
      } = props;

      const allProps = { ...props, ...rest };

      if (
        isDisabled ||
        (isAdminOnly && !isAdmin) ||
        (isMentorOnly && !isMentor)
      ) {
        return <Redirect to={'/'} />;
      }

      if (notAvailableForMobile && isMobile() && reactDeviceDetectIsMobile) {
        return <Redirect to="/mobile-access-blocked" />;
      }

      if (!isProtected) {
        return <Component {...allProps} />;
      }

      return isProtected && isAuthed ? (
        <Component {...allProps} />
      ) : (
        <Redirect to={`/login?redirect=${pathname}`} />
      );
    }}
  />
);

const MainRouter = ({ isAuthed, isAdmin, isMentor }) => (
  <Suspense fallback={<CenteredProgressbar />}>
    <Route
      render={renderProps => (
        <Main {...renderProps}>
          <Switch>
            <Route
              exact
              path="/"
              render={props => {
                if (!isAuthed) {
                  return <Redirect to={'/login'} />;
                }
                return <Dashboard {...props} />;
              }}
            />
            <MyRoute
              path="/login"
              Component={LoginPage}
              isProtected={false}
              isMentor={isMentor}
            />
            <MyRoute
              path="/logout"
              Component={LogOut}
              isProtected={false}
              isMentor={isMentor}
            />
            <MyRoute
              path="/create-account"
              isProtected={false}
              Component={CreateAccount}
              isMentor={isMentor}
            />
            <MyRoute
              path="/reset-password"
              Component={ResetPass}
              isProtected={false}
              isMentor={isMentor}
            />
            <MyRoute
              path="/register/:registrationCode?"
              Component={Register}
              isProtected={false}
              isMentor={isMentor}
            />
            <MyRoute
              path="/enroll/:programPath?"
              Component={Enroll}
              isProtected={false}
              isMentor={isMentor}
              isDisabled={!partnerConfig.enrollEnabled}
            />
            <MyRoute
              path="/courses/:programInstanceId?"
              Component={CourseLobby}
              isAuthed={isAuthed}
              isProtected={true}
              isMentor={isMentor}
              isDisabled={!partnerConfig.topNavLinks.browseCourses}
            />
            <MyRoute
              path="/mobile-access-blocked"
              Component={MobileAccessBlocked}
              isProtected={false}
              isMentor={isMentor}
            />
            <MyRoute
              path={'/dashboard'}
              Component={Dashboard}
              isAuthed={isAuthed}
              isProtected={true}
              isMentor={isMentor}
            />
            <MyRoute
              path="/about"
              Component={About}
              isAuthed={isAuthed}
              isProtected={true}
              isMentor={isMentor}
              notAvailableForMobile={true}
            />
            <MyRoute
              path="/select-program"
              Component={SelectProgram}
              isAuthed={isAuthed}
              isProtected={true}
              isMentor={isMentor}
            />
            <MyRoute
              path="/account-details"
              Component={AccountDetails}
              isAuthed={isAuthed}
              isProtected={true}
              isMentor={isMentor}
            />
            <MyRoute
              exact={true}
              path="/availability/:userId?"
              Component={Availability}
              isAuthed={isAuthed}
              isProtected={true}
              isMentor={isMentor}
              notAvailableForMobile={true}
            />
            <MyRoute
              exact={true}
              path="/project/:id/:tab(info|comments)?"
              Component={Project}
              isAuthed={isAuthed}
              isProtected={true}
              isMentor={isMentor}
              hideAddVideoCommentButton={true}
              noFooter={true}
            />
            <MyRoute
              path="/profile/:viewUserId?"
              Component={Profile}
              isAuthed={isAuthed}
              isProtected={true}
              isMentor={isMentor}
              notAvailableForMobile={true}
            />
            <MyRoute
              path="/crewHub/:crewId/:tab(messages|projects|files|chat)?/:projectId?"
              Component={CrewHub}
              isAuthed={isAuthed}
              isProtected={true}
              isMentor={isMentor}
              notAvailableForMobile={true}
            />
            <MyRoute
              exact={true}
              path="/conference/:id"
              Component={VideoConference}
              isAuthed={isAuthed}
              isProtected={true}
              isMentor={isMentor}
              notAvailableForMobile={true}
            />
            <MyRoute
              exact={true}
              path="/conference/:id/grid"
              Component={VideoConferenceGrid}
              isAuthed={isAuthed}
              isProtected={true}
              isMentor={isMentor}
            />
            <MyRoute
              path="/video-meeting/:token"
              hideAddVideoCommentButton={true}
              Component={VideoMeeting}
              isAuthed={isAuthed}
              isProtected={true}
              isMentor={isMentor}
              notAvailableForMobile={true}
            />
            <MyRoute
              path="/program/:programId/course/:courseId/lesson/:lessonId/:mainAreaType(video|pdf|image|screenplay|project|nsf|object3d)/:mainAreaId/:itemType?/:itemId?"
              Component={Lesson}
              isAuthed={isAuthed}
              isProtected={true}
              isMentor={isMentor}
            />

            <MyRoute
              path="/admin"
              Component={Admin}
              isAuthed={isAuthed}
              isProtected={true}
              isMentor={isMentor}
              notAvailableForMobile={true}
              isAdmin={isAdmin}
              isAdminOnly={true}
            />

            <MyRoute path="/project_ideas" Component={NoProjectsHint} />
            <MyRoute path="/s/:shortLinkId" Component={ShortLinkRedirection} />
            <Route render={props => <Redirect to="/" />} />
          </Switch>
        </Main>
      )}
    />
  </Suspense>
);

export default MainRouter;
