import config from 'config';
import { selectors as sclSelectors } from 'smashcut-client-lib';
import { get } from 'lodash';

const PREFIX = 'CREWS';

export const types = {
  ARCHIVE_CREW: `${PREFIX}/ARCHIVE_CREW`,
  ARCHIVE_CREW_SUCCESS: `${PREFIX}/ARCHIVE_CREW_SUCCESS`,
  ARCHIVE_CREW_FAILURE: `${PREFIX}/ARCHIVE_CREW_FAILURE`,

  CREATE_CREW: `${PREFIX}/CREATE_CREW`,
  CREATE_CREW_SUCCESS: `${PREFIX}/CREATE_CREW_SUCCESS`,
  CREATE_CREW_FAILURE: `${PREFIX}/CREATE_CREW_FAILURE`,

  DELETE_CREW: `${PREFIX}/DELETE_CREW`,
  DELETE_CREW_SUCCESS: `${PREFIX}/DELETE_CREW_SUCCESS`,
  DELETE_CREW_FAILURE: `${PREFIX}/DELETE_CREW_FAILURE`,

  ADD_CREW_MEMBERS: `${PREFIX}/ADD_CREW_MEMBERS`,
  ADD_CREW_MEMBERS_SUCCESS: `${PREFIX}/ADD_CREW_MEMBERS_SUCCESS`,
  ADD_CREW_MEMBERS_FAILURE: `${PREFIX}/ADD_CREW_MEMBERS_FAILURE`,

  REMOVE_CREW_MEMBERS: `${PREFIX}/REMOVE_CREW_MEMBERS`,
  REMOVE_CREW_MEMBERS_SUCCESS: `${PREFIX}/REMOVE_CREW_MEMBERS_SUCCESS`,
  REMOVE_CREW_MEMBERS_FAILURE: `${PREFIX}/REMOVE_CREW_MEMBERS_FAILURE`,

  UPDATE_CREW: `${PREFIX}/UPDATE_CREW`,
  UPDATE_CREW_SUCCESS: `${PREFIX}/UPDATE_CREW_SUCCESS`,
  UPDATE_CREW_FAILURE: `${PREFIX}/UPDATE_CREW_FAILURE`,

  UPDATE_CREW_NAME: `${PREFIX}/UPDATE_CREW_NAME`,
  UPDATE_CREW_NAME_SUCCESS: `${PREFIX}/UPDATE_CREW_NAME_SUCCESS`,
  UPDATE_CREW_NAME_FAILURE: `${PREFIX}/UPDATE_CREW_NAME_FAILURE`,

  UPDATE_MEMBER_ROLE: `${PREFIX}/UPDATE_MEMBER_ROLE`,
  UPDATE_MEMBER_ROLE_SUCCESS: `${PREFIX}/UPDATE_MEMBER_ROLE_SUCCESS`,
  UPDATE_MEMBER_ROLE_FAILURE: `${PREFIX}/UPDATE_MEMBER_ROLE_FAILURE`,

  UPDATE_CREW_NOTES: `${PREFIX}/UPDATE_CREW_NOTES`,
  UPDATE_CREW_NOTES_SUCCESS: `${PREFIX}/UPDATE_CREW_NOTES_SUCCESS`,
  UPDATE_CREW_NOTES_FAILURE: `${PREFIX}/UPDATE_CREW_NOTES_FAILURE`,

  UPDATE_CREW_LAST_SEEN: `${PREFIX}/UPDATE_CREW_LAST_SEEN`,
  UPDATE_CREW_LAST_SEEN_SUCCESS: `${PREFIX}/UPDATE_CREW_LAST_SEEN_SUCCESS`,
  UPDATE_CREW_LAST_SEEN_FAILURE: `${PREFIX}/UPDATE_CREW_LAST_SEEN_FAILURE`,

  SUBMIT_PROJECT: `${PREFIX}/SUBMIT_PROJECT`,
  SUBMIT_PROJECT_SUCCESS: `${PREFIX}/SUBMIT_PROJECT_SUCCESS`,
  SUBMIT_PROJECT_FAILURE: `${PREFIX}/SUBMIT_PROJECT_FAILURE`,

  SUBSCRIBE_TO_LAST_SEENS: `${PREFIX}/SUBSCRIBE_TO_LAST_SEENS`,
  UNSUBSCRIBE_FROM_LAST_SEENS: `${PREFIX}/UNSUBSCRIBE_FROM_LAST_SEENS`,
  LAST_SEENS_RECEIVED: `${PREFIX}/LAST_SEENS_RECEIVED`,

  SUBSCRIBE_TO_MESSAGES: `${PREFIX}/SUBSCRIBE_TO_MESSAGES`,
  UNSUBSCRIBE_FROM_MESSAGES: `${PREFIX}/UNSUBSCRIBE_FROM_MESSAGES`,
  MESSAGES_RECEIVED: `${PREFIX}/MESSAGES_RECEIVED`,

  SEND_CREW_MESSAGE: `${PREFIX}/SEND_CREW_MESSAGE`,
  SEND_CREW_MESSAGE_SUCCESS: `${PREFIX}/SEND_CREW_MESSAGE_SUCCESS`,
  SEND_CREW_MESSAGE_FAILURE: `${PREFIX}/SEND_CREW_MESSAGE_FAILURE`,

  REMOVE_CREW_MESSAGE: `${PREFIX}/REMOVE_CREW_MESSAGE`,
  REMOVE_CREW_MESSAGE_SUCCESS: `${PREFIX}/REMOVE_CREW_MESSAGE_SUCCESS`,
  REMOVE_CREW_MESSAGE_FAILURE: `${PREFIX}/REMOVE_CREW_MESSAGE_FAILURE`,

  REMOVE_CREW_FILE: `${PREFIX}/REMOVE_CREW_FILE`,
  REMOVE_CREW_FILE_SUCCESS: `${PREFIX}/REMOVE_CREW_FILE_SUCCESS`,
  REMOVE_CREW_FILE_FAILURE: `${PREFIX}/REMOVE_CREW_FILE_FAILURE`,

  RESOLVE_DOWNLOAD_URL: `${PREFIX}/RESOLVE_DOWNLOAD_URL`,
  RESOLVE_DOWNLOAD_URL_SUCCESS: `${PREFIX}/RESOLVE_DOWNLOAD_URL_SUCCESS`,

  INITIALIZE_CREW_VIDEO_MEETING: `${PREFIX}/INITIALIZE_CREW_VIDEO_MEETING`,
  INITIALIZE_CREW_VIDEO_MEETING_SUCCESS: `${PREFIX}/INITIALIZE_CREW_VIDEO_MEETING_SUCCESS`,
  INITIALIZE_CREW_VIDEO_MEETING_FAILURE: `${PREFIX}/INITIALIZE_CREW_VIDEO_MEETING_FAILURE`,

  SAVE_VIDEO_CALL_MESSAGE: `${PREFIX}/SAVE_VIDEO_CALL_MESSAGE`,
  SAVE_VIDEO_CALL_MESSAGE_SUCCESS: `${PREFIX}/SAVE_VIDEO_CALL_MESSAGE_SUCCESS`,
  SAVE_VIDEO_CALL_MESSAGE_FAILURE: `${PREFIX}/SAVE_VIDEO_CALL_MESSAGE_FAILURE`,

  GET_VIDEO_CALL_MESSAGES: `${PREFIX}/GET_VIDEO_CALL_MESSAGES`,
  GET_VIDEO_CALL_MESSAGES_SUCCESS: `${PREFIX}/GET_VIDEO_CALL_MESSAGES_SUCCESS`,
  GET_VIDEO_CALL_MESSAGES_FAILURE: `${PREFIX}/GET_VIDEO_CALL_MESSAGES_FAILURE`,
  UPDATE_CREW_LAST_SEEN_PER_CHAT_ID: `${PREFIX}/UPDATE_CREW_LAST_SEEN_PER_CHAT_ID`,

  SELECT_CHAT: `${PREFIX}/SELECT_CHAT`,
  DESELECT_CHAT: `${PREFIX}/DESELECT_CHAT`
};

const initialState = {};

export const crewsReducer = (state = initialState, action) => {
  let nextState = state;
  let shouldRefetch = false;
  switch (action.type) {
    case types.ARCHIVE_CREW:
      console.log('[red] archiving crew', action);
      nextState = { ...state, archiving: true };
      break;

    case types.ARCHIVE_CREW_SUCCESS:
    case types.ARCHIVE_CREW_FAILURE:
      nextState = { ...state, archiving: false };
      shouldRefetch = true;
      break;

    case types.CREATE_CREW:
      console.log('[red] creating crew', action);
      nextState = { ...state, creating: true };
      nextState.reviewItem = action.item;
      break;

    case types.CREATE_CREW_SUCCESS:
    case types.CREATE_CREW_FAILURE:
      nextState = { ...state, creating: false };
      shouldRefetch = true;
      break;

    case types.DELETE_CREW:
      console.log('[red] deleting crew', action);
      nextState = { ...state, deleting: true };
      break;

    case types.DELETE_CREW_SUCCESS:
    case types.DELETE_CREW_FAILURE:
      nextState = { ...state, deleting: false };
      shouldRefetch = true;
      break;

    case types.ADD_CREW_MEMBERS:
      nextState = { ...state, addingCrewMembers: true };
      break;

    case types.ADD_CREW_MEMBERS_SUCCESS:
    case types.ADD_CREW_MEMBERS_FAILURE:
      console.log('[red] add crew member success / failure');
      nextState = { ...state, addingCrewMembers: false };
      shouldRefetch = true;
      break;

    case types.REMOVE_CREW_MEMBERS:
      nextState = { ...state, removingCrewMembers: true };
      break;

    case types.REMOVE_CREW_MEMBERS_SUCCESS:
    case types.REMOVE_CREW_MEMBERS_FAILURE:
      console.log('[red] remove crew member success / failure');
      nextState = { ...state, removingCrewMembers: false };
      shouldRefetch = true;
      break;

    case types.UPDATE_MEMBER_ROLE:
      nextState = { ...state, updatingMemberRole: true };
      break;

    case types.UPDATE_MEMBER_ROLE_SUCCESS:
    case types.UPDATE_MEMBER_ROLE_FAILURE:
      nextState = { ...state, updatingMemberRole: false };
      shouldRefetch = true;
      break;

    case types.UPDATE_CREW:
      nextState = { ...state, updatingCrew: true };
      break;

    case types.UPDATE_CREW_SUCCESS:
    case types.UPDATE_CREW_FAILURE:
      nextState = { ...state, updatingCrew: false };
      shouldRefetch = true;
      break;

    case types.UPDATE_CREW_LAST_SEEN:
      nextState = { ...state, updatingCrewLastSeen: true };
      break;

    case types.UPDATE_CREW_LAST_SEEN_SUCCESS:
    case types.UPDATE_CREW_LAST_SEEN_FAILURE:
      nextState = {
        ...state,
        updatingCrewLastSeen: false,
        lastSeenPerChatId: {
          ...state.lastSeenPerChatId,
          [action.lastSeen.chatId]: action.lastSeen
        }
      };
      break;

    case types.UPDATE_CREW_NOTES:
      nextState = { ...state, updatingNotes: true };
      break;

    case types.UPDATE_CREW_NOTES_SUCCESS:
    case types.UPDATE_CREW_NOTES_FAILURE:
      nextState = { ...state, updatingNotes: false };
      shouldRefetch = true;
      break;

    case types.SEND_CREW_MESSAGE:
      nextState = { ...state, sendingMessage: true };
      break;

    case types.SEND_CREW_MESSAGE_SUCCESS:
    case types.SEND_CREW_MESSAGE_FAILURE:
      nextState = { ...state, sendingMessage: false };
      break;

    case types.REMOVE_CREW_MESSAGE:
      nextState = { ...state, removingMessage: true };
      break;

    case types.REMOVE_CREW_MESSAGE_SUCCESS:
    case types.REMOVE_CREW_MESSAGE_FAILURE:
      nextState = { ...state, removingMessage: false };
      break;

    case types.SUBMIT_PROJECT:
      nextState = { ...state, submitting: true };
      break;

    case types.SUBMIT_PROJECT_SUCCESS:
    case types.SUBMIT_PROJECT_FAILURE:
      nextState = { ...state, submitting: false };
      break;

    case types.RESOLVE_DOWNLOAD_URL:
      nextState = { ...state, downloadUrl: undefined };
      break;

    case types.RESOLVE_DOWNLOAD_URL_SUCCESS:
      nextState = { ...state, downloadUrl: action.downloadUrl };
      break;

    case types.LAST_SEENS_RECEIVED:
      nextState = { ...state, lastSeens: action.lastSeens };
      break;

    case types.MESSAGES_RECEIVED:
      nextState = { ...state, messages: action.messages };
      break;

    case types.REMOVE_CREW_FILE:
      nextState = { ...state, removingFile: action.myFileId };
      break;

    case types.REMOVE_CREW_FILE_SUCCESS:
      nextState = { ...state, removingFile: undefined };

      break;

    case types.INITIALIZE_CREW_VIDEO_MEETING_SUCCESS:
      nextState = {
        ...state,
        meeting: action.meeting,
        conferenceId: action.meeting.id
      };
      break;

    case types.GET_VIDEO_CALL_MESSAGES_SUCCESS:
      nextState = { ...state, videoCallMessages: action.messages };
      break;

    case types.SELECT_CHAT:
      nextState = { ...state, chat: action.chat };
      break;

    case types.DESELECT_CHAT:
      nextState = { ...state, chat: undefined };
      break;

    case types.UPDATE_CREW_LAST_SEEN_PER_CHAT_ID:
      nextState = {
        ...state,
        lastSeenPerChatId: action.lastSeenPerChatId
      };
      break;
  }

  if (state.shouldRefetch !== shouldRefetch) {
    nextState = { ...nextState, shouldRefetch };
  }

  return nextState;
};

export const archiveCrew = input => ({
  type: types.ARCHIVE_CREW,
  input
});

export const createCrew = input => ({
  type: types.CREATE_CREW,
  input
});

export const deleteCrew = id => ({
  type: types.DELETE_CREW,
  id
});

export const addCrewMembers = (crewId, users) => ({
  type: types.ADD_CREW_MEMBERS,
  crewId,
  users
});

export const removeCrewMembers = (crewId, users) => ({
  type: types.REMOVE_CREW_MEMBERS,
  crewId,
  users
});

export const updateMemberRole = (crewId, userId, role) => ({
  type: types.UPDATE_MEMBER_ROLE,
  crewId,
  userId,
  role
});

export const updateCrew = input => ({
  type: types.UPDATE_CREW,
  input
});

export const updateCrewNotes = (crewId, notes) => ({
  type: types.UPDATE_CREW_NOTES,
  crewId,
  notes
});

export const updateCrewName = (id, name) => ({
  type: types.UPDATE_CREW_NAME,
  id,
  name
});

export const getLastSeens = (crewId, userId, callback) => ({
  type: types.SUBSCRIBE_TO_LAST_SEENS,
  crewId,
  userId,
  callback
});

export const subscribeToMessages = (crewId, callback) => ({
  type: types.SUBSCRIBE_TO_MESSAGES,
  crewId,
  callback
});

export const unsubscribeFromMessages = crewId => ({
  type: types.UNSUBSCRIBE_FROM_MESSAGES,
  crewId
});

export const updateLastSeenPerChatId = lastSeenPerChatId => ({
  type: types.UPDATE_CREW_LAST_SEEN_PER_CHAT_ID,
  lastSeenPerChatId
});

// because MessageBoard is used in discussions too, crewId comes last
export const sendCrewMessage = (
  chatId,
  userId,
  message,
  file,
  video,
  crewId,
  privateRecipientId
) => ({
  type: types.SEND_CREW_MESSAGE,
  chatId,
  crewId,
  userId,
  message,
  file,
  video,
  privateRecipientId
});

export const removeCrewMessage = ({ crewId, id: messageId }) => ({
  type: types.REMOVE_CREW_MESSAGE,
  crewId,
  messageId
});

export const resolveDownloadUrl = url => ({
  type: types.RESOLVE_DOWNLOAD_URL,
  url
});

export const removeCrewFile = (userId, crewId, projectId, myFileId) => ({
  type: types.REMOVE_CREW_FILE,
  userId,
  crewId,
  projectId,
  myFileId
});

export const submitProject = input => ({
  type: types.SUBMIT_PROJECT,
  input
});

export const initializeCrewVideoMeeting = crewId => ({
  type: types.INITIALIZE_CREW_VIDEO_MEETING,
  crewId
});

export const updateLastSeen = (userId, chatId, time, crewId) => ({
  type: types.UPDATE_CREW_LAST_SEEN,
  crewId,
  userId,
  chatId,
  time
});

export const saveVideoCallMessage = (crewId, senderId, message) => ({
  type: types.SAVE_VIDEO_CALL_MESSAGE,
  crewId,
  senderId,
  message
});

export const getVideoCallMessages = crewId => ({
  type: types.GET_VIDEO_CALL_MESSAGES,
  crewId
});

export const selectChat = chat => ({
  type: types.SELECT_CHAT,
  chat
});

export const deselectChat = () => ({
  type: types.DESELECT_CHAT
});

export const actions = {
  addCrewMembers,
  archiveCrew,
  createCrew,
  deleteCrew,
  deselectChat,
  getVideoCallMessages,
  initializeCrewVideoMeeting,
  removeCrewFile,
  removeCrewMembers,
  removeCrewMessage,
  resolveDownloadUrl,
  saveVideoCallMessage,
  selectChat,
  sendCrewMessage,
  submitProject,
  getLastSeens,
  subscribeToMessages,
  unsubscribeFromMessages,
  updateCrew,
  updateCrewName,
  updateCrewNotes,
  updateLastSeen,
  updateMemberRole,
  updateLastSeenPerChatId
};

export const selectors = {
  getAddingMember: state => state.dashboard.crews.addingCrewMembers,
  getChat: state => state.dashboard.crews.chat,
  getCreating: state => state.dashboard.crews.creating,
  getConferenceId: state =>
    state.dashboard.crews.meeting && state.dashboard.crews.meeting.id,
  getDeleting: state => state.dashboard.crews.deleting,
  getDownloadUrl: state => state.dashboard.crews.downloadUrl,
  getLastSeen: (state, chatId) => {
    const user = sclSelectors.getCurrentUser(state);
    const lastSeen =
      (user.lastSeen && user.lastSeen[chatId]) || new Date(0).toISOString();
    return lastSeen;
  },
  getMeeting: state => state.dashboard.crews.meeting,
  getRemovingMember: state => state.dashboard.crews.removingCrewMembers,
  getSendingMessage: state => state.dashboard.crews.sendingMessage,
  getShouldRefetch: state => state.dashboard.crews.shouldRefetch,
  getUpdatingCrew: state => state.dashboard.crews.updatingCrew,
  getUpdatingMemberRole: state => state.dashboard.crews.updatingMemberRole,
  getIsUsingCrewFilesInsteadOfAssets: state => {
    const cup = sclSelectors.getCurrentUserProgram(state);
    return get(
      cup,
      'programInstance.isUsingCrewFilesInsteadOfAssets',
      config.isUsingCrewFilesInsteadOfAssets
    );
  },
  getVideoCallMessages: state => state.dashboard.crews.videoCallMessages,
  getLastSeenPerChatId: state => state.dashboard.crews.lastSeenPerChatId
};
