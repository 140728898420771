import { gql } from '@apollo/client';

export const toggleDiscussionTab = apolloClient => async programInstanceId => {
  return await apolloClient.mutate({
    mutation: gql`
      mutation toggleDiscussionTab($programInstanceId: ID!) {
        toggleDiscussionTab(programInstanceId: $programInstanceId)
      }
    `,
    variables: {
      programInstanceId
    },
    refetchQueries: ['getAdminData']
  });
};
