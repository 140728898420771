import { selectors as sclSelectors } from 'smashcut-client-lib';
import { actions as uploadActions } from '../uploads';
import { UPLOAD_TYPE } from 'components/common/chat/constants';
import { logAndSendError } from 'utils/sentryHelper';

const PREFIX = 'DASHBOARD/VIDEOMESSAGE';

export const types = {
  ADD_VIDEO_MESSAGE_DISCUSSIONS: `${PREFIX}/ADD_VIDEO_MESSAGE_DISCUSSIONS`,
  REMOVE_VIDEO_MESSAGE_DISCUSSIONS: `${PREFIX}/REMOVE_VIDEO_MESSAGE_DISCUSSIONS`,
  ADD_VIDEO_MESSAGE_CREWHUB: `${PREFIX}/ADD_VIDEO_MESSAGE_CREWHUB`,
  REMOVE_VIDEO_MESSAGE_CREWHUB: `${PREFIX}/REMOVE_VIDEO_MESSAGE_CREWHUB`,
  ADD_VIDEO_MESSAGE_ERROR: `${PREFIX}/ADD_VIDEO_MESSAGE_ERROR`,
  ADDING_IN_PROGRESS: `${PREFIX}/ADDING_IN_PROGRESS`
};

const initialState = {
  videoMessageDiscussions: null,
  videoMessageCrewHub: null,
  loading: false,
  error: ''
};

export const videoMessageReducer = (state = initialState, action) => {
  let nextState = state;
  switch (action.type) {
    case types.ADDING_IN_PROGRESS:
      nextState = { ...state, loading: true };
      break;
    case types.ADD_VIDEO_MESSAGE_DISCUSSIONS:
      nextState = {
        ...state,
        videoMessageDiscussions: action.item || 'Error',
        loading: false
      };
      break;
    case types.REMOVE_VIDEO_MESSAGE_DISCUSSIONS:
      nextState = { ...state, videoMessageDiscussions: null };
      break;
    case types.ADD_VIDEO_MESSAGE_CREWHUB:
      nextState = {
        ...state,
        videoMessageCrewHub: action.item || 'Error',
        loading: false
      };
      break;
    case types.REMOVE_VIDEO_MESSAGE_CREWHUB:
      nextState = { ...state, videoMessageCrewHub: null };
      break;
    case types.ADD_VIDEO_MESSAGE_ERROR:
      nextState = { ...state, loading: false, error: action.error };
  }

  return nextState;
};

const startVideoMessageUpload = (video, place) => (
  dispatch,
  getState,
  { api }
) => {
  const fullState = getState();
  const uploadCreatorId = sclSelectors.getCurrentUserId(fullState);

  dispatch({ type: types.ADDING_IN_PROGRESS });

  return dispatch(
    uploadActions.startUpload({
      sourceFile: video.blob,
      fileType: 'VIDEO',
      uploadOwnerId: uploadCreatorId,
      uploadType: UPLOAD_TYPE.VIDEO_MESSAGE
    })
  )
    .then(uploadRecord => {
      if (place === 'discussions') {
        return dispatch({
          type: types.ADD_VIDEO_MESSAGE_DISCUSSIONS,
          item: uploadRecord
        });
      }
      return dispatch({
        type: types.ADD_VIDEO_MESSAGE_CREWHUB,
        item: uploadRecord
      });
    })
    .catch(err => {
      console.log(err);
      logAndSendError(err, 'error uploading intro video');
      dispatch({ type: types.ADD_VIDEO_MESSAGE_ERROR, error: err });
    });
};

const removeVideoMessageDiscussions = () => ({
  type: types.REMOVE_VIDEO_MESSAGE_DISCUSSIONS
});

const removeVideoMessageCrewHub = () => ({
  type: types.REMOVE_VIDEO_MESSAGE_CREWHUB
});

export const actions = {
  startVideoMessageUpload,
  removeVideoMessageDiscussions,
  removeVideoMessageCrewHub
};

export const selectors = {
  getVideoMessageDiscussions: state =>
    state.dashboard.videoMessage.videoMessageDiscussions,
  getVideoMessageCrewHub: state =>
    state.dashboard.videoMessage.videoMessageCrewHub,
  isLoading: state => state.dashboard.videoMessage.loading
};
