// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VideoMarkers--markers--3CwB2ThO{height:10px}@media (max-width: 1000px){.VideoMarkers--markers--3CwB2ThO{z-index:3332}}.VideoMarkers--notePanel--Edg3Ar9A{color:#000000;font-size:12px;margin-left:-20px;bottom:18px;min-width:170px;max-width:250px;max-height:150px;overflow:hidden;padding:20px;background:white;position:absolute;opacity:0;-webkit-transition:opacity 0.25s;transition:opacity 0.25s;border-bottom:2px solid #377B9B;color:rgba(0, 0, 0, .7)}@media (max-width: 1000px){.VideoMarkers--notePanel--Edg3Ar9A{display:none}}.VideoMarkers--notePanel--Edg3Ar9A.VideoMarkers--notePanelVisible--3JzP4f2Q{opacity:1}.VideoMarkers--notePanel--Edg3Ar9A .VideoMarkers--playOrLoopContainer--1lsujhgC{margin-top:15px}\n", ""]);
// Exports
exports.locals = {
	"markers": "VideoMarkers--markers--3CwB2ThO",
	"notePanel": "VideoMarkers--notePanel--Edg3Ar9A",
	"notePanelVisible": "VideoMarkers--notePanelVisible--3JzP4f2Q",
	"playOrLoopContainer": "VideoMarkers--playOrLoopContainer--1lsujhgC"
};
module.exports = exports;
