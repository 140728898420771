import {
  apolloClient,
  apolloClientNoAuth,
  authService
} from 'smashcut-client-lib/services';
import { acceptUserToProgram } from './acceptUserToProgram';
import { addEnrollee } from './addEnrollee';
import { addProgramInstance } from './addProgramInstance';
import { deployProgram } from './deployProgram';
import { enrollTrialUser } from './enrollTrialUser';
import { enrollUserInProgram } from './enrollUserInProgram';
import { impersonate } from './impersonate';
import { makeUserInstructor } from './makeUserInstructor';
import { removeEnrollee } from './removeEnrollee';
import { removeTrialUser } from './removeTrialUser';
import { repairDb } from './repairDb';
import { setProgramIsTrash } from './setProgramIsTrash';
import { setProjectStatus } from './setProjectStatus';
import { testDbStructure } from './testDbStructure';
import { toggleProgramHasEnded } from './toggleProgramHasEnded';
import { toggleDiscussionTab } from './toggleDiscussionTab';
import { updatePassword } from './updatePassword';
import { updateProgram } from './updateProgram';
import { updateProgramForEnrollment } from './updateProgramForEnrollment';
import { updateRole } from './updateRole';
import { uploadProgramBackground } from './uploadProgramBackground';
import { toggleIsUsingCrewFiles } from './toggleIsUsingCrewFiles';
import { getAllUserPrograms } from './getAllUserPrograms';
import { loadAllUsers } from './loadAllUsers';

export default config => {
  const authSrvc = authService(config);
  return {
    acceptUserToProgram: acceptUserToProgram(apolloClient),
    addEnrollee: addEnrollee(apolloClient),
    addEnrolleeNoAuth: addEnrollee(apolloClientNoAuth),
    addProgramInstance: addProgramInstance(apolloClient),
    deployProgram: deployProgram(apolloClient),
    enrollTrialUser: enrollTrialUser(apolloClient),
    enrollUserInProgram: enrollUserInProgram(apolloClient),
    getAllUserPrograms: getAllUserPrograms(apolloClient),
    impersonate: impersonate(apolloClient, authSrvc),
    loadAllUsers: loadAllUsers(apolloClient),
    makeUserInstructor: makeUserInstructor(apolloClient),
    removeEnrollee: removeEnrollee(apolloClient),
    removeTrialUser: removeTrialUser(apolloClient),
    repairDb: repairDb(apolloClient),
    setProgramIsTrash: setProgramIsTrash(apolloClient),
    setProjectStatus: setProjectStatus(apolloClient),
    testDbStructure: testDbStructure(apolloClient),
    toggleProgramHasEnded: toggleProgramHasEnded(apolloClient),
    toggleDiscussionTab: toggleDiscussionTab(apolloClient),
    toggleIsUsingCrewFiles: toggleIsUsingCrewFiles(apolloClient),
    updatePassword: updatePassword(apolloClient),
    updateProgram: updateProgram(apolloClient),
    updateProgramForEnrollment: updateProgramForEnrollment(apolloClient),
    updateRole: updateRole(apolloClient),
    uploadProgramBackground: uploadProgramBackground(apolloClient)
  };
};
