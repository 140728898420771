import { gql } from '@apollo/client';

export class CommentSection {
  constructor(apolloClient) {
    this.apolloClient = apolloClient;
  }

  connect(propsOrResolve) {
    this.subscription && this.subscription.unsubscribe();

    const GET_COMMENTS = gql`
      query getComments($commentSectionId: ID!) {
        comments(commentSectionId: $commentSectionId) {
          id
          author
          authorUser {
            id
            fullName
            avatar
            roleLabel
            isMentor
            isStaff
          }
          msg
          time
          duration
          imageProjectId
          imageId
          imageUrl
          hotPoints {
            position {
              x
              y
              z
            }
            normal {
              x
              y
              z
            }
          }
          pin {
            x
            y
            color
            imgRect {
              x
              y
              width
              height
            }
          }
          lastUpdate
          videoUrl
          created
          chunkIndex
          videoMetadata {
            videoHeight
            videoWidth
          }
        }
        replies(commentSectionId: $commentSectionId) {
          id
          parentId
          author
          duration
          lastUpdate
          videoUrl
          authorUser {
            id
            fullName
            avatar
            roleLabel
            isMentor
            isStaff
          }
          msg
          time
          created
          chunkIndex
          videoMetadata {
            videoHeight
            videoWidth
          }
        }
      }
    `;

    const SUBSCRIBE_TO_COMMENTS = gql`
      subscription onComment($commentSectionId: String) {
        onCommentSectionUpdate(commentSectionId: $commentSectionId) {
          updateType
        }
      }
    `;

    console.log(
      'propsOrResolve.commentSectionId: ',
      propsOrResolve.commentSectionId
    );

    this.subscription = this.apolloClient
      .subscribe({
        query: SUBSCRIBE_TO_COMMENTS,
        variables: {
          commentSectionId: propsOrResolve.commentSectionId
        }
      })
      .subscribe({
        next(data) {
          const update = data.data.onCommentSectionUpdate || {};
          console.log('onCommentSectionUpdate', update);
          const comment = update.comment;
          switch (update.updateType) {
            case 'COMMENT_ADDED':
              propsOrResolve.onCommentValue({
                key: comment.id,
                value: comment
              });
              break;
            case 'REPLY_ADDED':
              propsOrResolve.onReplyValue({ key: comment.id, value: comment });
              break;
            case 'COMMENT_REMOVED':
              propsOrResolve.onCommentValue({ key: comment.id, value: null });
              break;
            case 'REPLY_REMOVED':
              propsOrResolve.onReplyValue({ key: comment.id, value: null });
              break;
          }
        },
        error(err) {
          console.error('Error subscribing to comments', err);
        }
      });

    this.apolloClient
      .query({
        query: GET_COMMENTS,
        fetchPolicy: 'network-only',
        variables: {
          commentSectionId: propsOrResolve.commentSectionId
        }
      })
      .then(({ data }) => {
        const comments = data.comments;
        const replies = data.replies;
        comments.forEach(c =>
          propsOrResolve.onCommentValue({ key: c.id, value: c })
        );
        replies.forEach(c =>
          propsOrResolve.onReplyValue({ key: c.id, value: c })
        );
        propsOrResolve.onComplete();
      });
  }

  disconnect() {
    this.subscription && this.subscription.unsubscribe();
  }
}
