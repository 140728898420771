import { gql } from '@apollo/client';
import { uid } from '../../utils/uid';

const ADD_MY_FILE_COMMON = `
  addMyFile(input: $input) {
    id
    baseUrl
    created
    description
    downloadUrl
    fileType
    name
    ownerId
    status
    thumbnailUrl
  }
`;

const ADD_MY_FILE = gql`
  mutation addMyFile($input: AddMyFileInput!) {
    ${ADD_MY_FILE_COMMON}
  }
`;

const ADD_MY_FILE_TO_CREW = gql`
  mutation addMyFile($input: AddMyFileInput!, $projectId: ID!, $crewId: ID!, $myFileId: ID!) {
    addMyFile: ${ADD_MY_FILE_COMMON}
    addToCrew: addCrewFile(crewId: $crewId, projectId: $projectId, myFileId: $myFileId)
  }
`;

export function addMyFile(apolloClient) {
  return (input, crewId, projectId) => {
    const newId = `${input.isSharedFile || ''}${uid()}`;
    return apolloClient
      .mutate({
        mutation: crewId && projectId ? ADD_MY_FILE_TO_CREW : ADD_MY_FILE,
        variables: {
          input: {
            id: newId,
            description: input.description || '',
            downloadUrl: input.downloadUrl || '',
            error: input.error || '',
            fileMimeType: input.fileMimeType || '',
            fileName: input.fileName || '',
            fileSize: input.fileSize || 0,
            fileType: input.fileType || '',
            finished: input.finished || '',
            isVisible: input.isVisible || true,
            isCrewFile: !!(crewId && projectId),
            crewId: crewId,
            crewProjectId: projectId,
            name: input.name || '',
            ownerId: input.ownerId || '',
            started: input.started || '',
            status: input.status || '',
            uploadDurationMs: input.uploadDurationMs || 0,
            uploadPath: input.uploadPath || '',
            parentId: input.parentId || '',
            isSharedFile: !!input.isSharedFile,
            images: input.images
          },
          crewId,
          projectId,
          myFileId: newId
        },
        refetchQueries: crewId && [`getCrew`]
      })
      .then(response => response.data.addMyFile);
  };
}
