import * as partnerConfig from 'themes/partnerConfig';
import AppUpdateDialog from './AppUpdateDialog';
import CenteredLogo from 'themes/CenteredLogo';
import config from 'config';
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import SelectProgramPopup from '../selectProgram/SelectProgramPopup';
import StickyImpersonateWarning from 'admin/components/StickyImpersonateWarning';
import styles from './Preloader.scss';
import { connect } from 'react-redux';
import { getCurrentUser } from 'smashcut-client-lib/selectors';
import { withDeviceDetector } from 'components/common/withDeviceDetector';
import { useLocation } from 'react-router-dom';
import { startCase, toLower, includes, last, keys } from 'lodash';
import { changeLocation } from 'smashcut-client-lib/actions';
import MobileMarkerModal from './../lesson/MobileMarkerModal';
import MobileBetaWarning from 'components/common/MobileBetaWarning';
import { isMobile as reactDeviceDetectIsMobile } from 'react-device-detect';

const titles = {
  program: 'Lessons',
  project: 'File',
  crewHub: 'Crew Hub',
  crews: 'Crews',
  students: 'Students',
  myfiles: 'My Files',
  conference: 'Conference',
  'video-meeting': 'Video Meeting',
  profile: 'Profile',
  projects: 'Projects',
  'projects-for-review': 'Projects For Review',
  discussions: 'Discussions'
};

const setPageTitle = (loc, title, formattedTitle) => {
  const tKey = keys(titles).find(titleKey => includes(loc, titleKey));

  if (tKey) {
    document.title = `${title} - ${titles[tKey]}`;
    return;
  }
  document.title = formattedTitle ? `${title} - ${formattedTitle}` : title;
};

const Preloader = withDeviceDetector(
  ({
    firebaseUp,
    MainRouter,
    isAuthed,
    isAdmin,
    isMentor,
    changeLocation,
    auth0InProgress,
    isMobile
  }) => {
    let location = useLocation();
    const [title, setTitle] = useState(document.title);

    useEffect(() => {
      document.title = '';
      const loc = location.pathname.split('/');
      const pageTitle = last(loc);
      const formattedTitle = startCase(toLower(pageTitle.replace('-', ' ')));
      setPageTitle(loc, title, formattedTitle);
      changeLocation(location);
    }, [location.pathname]);

    const blockMobile =
      !config.disableMobileBlocking && // per env
      partnerConfig.blockMobileAccess && // per theme
      isMobile &&
      reactDeviceDetectIsMobile;

    const isEnroll = location.pathname === '/enroll';
    if (!isEnroll && (blockMobile || !firebaseUp || auth0InProgress)) {
      return (
        <CenteredLogo>
          {blockMobile && (
            <div className={styles.mobileMessage}>
              <p>Mobile Coming Soon.</p>
              <p>
                For the time being, please <br />
                visit Smashcut from your <br />
                laptop or desktop.
              </p>
              <p> Thanks for your patience!</p>
              <p>
                {' '}
                Note: If you're seeing this message on your
                <br />
                laptop or desktop, please expand
                <br />
                your browser window and reload.
              </p>
            </div>
          )}
        </CenteredLogo>
      );
    }

    return (
      <>
        <MainRouter isAuthed={isAuthed} isAdmin={isAdmin} isMentor={isMentor} />
        <AppUpdateDialog />
        <StickyImpersonateWarning />
        <SelectProgramPopup />
        {isMobile && reactDeviceDetectIsMobile && <MobileBetaWarning />}
        <MobileMarkerModal />
        <ReactTooltip
          id="rtt-grey"
          effect="solid"
          globalEventOff="click"
          className={styles.tooltip}
          backgroundColor="#444444"
        />
      </>
    );
  }
);

export const mapStateToProps = state => {
  const user = getCurrentUser(state);
  const auth = state.auth || {};
  const firebaseUp = !!(auth.firebaseUp && (!auth.firebaseUser || user));
  const isAuthed = state.auth != null && state.auth.firebaseUser != null;
  const isAdmin = user && user.isAdmin;
  const isMentor = user && user.isMentor;
  const auth0InProgress =
    config.auth0Enabled && window.location.href.indexOf('access_token') >= 0;

  return { firebaseUp, isAuthed, isAdmin, isMentor, auth0InProgress };
};

const mapDispatchToProps = {
  changeLocation: changeLocation
};

export default connect(mapStateToProps, mapDispatchToProps)(Preloader);
