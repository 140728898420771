import GeneralToastNotification from 'components/dashboard/GeneralToastNotification';
import Header from './Header';
import HelpModal from 'components/dashboard/syllabus/HelpModal';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import NotificationSettingsPopupContainer from 'components/notificationSettings/NotificationSettingsPopupContainer';
import React from 'react';
import RelatedVideoPlayer from 'components/relatedVideoPlayer/RelatedVideoPlayer';
import SmashcutTheme from 'styles/react-toolbox/SmashcutTheme';
import styles from './Main.scss';
import ToastNotification from './ToastNotification';
import { actions as notificationActions } from 'reducers/notifications';
import { connect } from 'react-redux';
import { getMessages } from 'smashcut-client-lib/selectors';
import { ThemeProvider } from 'react-css-themr';
import CookieConsent from './CookieConsent';
import { hot } from 'react-hot-loader/root';
import DownloadFilesModal from '../dashboard/myfiles/DownloadFilesModal';
import { actions as myFileActions } from '../../reducers/dashboard/myFiles';
import { selectors as selectProgramSelectors } from 'components/selectProgram/selectProgramReducer';
import { CenteredProgressbar } from 'components/common/CenteredProgressbar';
import { SmashcutIframe } from './SmashcutIframe';
import CreateMyFilePopup from '../dashboard/CreateMyFilePopup';
import CreateProjectPopup from '../dashboard/CreateProjectPopup';
import * as Sentry from '@sentry/react';
import { ErrorFallbackComponent } from './ErrorFallbackComponent';

const MainContentWithSentryErrorBoundary = props => (
  <Sentry.ErrorBoundary fallback={ErrorFallbackComponent}>
    <main className={styles.topContainer}>{props.children}</main>
  </Sentry.ErrorBoundary>
);

export class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toastNotificationVisible: false
    };
  }

  UNSAFE_componentWillReceiveProps(np) {
    if (
      np.messages &&
      this.props.messages &&
      np.messages.length !== this.props.messages.length
    ) {
      this.setState({
        toastNotificationVisible: true,
        toastNotification: np.messages[0].notification
      });

      setTimeout(() => {
        this.handleCloseToastNotification();
      }, 10000);
    }
  }

  handleCloseToastNotification = () => {
    this.setState({
      toastNotificationVisible: false
    });
  };

  handleClickToastNotification = notification => {
    try {
      const parser = document.createElement('a');
      parser.href = notification.data.url;
      this.props.history.push(parser.pathname);
    } catch (e) {}
  };

  render() {
    const {
      closeUploadFileDialog,
      children,
      location,
      match: { params },
      isProgramChanging
    } = this.props;
    const isSelectProgramPage = location.pathname === '/select-program';
    const isEnroll = location.pathname === '/enroll';
    const isHeaderHidden = isSelectProgramPage || isEnroll;

    return (
      <MuiThemeProvider>
        <ThemeProvider theme={SmashcutTheme}>
          <div className={styles.topContainer}>
            {!isHeaderHidden && (
              <Header params={params} onSelectItem={() => {}} />
            )}
            <ToastNotification
              visible={this.state.toastNotificationVisible}
              notification={this.state.toastNotification}
              onClose={this.handleCloseToastNotification}
              onClick={this.handleClickToastNotification}
            />
            <MainContentWithSentryErrorBoundary children={children} />

            <CreateMyFilePopup />
            <CreateProjectPopup />
            <HelpModal />
            <RelatedVideoPlayer />
            <NotificationSettingsPopupContainer />
            <GeneralToastNotification />
            <CookieConsent />
            <DownloadFilesModal
              onCloseUploadFileDialog={closeUploadFileDialog}
            />
            <SmashcutIframe />

            {isProgramChanging && (
              <div className={styles.loadingOverlay}>
                <CenteredProgressbar />
              </div>
            )}
          </div>
        </ThemeProvider>
      </MuiThemeProvider>
    );
  }
}

export const mapStateToProps = state => {
  const messages = getMessages(state);
  const isProgramChanging = selectProgramSelectors.getProgramChangeStatus(
    state
  );
  return {
    messages,
    isProgramChanging
  };
};

const mapDispatchToProps = {
  showMobileWarning: notificationActions.showMobileWarning,
  closeUploadFileDialog: myFileActions.closeUploadFileDialog
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(Main));
