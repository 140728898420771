import FocusLock from 'react-focus-lock';
import keyboardHandlerManager from '../../common/KeyboardHandlerManager';
import PropTypes from 'prop-types';
import radioButtonTheme from './SelectProgram-RadioButtonTheme.scss';
import React, { useEffect, useState } from 'react';
import styles from './SelectProgram.scss';
import { connect } from 'react-redux';
import { HeaderLogo } from 'themes/HeaderLogo';
import { RadioButton } from 'react-toolbox';
import { MainButton, SecondaryButton } from '../common/button';
import {
  actions as selectProgramActions,
  selectors as selectProgramSelectors
} from './selectProgramReducer';
import { HelpChatButton } from '../common/HelpChatButton';
import SelectBoxMobile from 'components/mobile/SelectBoxMobile';
import AfterLoginProgramSelector, {
  formatProgramDates
} from './AfterLoginProgramSelector';
import { withDeviceDetector } from 'components/common/withDeviceDetector';
import { actions as mobileBetaWarningActions } from 'reducers/mobileBetaWarning';
import { Tab, Tabs } from 'react-toolbox';
import TabsTheme from '../dashboard/DashboardTabCrewsTabsTheme.scss';
import SmashcutAvatar from '../common/SmashcutAvatar';
import { selectors as sclSelectors } from 'smashcut-client-lib';
import * as userActions from 'smashcut-client-lib/actions/userActions';
import { useHistory } from 'react-router-dom';

const SelectProgramModal = ({ onClose, children }) => {
  const onKeyUp = e => {
    switch (e.key) {
      case 'Enter':
        onClose();
        break;
      case 'Escape':
        onClose();
        break;
    }
    return false;
  };

  useEffect(() => {
    keyboardHandlerManager.addKeyboardHandler(onKeyUp);
    return () => keyboardHandlerManager.removeKeyboardHandler(onKeyUp);
  }, []);

  return (
    <FocusLock returnFocus={true}>
      <div className={styles.topContainer}>
        <div className={styles.overlay} onClick={onClose} />
        <div className={styles.popup}>{children}</div>
      </div>
    </FocusLock>
  );
};

const SelectProgram = ({
  user,
  onSelect,
  selectProgram,
  onCancel,
  options,
  selectedOptionId,
  showHelpChatButton,
  showCancelButton,
  isMobile,
  afterLogin,
  openMobileBetaWarning,
  logout
}) => {
  const handleSelect = o => {
    selectProgram(isMobile ? o : o.id);
    onSelect();
    isMobile && afterLogin && openMobileBetaWarning();
  };

  const handleCancel = () => onCancel && onCancel();

  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = tabIndex => setTabIndex(tabIndex);

  const history = useHistory();

  const hasArchived = options.some(o => !!o.hasEnded);

  if (isMobile) {
    const programOptions = options.map(option => ({
      index: option.id,
      title: option.label,
      ...option
    }));

    if (afterLogin) {
      return (
        <AfterLoginProgramSelector
          options={programOptions}
          onChange={handleSelect}
        />
      );
    }

    return (
      <SelectBoxMobile
        title="Switch Program"
        options={programOptions}
        onChange={handleSelect}
        selected={selectedOptionId}
        onClose={handleCancel}
      />
    );
  }

  const renderList = isActive => (
    <div className={styles.listContainer}>
      <ul>
        {options
          .filter(p => !!p.hasEnded === !isActive)
          .map(o => (
            <li key={o.id} onClick={() => handleSelect(o)}>
              <div className={styles.optionInfo}>
                <div className={styles.label}>{o.label}</div>
                <div className={styles.dates}>{formatProgramDates(o)}</div>
              </div>
              <RadioButton
                checked={selectedOptionId === o.id}
                theme={radioButtonTheme}
              />
            </li>
          ))}
      </ul>
    </div>
  );

  const renderAfterLoginHeader = () =>
    user && (
      <div className={styles.header}>
        <SmashcutAvatar src={user.avatar} className={styles.avatar} />
        <div className={styles.userInfo}>
          <div>
            Logged in as <strong>{user.fullName}</strong>
          </div>
          <div>Select a program</div>
        </div>
        <MainButton
          label="Log Out"
          icon="fas fa-sign-out-alt"
          onClick={() => logout(history)}
        />
      </div>
    );

  const renderHeader = () => (
    <div className={styles.header}>
      <div className={styles.select}>Select a program</div>
      {showCancelButton && (
        <SecondaryButton label="Cancel" onClick={handleCancel} />
      )}
      {showHelpChatButton && <HelpChatButton />}
    </div>
  );

  return (
    <SelectProgramModal onClose={handleCancel}>
      {afterLogin ? renderAfterLoginHeader() : renderHeader()}
      <div className={styles.list}>
        {hasArchived && (
          <Tabs index={tabIndex} onChange={handleTabChange} theme={TabsTheme}>
            <Tab
              label="Active"
              className={styles.tab}
              activeClassName={styles.tabActive}
            ></Tab>
            <Tab
              label="Archived"
              className={styles.tab}
              activeClassName={styles.tabActive}
            ></Tab>
          </Tabs>
        )}
        {renderList(tabIndex === 0)}
      </div>
    </SelectProgramModal>
  );
};

SelectProgram.propTypes = {
  onCancel: PropTypes.func,
  onSelect: PropTypes.func,
  showHelpChatButton: PropTypes.bool,
  showCancelButton: PropTypes.bool
};

export const mapStateToProps = state => ({
  user: sclSelectors.getCurrentUser(state),
  options: selectProgramSelectors.getOptions(state),
  selectedOptionId: selectProgramSelectors.getSelectedOptionId(state)
});

export const mapDispatchToProps = {
  selectProgram: selectProgramActions.selectProgram,
  openMobileBetaWarning: mobileBetaWarningActions.showWarning,
  logout: userActions.logout
};

export default withDeviceDetector(
  connect(mapStateToProps, mapDispatchToProps)(SelectProgram)
);
