'use strict';

import baseConfig from './base';

const awsApi = 'https://ati.api.smashcut.com';
const awsWss = 'wss://ejvp2zxvpj.execute-api.eu-west-1.amazonaws.com/ati';

let config = {
  apolloUri: `${awsApi}/graphql`,
  appEnv: 'aws-ati',
  baseUrl: 'https://ati.smashcut.com',
  env: 'prod',
  logrocket: 'px1cem/ati-smashcut-web',
  logRocketEnabled: true,
  opentokApiKey: '47015504',
  publicSiteUrl: 'https://www.smashcut.com',
  pushMessagingPublicVapidKey:
    'BH1KmnQ_pPyip2e3o2WKqibenmCC06_naTUMSM0z6AsOgii4loOFFhqpXdF6_vBs3RwFRFgzjGpPCawgSud9xWs',
  sentry:
    'https://c245c4c53a6d4b589a4824c460298651@o82327.ingest.sentry.io/179879',
  sentryEnabled: true,
  smashcutApiUrl: awsApi,
  wssUrl: awsWss
};

let mergedConfig = Object.freeze(Object.assign({}, baseConfig, config));
// console.log('Using config: ', mergedConfig)
export default mergedConfig;
