import { reduce, defaults, union } from 'lodash';

const prefix = 'VIDEO_CONFERENCE';

export const types = {
  CREATE_CONFERENCE_REQUEST: `${prefix}/CREATE_CONFERENCE_REQUEST`,
  CREATE_CONFERENCE_SUCCESS: `${prefix}/CREATE_CONFERENCE_SUCCESS`,
  CREATE_CONFERENCE_FAILURE: `${prefix}/CREATE_CONFERENCE_FAILURE`,
  UPDATE_CONFERENCE_REQUEST: `${prefix}/UPDATE_CONFERENCE_REQUEST`,
  UPDATE_CONFERENCE_SUCCESS: `${prefix}/UPDATE_CONFERENCE_SUCCESS`,
  UPDATE_CONFERENCE_FAILURE: `${prefix}/UPDATE_CONFERENCE_FAILURE`,
  DELETE_CONFERENCE_REQUEST: `${prefix}/DELETE_CONFERENCE_REQUEST`,
  DELETE_CONFERENCE_SUCCESS: `${prefix}/DELETE_CONFERENCE_SUCCESS`,
  DELETE_CONFERENCE_FAILURE: `${prefix}/DELETE_CONFERENCE_FAILURE`,
  INITIALIZE_CONFERENCE_REQUEST: `${prefix}/INITIALIZE_CONFERENCE_REQUEST`,
  INITIALIZE_CONFERENCE_SUCCESS: `${prefix}/INITIALIZE_CONFERENCE_SUCCESS`,
  INITIALIZE_CONFERENCE_FAILURE: `${prefix}/INITIALIZE_CONFERENCE_FAILURE`,
  FINISH_CONFERENCE: `${prefix}/FINISH_CONFERENCE`,
  SESSION_CONNECTED: `${prefix}/SESSION_CONNECTED`,
  SESSION_DISCONNECTED: `${prefix}/SESSION_DISCONNECTED`,
  LOAD_USER_REQUEST: `${prefix}/LOAD_USER_REQUEST`,
  LOAD_USER_SUCCESS: `${prefix}/LOAD_USER_SUCCESS`,
  LOAD_USER_FAILURE: `${prefix}/LOAD_USER_FAILURE`,
  SAVE_CHAT_MESSAGE_REQUEST: `${prefix}/SAVE_CHAT_MESSAGE_REQUEST`,
  SAVE_CHAT_MESSAGE_SUCCESS: `${prefix}/SAVE_CHAT_MESSAGE_SUCCESS`,
  SAVE_CHAT_MESSAGE_FAILURE: `${prefix}/SAVE_CHAT_MESSAGE_FAILURE`,
  LOAD_BROWSABLE_MEDIA_REQUEST: `${prefix}/LOAD_BROWSABLE_MEDIA_REQUEST`,
  LOAD_BROWSABLE_MEDIA_SUCCESS: `${prefix}/LOAD_BROWSABLE_MEDIA_SUCCESS`,
  LOAD_BROWSABLE_MEDIA_FAILURE: `${prefix}/LOAD_BROWSABLE_MEDIA_FAILURE`,
  SELECT_MEDIA: `${prefix}/SELECT_MEDIA`,
  UPDATE_SETTINGS: `${prefix}/UPDATE_SETTINGS`,
  SHOW_NOTIFICATION: `${prefix}/SHOW_NOTIFICATION`,
  SHOW_BECAME_CO_HOST_WARNING: `${prefix}/SHOW_BECAME_CO_HOST_WARNING`,
  HIDE_BECAME_CO_HOST_WARNING: `${prefix}/HIDE_BECAME_CO_HOST_WARNING`,
  SHOW_COMMENTS: `${prefix}/SHOW_COMMENTS`,
  HIDE_COMMENTS: `${prefix}/HIDE_COMMENTS`,
  SHOW_LEFT_PANEL: `${prefix}/SHOW_LEFT_PANEL`,
  HIDE_LEFT_PANEL: `${prefix}/HIDE_LEFT_PANEL`,
  ADD_OR_REMOVE_CO_HOST: `${prefix}/ADD_OR_REMOVE_CO_HOST`,
  RAISE_HAND: `${prefix}/RAISE_HAND`,
  LOWER_HAND: `${prefix}/LOWER_HAND`,
  HOST_JOINED: `${prefix}/HOST_JOINED`,
  HOST_LEFT: `${prefix}/HOST_LEFT`,
  HOST_STARTED_BROWSING: `${prefix}/HOST_STARTED_BROWSING`,
  HOST_STOPPED_BROWSING: `${prefix}/HOST_STOPPED_BROWSING`,
  SHOW_MEDIA_BROWSER: `${prefix}/SHOW_MEDIA_BROWSER`,
  HIDE_MEDIA_BROWSER: `${prefix}/HIDE_MEDIA_BROWSER`,
  GOTO_PDF_PAGE: `${prefix}/GOTO_PDF_PAGE`,
  SET_PDF_SCALE: `${prefix}/SET_PDF_SCALE`,
  SELECT_IMAGE: `${prefix}/SELECT_IMAGE`,
  CHANGE_HAND_ICON: `${prefix}/CHANGE_HAND_ICON`,
  CLEAR_RAISED_HANDS: `${prefix}/CLEAR_RAISED_HANDS`
};

export const initialState = {
  users: {},
  mediaPerUser: {},
  isLoadingBrowsableMedia: false,
  pdfPage: 1,
  pdfScale: 1,
  leftPanelVisible: true
};

export const videoConferenceReducer = (
  state = initialState,
  action,
  fullState
) => {
  let nextState = state;
  switch (action.type) {
    case types.INITIALIZE_CONFERENCE_REQUEST:
      nextState = {
        ...state,
        selectedMedia: null
      };
      break;

    case types.INITIALIZE_CONFERENCE_SUCCESS:
      const { host } = action.conference;
      let users = reduce(
        action.conference.participants,
        (acc, val) => {
          acc[val.id] = val;
          return acc;
        },
        {}
      );
      if (host) {
        users[host.id] = host;
      }
      nextState = {
        ...state,
        conference: action.conference,
        users,
        presentingUserId: action.conference.presentingUserId,
        leftPanelVisible: true,
        settings: defaults(action.conference.settings, {
          webcamsDisabled: false,
          micsDisabled: false,
          participantsCanPresent: false,
          participantsCanShareScreen: false,
          participantsCanMuteEveryone: false,
          participantsCanMute: false
        })
      };
      break;

    case types.FINISH_CONFERENCE:
      console.log('Finishing Conference');
      nextState = {};
      break;

    // case officeHoursTypes.LOAD_OFFICE_HOURS_COMPLETE:
    //   // We create a "hostless" conference for the 1 to 1 meeting
    //   nextState = {
    //     ...state,
    //     conference: {
    //       id: action.data.id
    //     }
    //   };
    //   break;
    //
    case types.INITIALIZE_CONFERENCE_FAILURE:
      nextState = {
        ...state,
        error: action.error
      };
      break;

    case types.LOAD_USER_SUCCESS:
      nextState = {
        ...state,
        users: {
          ...state.users,
          [action.user.id]: action.user
        }
      };
      break;

    case types.LOAD_BROWSABLE_MEDIA_REQUEST:
      nextState = {
        ...state,
        isLoadingBrowsableMedia: true,
        mediaPerUser: {}
      };
      break;

    case types.LOAD_BROWSABLE_MEDIA_FAILURE:
      nextState = {
        ...state,
        isLoadingBrowsableMedia: false
      };
      break;

    case types.LOAD_BROWSABLE_MEDIA_SUCCESS:
      let media = action.browsableMedia.map(file => ({
        ...file,
        id: file.id.split('#')[0],
        children: null,
        isFolder: !!file.folder
      }));

      let mediaPerUser = { [action.userId]: media };
      nextState = {
        ...state,
        isLoadingBrowsableMedia: false,
        mediaPerUser
      };
      break;

    case types.HOST_JOINED:
      nextState = {
        ...state,
        hostHasJoined: true
      };
      break;

    case types.HOST_LEFT:
      nextState = {
        ...state,
        hostHasJoined: false,
        hostIsBrowsing: false,
        conference: {
          ...state.conference,
          isPlaying: false
        }
      };
      break;

    case types.SELECT_MEDIA:
      const selectedMedia = action.media && action.media.id && action.media;
      nextState = {
        ...state,
        hostIsBrowsing: false,
        selectedMedia,
        presentingUserId:
          action.media && action.media.id
            ? action.initiatorUserId || state.presentingUserId
            : null
      };

      if (selectedMedia && selectedMedia.fileType === 'PDF') {
        nextState.pdfPage = state.conference.currentPosition || 1;
      }

      if (!(action.media && action.media.id)) {
        nextState.commentsVisible = false;
      }

      if (!action.isInitial) {
        nextState.conference.currentPosition = null;
        nextState.conference.isPlaying = false;
        nextState.conference.selectedImageIndex = 0;
        nextState.pdfPage = 1;
      }

      break;

    case types.SELECT_IMAGE:
      nextState = {
        ...state,
        conference: {
          ...state.conference,
          selectedImageIndex: action.imageIndex
        }
      };
      break;

    case types.SHOW_NOTIFICATION:
      nextState = { ...state, notification: action.notification };
      break;

    case types.HOST_STARTED_BROWSING:
      nextState = { ...state, hostIsBrowsing: true };
      break;

    case types.HOST_STOPPED_BROWSING:
      nextState = { ...state, hostIsBrowsing: false };
      break;

    case types.ADD_OR_REMOVE_CO_HOST:
      {
        let coHosts = state.conference.coHostIds || [];
        if (action.isAdding) {
          // TODO: post MVP:
          // coHosts = union(coHosts, [action.userId]);
          state.conference.coHostIds = [action.userId];

          // lower the hand of the promoted student
          state.conference.raisedHands = (
            state.conference.raisedHands || []
          ).filter(id => id !== action.userId);
        } else {
          state.conference.coHostIds = coHosts.filter(
            id => id !== action.userId
          );
        }
        console.log('new chids', coHosts);
        nextState = {
          ...state,
          conference: { ...state.conference }
        };
      }
      break;

    case types.RAISE_HAND:
    case types.LOWER_HAND:
      {
        const raisedHands = state.conference.raisedHands || [];
        if (action.type === types.RAISE_HAND) {
          state.conference.raisedHands = union(raisedHands, [action.userId]);
        } else {
          state.conference.raisedHands = raisedHands.filter(
            id => id !== action.userId
          );
        }
        nextState = {
          ...state,
          conference: { ...state.conference }
        };
      }
      break;

    case types.CLEAR_RAISED_HANDS:
      nextState = {
        ...state,
        conference: {
          ...state.conference,
          raisedHands: [],
          lastRaisedHands: state.conference.raisedHands
        }
      };
      break;

    case types.GOTO_PDF_PAGE:
      nextState = {
        ...state,
        pdfPage: action.page
      };
      break;

    case types.SET_PDF_SCALE:
      nextState = {
        ...state,
        pdfScale: action.scale
      };
      break;

    case types.UPDATE_SETTINGS:
      nextState = {
        ...state,
        settings: defaults(action.settings, state.settings)
      };
      break;

    case types.SHOW_COMMENTS:
      nextState = {
        ...state,
        commentsVisible: true
      };
      break;

    case types.HIDE_COMMENTS:
      nextState = {
        ...state,
        commentsVisible: false
      };
      break;

    case types.SHOW_LEFT_PANEL:
      nextState = {
        ...state,
        leftPanelVisible: true
      };
      break;

    case types.HIDE_LEFT_PANEL:
      nextState = {
        ...state,
        leftPanelVisible: false
      };
      break;

    case types.SHOW_MEDIA_BROWSER:
      nextState = {
        ...state,
        mediaBrowserVisible: true
      };
      break;

    case types.HIDE_MEDIA_BROWSER:
      nextState = {
        ...state,
        mediaBrowserVisible: false
      };
      break;

    case types.SHOW_BECAME_CO_HOST_WARNING:
      nextState = { ...state, becameCoHostWarningVisible: true };
      break;

    case types.HIDE_BECAME_CO_HOST_WARNING:
      nextState = { ...state, becameCoHostWarningVisible: false };
      break;

    case types.CHANGE_HAND_ICON:
      const { handIconIndex, userId } = action.userId;
      nextState = {
        ...state,
        users: {
          ...state.users,
          [userId]: {
            ...state.users[userId],
            settings: { ...state.users[userId].settings, handIconIndex }
          }
        }
      };
      break;
  }

  return nextState;
};

const loadUser = userId => ({
  type: types.LOAD_USER_REQUEST,
  userId
});

const loadBrowsableMedia = userId => ({
  type: types.LOAD_BROWSABLE_MEDIA_REQUEST,
  userId
});

const selectMedia = ({ media, initiatorUserId, isInitial }) => ({
  type: types.SELECT_MEDIA,
  media,
  initiatorUserId,
  isInitial
});

const initializeConference = (
  conferenceId,
  userId,
  opentokSessionId,
  opentokToken
) => ({
  type: types.INITIALIZE_CONFERENCE_REQUEST,
  conferenceId,
  userId,
  opentokSessionId,
  opentokToken
});

const updateConference = (conferenceId, conference) => ({
  type: types.UPDATE_CONFERENCE_REQUEST,
  conferenceId,
  conference
});

const deleteConference = conferenceId => ({
  type: types.DELETE_CONFERENCE_REQUEST,
  conferenceId
});

const createConference = ({
  hostId,
  name,
  startDate,
  participants,
  conferenceType,
  duration,
  recurringDays,
  recurringEndDate
}) => ({
  type: types.CREATE_CONFERENCE_REQUEST,
  hostId,
  name,
  startDate,
  participants,
  conferenceType,
  duration,
  recurringDays,
  recurringEndDate
});

const sessionConnected = () => ({
  type: types.SESSION_CONNECTED
});

const sessionDisconnected = () => ({
  type: types.SESSION_DISCONNECTED
});

const hostJoined = () => ({
  type: types.HOST_JOINED
});

const hostLeft = () => ({
  type: types.HOST_LEFT
});

const hostStartedBrowsing = () => ({
  type: types.HOST_STARTED_BROWSING
});

const hostStoppedBrowsing = () => ({
  type: types.HOST_STOPPED_BROWSING
});

const saveChatMessage = (senderId, message, timestamp) => ({
  type: types.SAVE_CHAT_MESSAGE_REQUEST,
  senderId,
  message,
  timestamp
});

const addOrRemoveCoHost = (userId, isAdding) => ({
  type: types.ADD_OR_REMOVE_CO_HOST,
  userId,
  isAdding
});

const raiseHand = userId => ({
  type: types.RAISE_HAND,
  userId
});

const lowerHand = userId => ({
  type: types.LOWER_HAND,
  userId
});

const showNotification = notification => ({
  type: types.SHOW_NOTIFICATION,
  notification
});

const showComments = () => ({
  type: types.SHOW_COMMENTS
});

const hideComments = () => ({
  type: types.HIDE_COMMENTS
});

const showLeftPanel = () => ({
  type: types.SHOW_LEFT_PANEL
});

const hideLeftPanel = () => ({
  type: types.HIDE_LEFT_PANEL
});

const showMediaBrowser = () => ({
  type: types.SHOW_MEDIA_BROWSER
});

const hideMediaBrowser = () => ({
  type: types.HIDE_MEDIA_BROWSER
});

const finishConference = () => ({
  type: types.FINISH_CONFERENCE
});

const showBecameCoHostWarning = () => ({
  type: types.SHOW_BECAME_CO_HOST_WARNING
});

const hideBecameCoHostWarning = () => ({
  type: types.HIDE_BECAME_CO_HOST_WARNING
});

const gotoPdfPage = page => ({
  type: types.GOTO_PDF_PAGE,
  page
});

const setPdfScale = scale => ({
  type: types.SET_PDF_SCALE,
  scale
});

const selectImage = imageIndex => ({
  type: types.SELECT_IMAGE,
  imageIndex
});

const updateSettings = (conferenceId, settings, dontPersist) => ({
  type: types.UPDATE_SETTINGS,
  conferenceId,
  settings,
  dontPersist
});

const changeHandIcon = (userId, handIconIndex) => ({
  type: types.CHANGE_HAND_ICON,
  userId,
  handIconIndex
});

const clearRaisedHands = () => ({
  type: types.CLEAR_RAISED_HANDS
});

export const actions = {
  createConference,
  updateConference,
  deleteConference,
  initializeConference,
  loadUser,
  loadBrowsableMedia,
  hostJoined,
  hostLeft,
  hostStartedBrowsing,
  hostStoppedBrowsing,
  selectMedia,
  sessionConnected,
  sessionDisconnected,
  saveChatMessage,
  addOrRemoveCoHost,
  raiseHand,
  lowerHand,
  showNotification,
  updateSettings,
  showComments,
  hideComments,
  showLeftPanel,
  hideLeftPanel,
  showMediaBrowser,
  hideMediaBrowser,
  finishConference,
  showBecameCoHostWarning,
  hideBecameCoHostWarning,
  gotoPdfPage,
  setPdfScale,
  selectImage,
  changeHandIcon,
  clearRaisedHands
};

export const selectors = {
  getConference: state => state.videoConference.conference,
  getPdfPage: state => state.videoConference.pdfPage,
  getPdfScale: state => state.videoConference.pdfScale,
  getUser: (state, userId) => (state.videoConference.users || {})[userId],
  getUsers: state => state.videoConference.users,
  getHostHasJoined: state => state.videoConference.hostHasJoined,
  getIsLoadingBrowsableMedia: state =>
    state.videoConference.isLoadingBrowsableMedia,
  getBrowsableMedia: state => state.videoConference.mediaPerUser,
  getBrowsableMediaForUser: (state, userId) =>
    state.videoConference.mediaPerUser[userId],
  getSelectedMedia: state => state.videoConference.selectedMedia,
  getPresentingUserId: state => state.videoConference.presentingUserId,
  getError: state => state.videoConference.error,
  getNotification: state => state.videoConference.notification,
  getShouldRefetch: state => state.videoConference.shouldRefetch,
  getHostIsBrowsing: state => state.videoConference.hostIsBrowsing,
  getCoHosts: state => state.videoConference.coHosts,
  getSettings: state => state.videoConference.settings,
  getCommentsVisible: state => state.videoConference.commentsVisible,
  getLeftPanelVisible: state => state.videoConference.leftPanelVisible,
  getMediaBrowserVisible: state => state.videoConference.mediaBrowserVisible,
  getBecameCoHostWarningVisible: state =>
    state.videoConference.becameCoHostWarningVisible,
  getRaisedHands: state => state.videoConference.conference.raisedHands,
  getSelectedImageIndex: state =>
    state.videoConference.conference.selectedImageIndex || 0
};
