import { actions as lessonActions } from 'reducers/lesson';
import { selectors as lessonSelectors } from 'reducers/lesson';

const PREFIX = 'DASHBOARD/USER_PROJECTS';

export const types = {
  PROJECT_CREATOR_OPEN: `${PREFIX}/PROJECT_CREATOR_OPEN`,
  PROJECT_CREATOR_CLOSE: `${PREFIX}/PROJECT_CREATOR_CLOSE`
};

const initialState = {
  projectCreator: {
    open: false
  }
};

export const userProjectsReducer = (state = initialState, action) => {
  let nextState = state;

  switch (action.type) {
    case types.PROJECT_CREATOR_OPEN:
      nextState = { ...state };
      nextState.projectCreator = { ...state.projectCreator };
      nextState.projectCreator.open = !state.projectCreator.open;
      break;

    case types.PROJECT_CREATOR_CLOSE:
      nextState = { ...state };
      nextState.projectCreator = { open: false };
      break;
  }

  return nextState;
};

function gotoProject(project, push) {
  if (lessonSelectors.isLessonProject(project)) {
    return lessonActions.selectProject(project);
  }
  return (dispatch, getState) => {
    return push('/project/' + project.id);
  };
}

function openProjectCreator() {
  return { type: types.PROJECT_CREATOR_OPEN };
}

function closeProjectCreator() {
  return { type: types.PROJECT_CREATOR_CLOSE };
}

export const actions = {
  gotoProject,
  openProjectCreator,
  closeProjectCreator
};

function getProjectCreator(fullState) {
  return fullState.dashboard.userProjects.projectCreator;
}

export const selectors = {
  getProjectCreator
};
