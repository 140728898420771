import React from 'react';
import { BrowseButton as ReactToolboxBrowseButton } from 'react-toolbox/lib/button';
import { themr } from 'react-css-themr';
import { BUTTON } from 'react-toolbox/lib/identifiers';

class SmashcutBrowseButton extends React.Component {
  constructor(props) {
    super(props);
    this.selectedFile = {};
    this.timeStamp = 0;
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const files = [...e.target.files];

      console.log(
        'handleChange',
        e.nativeEvent,
        lastUploadIsOldEnough(this.timeStamp),
        isDifferentFile(file, this.selectedFile),
        files,
        e.target.multiple
      );

      // reset the chosen file
      // otherwise you cannot select the same file again
      e.target.value = '';

      if (e.target.multiple) {
        this.props.onChange(files);
        return;
      }

      // for some reason we are getting the change event
      // twice for a single selection
      if (
        lastUploadIsOldEnough(this.timeStamp) ||
        isDifferentFile(file, this.selectedFile)
      ) {
        this.selectedFile = file;
        this.timeStamp = Date.now();
        this.props.onChange(file);
      }
    }

    // -------------------------------------

    function lastUploadIsOldEnough(timeStamp) {
      return Date.now() - timeStamp > 5000;
    }

    function isDifferentFile(a, b) {
      console.log(
        'isDifferentFile',
        a.name !== b.name || a.lastModified !== b.lastModified,
        a,
        b
      );
      return a.name !== b.name || a.lastModified !== b.lastModified;
    }
  }

  render() {
    return (
      <ReactToolboxBrowseButton {...this.props} onChange={this.handleChange}>
        <div className={this.props.theme.underline} />
      </ReactToolboxBrowseButton>
    );
  }
}
export default themr(BUTTON)(SmashcutBrowseButton);
