import { actionType as types } from '../constants';
import { getCurrentUser } from '../selectors';
import { LOAD_LOGGED_IN_USER, LOGOUT } from './authReducer';
import { types as studentTypes } from 'reducers/dashboard/studentNotes';

const initialState = {
  comments: {},
  counts: {},
  discussions: {},
  lastReads: {},
  messages: {},
  programInstances: {},
  programs: {},
  projects: {},
  releases: {},
  replies: {},
  screenplays: {},
  userPrograms: {},
  users: {}
};

const entitiesReducer = (state = initialState, action, fullState) => {
  let nextState = state;

  switch (action.type) {
    case types.COMMENT_SECTION_START_LOADING:
      nextState = { ...state, comments: {}, replies: {} };
      break;

    case types.LOAD_ONCE_FROM_DATABASE_COMPLETE:
      if (action.dbPath.indexOf('programInstances') >= 0) {
        // console.log('loaded', action)
        nextState = { ...state, programInstances: action.data };
      }
      break;

    case LOAD_LOGGED_IN_USER:
      if (action.payload && action.payload.user) {
        nextState = { ...state, users: { ...state.users } };
        nextState.users[action.payload.user.id] = action.payload.user;
      }
      break;

    case studentTypes.NOTE_UPDATE_SUCCESS:
      if (action.payload && action.payload.userId) {
        const enrolleeId = action.payload.enrolleeId;
        const userId = action.payload.userId;
        const note = action.payload.note;
        nextState.userPrograms[enrolleeId] = {
          ...state.userPrograms[enrolleeId],
          notes: {
            ...state.userPrograms[enrolleeId].notes,
            [userId]: note
          }
        };
      }
      break;

    case types.INSERT_USER_DATA:
      if (action.payload && action.payload.userData) {
        const {
          userPrograms,
          programInstances,
          programs,
          projects,
          screenplays,
          imageProjects,
          nonSupportedFileProjects,
          object3DProjects,
          users
        } = action.payload.userData;
        nextState = {
          ...state,
          userPrograms,
          programInstances,
          programs,
          projects,
          screenplays,
          imageProjects,
          nonSupportedFileProjects,
          object3DProjects,
          users: {
            ...state.users,
            ...users
          }
        };
      }
      break;

    case types.INSERT_ENTITY_DISCUSSIONS:
      if (action.payload) {
        nextState = {
          ...state,
          discussions: action.payload
        };
      }
      break;

    case types.INSERT_ENTITY_DISCUSSIONS_MESSAGES:
      if (action.payload) {
        nextState = {
          ...state,
          messages: action.payload
        };
      }
      break;

    case types.INSERT_ENTITY_DISCUSSIONS_LAST_READS:
      if (action.payload) {
        nextState = {
          ...state,
          lastReads: action.payload
        };
      }
      break;

    case LOGOUT:
      nextState = { ...initialState };
      break;

    case types.UPDATE_ENTITY_USER:
      if (action.id && action.payload) {
        nextState = { ...state };
        nextState.users = { ...state.users };
        nextState.users[action.id] = { ...state.users[action.id] };
        Object.keys(action.payload).forEach(key => {
          nextState.users[action.id][key] = action.payload[key];
        });
      }
      break;

    case types.updateUserSettings:
      // TODO why is that necessary? doesn't it get updated anyways, shortly after we wrote to the db?
      let updatedUser = { ...getCurrentUser(fullState) };
      updatedUser.settings = { ...updatedUser.settings, ...action.settings };
      nextState = { ...state, users: { ...state.users } };
      nextState.users[updatedUser.id] = updatedUser;
      break;

    case types.updateUser:
      let user = { ...getCurrentUser(fullState) };
      user = { ...user, ...action.changes };
      nextState = { ...state, users: { ...state.users, [user.id]: user } };
      break;

    case types.REMOVE_ENTITY_DISCUSSIONS_MESSAGE:
    case types.REMOVE_ENTITY_COMMENTS:
    case types.REMOVE_ENTITY_DISCUSSIONS:
    case types.REMOVE_ENTITY_IMAGEPROJECTS:
    case types.REMOVE_ENTITY_INTRO_VIDEOS:
    case types.REMOVE_ENTITY_LAST_READS:
    case types.REMOVE_ENTITY_MESSAGES:
    case types.REMOVE_ENTITY_PROGRAM_INSTANCES:
    case types.REMOVE_ENTITY_PROGRAMS:
    case types.REMOVE_ENTITY_PROJECTS:
    case types.REMOVE_ENTITY_RELEASES:
    case types.REMOVE_ENTITY_REPLIES:
    case types.REMOVE_ENTITY_SCREENPLAYS:
    case types.REMOVE_ENTITY_USER_PROGRAMS:
    case types.REMOVE_ENTITY_USERS:
      if (state[action.entityType] && state[action.entityType][action.id]) {
        nextState = { ...state };
        nextState[action.entityType] = { ...state[action.entityType] };
        delete nextState[action.entityType][action.id];
      }
      break;

    case types.INSERT_ENTITY_NEW_DISCUSSIONS_MESSAGE:
    case types.INSERT_ENTITY_COMMENTS:
    case types.INSERT_ENTITY_DISCUSSION:
    case types.INSERT_ENTITY_IMAGEPROJECTS:
    case types.INSERT_ENTITY_INTRO_VIDEOS:
    case types.INSERT_ENTITY_LAST_READ:
    case types.INSERT_ENTITY_PROGRAM_INSTANCES:
    case types.INSERT_ENTITY_PROGRAMS:
    case types.INSERT_ENTITY_PROJECTS:
    case types.INSERT_ENTITY_RELEASES:
    case types.INSERT_ENTITY_REPLIES:
    case types.INSERT_ENTITY_SCREENPLAYS:
    case types.INSERT_ENTITY_USER_PROGRAMS:
    case types.INSERT_ENTITY_USERS:
      nextState = { ...state };
      nextState[action.entityType] = { ...state[action.entityType] };
      nextState[action.entityType][action.id] = action.entity;
      break;

    case types.UPDATE_ENTITY_COMMENTS:
    case types.UPDATE_ENTITY_DISCUSSIONS:
    case types.UPDATE_ENTITY_INTRO_VIDEOS:
    case types.UPDATE_ENTITY_LAST_READ:
    case types.UPDATE_ENTITY_MESSAGES:
    case types.UPDATE_ENTITY_PROGRAM_INSTANCES:
    case types.UPDATE_ENTITY_PROGRAMS:
    case types.UPDATE_ENTITY_PROJECTS:
    case types.UPDATE_ENTITY_RELEASES:
    case types.UPDATE_ENTITY_REPLIES:
    case types.UPDATE_ENTITY_SCREENPLAYS:
    case types.UPDATE_ENTITY_USER_PROGRAMS:
    case types.UPDATE_ENTITY_USERS:
    case types.UPDATE_ENTITY_DISCUSSIONS_MESSAGE:
      nextState = { ...state };
      nextState[action.entityType] = { ...state[action.entityType] };
      nextState[action.entityType][action.id] = {
        ...state[action.entityType][action.id],
        ...action.entity
      };
      break;

    case types.INSERT_ENTITY_COUNTS:
    case types.UPDATE_ENTITY_COUNTS:
      nextState = { ...state };
      nextState[action.entityType] = { ...state[action.entityType] };
      nextState[action.entityType][action.id] = Object.keys(
        action.entity || {}
      ).length;
      break;

    case types.REMOVE_ENTITY_COUNTS:
      nextState = { ...state };
      nextState[action.entityType] = { ...state[action.entityType] };
      nextState[action.entityType][action.id] = 0;
      break;
  }

  return nextState;
};

export default entitiesReducer;
