import moment from 'moment';

function getFirstWeekStartDate(programStartDate, weekStartDay) {
  weekStartDay = isNaN(weekStartDay) ? 1 : weekStartDay;

  const startDay = programStartDate.day();
  if (startDay === weekStartDay) {
    return programStartDate.toDate();
  }
  if (startDay > weekStartDay) {
    const d = new Date(programStartDate);
    d.setDate(d.getDate() - startDay + weekStartDay);
    return d;
  }
  // startDay < weekStartDay
  const d = new Date(programStartDate);
  d.setDate(d.getDate() - 7 - startDay + weekStartDay);
  return d;
}

export const getWeekStartDateMoment = (lessonId, courseId, program, pace) => {
  const programStartDate = moment(program.startDate);

  if (!programStartDate.isValid()) {
    console.error(program);
    throw new Error('Error parsing startDate from program');
  }

  const week = pace.weeks.find(week =>
    findLessonInWeek(week, courseId, lessonId)
  );
  if (!week) {
    console.error(program);
    throw new Error('Error finding lesson in selected pace');
  }

  const weekIndex = pace.weeks.indexOf(week);

  if (weekIndex === 0) {
    return moment(programStartDate);
  }
  const firstWeekStartDate = getFirstWeekStartDate(
    programStartDate,
    program.weekStartDay
  );
  return moment(firstWeekStartDate).add(weekIndex, 'weeks');
};

function findLessonInWeek(week, courseId, lessonId) {
  return (
    week &&
    week.lessons &&
    week.lessons.find(l => l.lesson === lessonId && l.course === courseId)
  );
}

export const getWeekStartDate = (lessonId, courseId, program, pace) => {
  return getWeekStartDateMoment(lessonId, courseId, program, pace).format(
    'MMM DD'
  );
};

export const calcDueDate = (weekStartDate, dueDays) => {
  return moment(weekStartDate)
    .add(dueDays, 'days')
    .add(12, 'hours');
};

export const getDueDateMoment = (
  lessonId,
  courseId,
  program,
  pace,
  dueDays
) => {
  return calcDueDate(
    getWeekStartDateMoment(lessonId, courseId, program, pace),
    dueDays
  );
};

export const getDueDate = (lessonId, courseId, program, pace, dueDays) => {
  return getDueDateMoment(lessonId, courseId, program, pace, dueDays).format(
    'MMM DD'
  );
};
