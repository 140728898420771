import * as userProgramHelper from 'smashcut-client-lib/utils/userProgramHelper';
import { gql } from '@apollo/client';
import moment from 'moment';
import { ACTION_ID } from '../lesson';
import { actions as lessonActions } from 'reducers/lesson';
import { actions as relatedVideoActions } from 'reducers/relatedVideoPlayer';
import { actionType as sclTypes } from 'smashcut-client-lib';
import { getCurrentUserProgram } from 'smashcut-client-lib/selectors';
import { logAndSendError } from 'utils/sentryHelper';
import { types as lessonProjectTypes } from 'reducers/lessonProjectTypes';
import { types as selectProgramTypes } from 'components/selectProgram/selectProgramReducer';
import { LOGOUT } from '../../smashcut-client-lib/reducers/authReducer';
import { showIframe as showIframeAction } from '../../components/common/SmashcutIframe';

const PREFIX = 'DASHBOARD_SYLLABUS';

export const types = {
  HELP_MODAL_TOGGLE: `${PREFIX}/HELP_MODAL_TOGGLE`,
  LOAD_SYLLABUS_FAILURE: `${PREFIX}/LOAD_SYLLABUS_FAILURE`,
  LOAD_SYLLABUS_START: `${PREFIX}/LOAD_SYLLABUS_START`,
  LOAD_SYLLABUS_SUCCESS: `${PREFIX}/LOAD_SYLLABUS_SUCCESS`,
  MARK_AS_DONE_SUCCESS: `${PREFIX}/MARK_AS_DONE_SUCCESS`,
  OPEN_THIS_WEEK: `${PREFIX}/OPEN_THIS_WEEK`,
  PREVIEW_SYLLABUS: `${PREFIX}/PREVIEW_SYLLABUS`,
  REMOVE_MARK_AS_DONE_SUCCESS: `${PREFIX}/REMOVE_MARK_AS_DONE_SUCCESS`,
  SUBMIT_PROJECT_MODAL_TOGGLE: `${PREFIX}/SUBMIT_PROJECT_MODAL_TOGGLE`
};

const initialState = {
  openedWeek: 0,
  loading: false,
  helpModal: {
    opened: false
  }
};

export const syllabusReducer = (state = initialState, action) => {
  let nextState = state;

  switch (action.type) {
    case types.LOAD_SYLLABUS_START:
      nextState = {
        ...state,
        loading: true
      };
      break;

    case types.PREVIEW_SYLLABUS:
      nextState = {
        isPreview: true,
        contentId: action.contentId,
        programId: action.programId
      };
      break;

    case types.LOAD_SYLLABUS_SUCCESS:
      nextState = {
        ...state,
        data: action.data,
        resolveAsset: action.resolveAsset,
        loading: false
      };
      break;

    case types.LOAD_SYLLABUS_FAILURE:
      nextState = {
        ...state,
        error: action.error,
        loading: false
      };
      break;

    case types.OPEN_THIS_WEEK:
      nextState = { ...state, openedWeek: action.weekIndex };
      break;

    case types.HELP_MODAL_TOGGLE:
      nextState = {
        ...state,
        helpModal: {
          text: action.text,
          opened: !state.helpModal.opened
        }
      };
      break;

    case types.SUBMIT_PROJECT_MODAL_TOGGLE:
      nextState = {
        ...state,
        submitProjectOpened: state.submitProjectOpened
          ? undefined
          : {
              item: action.item,
              type: action.itemType
            }
      };
      break;

    case lessonProjectTypes.MARK_AS_DONE_SUCCESS:
    case types.MARK_AS_DONE_SUCCESS:
      nextState = {
        ...state,
        submitProjectOpened: undefined
      };
      break;

    case LOGOUT:
    case selectProgramTypes.SELECT_USER_PROGRAM:
      nextState = {
        ...state,
        openedWeek: 0
      };
      break;
  }

  return nextState;
};

// ------------------------------------------------

// I've tried graphql "fragments" but then you will need
// interfaces and a build step. To avoid this i'm using
// string interpolation here
const GOTO_LESSON_PAYLOAD_FRAGMENT = `
      gotoLessonPayload {
        programId
        courseId
        lessonId
        mainAreaType
        mainAreaId
        tabName
        itemType
        itemId
      }
    `;

const RESOLVED_VIDEO_FRAGMENT = `
      resolvedVideo {
        id
        baseUrl
        dashUrl
        description
        duration
        hlsUrl
        spriteUrl
        thumbnailUrl
        title
        type
        url
        videoUrl
        vttUrl
      }
    `;

const ANNOTATION_FRAGMENT = `
  id
  relatedVideos {
    id
    title
    videoUrl
    ${RESOLVED_VIDEO_FRAGMENT}
  }
  teaser
  text
  time
  title
`;

const SUBTITLES_FRAGMENT = `
  subTitles {
    id
    label
    lang
    url
  }
`;

const SYLLABUS_QUERY = gql`
  query syllabus($enrolleeId: String!) {
    syllabus(enrolleeId: $enrolleeId) {
      startDate
      assets {
        generation
        originalFileName
      }
      weeks {
        id
        availableFrom
        canNavigate
        endDate
        index
        isModuleBased
        progress
        shortTitle
        startDate
        title
        lessons {
          id
          description
          disciplines
          hideLessonMediaFromSyllabus
          thumbnailPosition
          thumbnailUrl
          title
          ${GOTO_LESSON_PAYLOAD_FRAGMENT}
          videos {
            id
            assignmentTypeLabel
            dueDate
            dueDays
            duration
            pdfUrl
            status
            thumbnailUrl
            title
            ${GOTO_LESSON_PAYLOAD_FRAGMENT}
          }
          assignments {
            id
            annotations {
              ${ANNOTATION_FRAGMENT}
            }
            assignmentType
            assignmentTypeLabel
            description
            disciplines
            dueDate
            dueDays
            externalUrl
            help
            instructions
            instructionsPdfUrl
            isCrewAssignment
            hideSubmitButton
            responseType
            status
            ${SUBTITLES_FRAGMENT}
            thumbnailUrl
            transscriptUrl
            title
            videoUrl
            videoDuration
            ${RESOLVED_VIDEO_FRAGMENT}
            ${GOTO_LESSON_PAYLOAD_FRAGMENT}
            crewProject {
              id
              crewId
              lessonRecord {
                id
                status
              }
            }
          }
        }
      }
    }
  }
`;

// Note: When you are missing data from an assignment, check getItem below
export const loadSyllabus = maybeId => {
  return (dispatch, getState, { api, apolloClient }) => {
    const state = getState();
    const userProgram = getCurrentUserProgram(state);
    const id = maybeId || (userProgram && userProgram.id);
    if (id) {
      return loadSyllabusForUserProgram(id);
    }
    // ---------------------------------
    // only functions below
    // ---------------------------------

    function loadSyllabusForUserProgram(userProgramId) {
      dispatch(loadSyllabusStart());

      return apolloClient
        .query({
          query: SYLLABUS_QUERY,
          variables: { enrolleeId: userProgramId },
          fetchPolicy: 'network-only' // disable cache to force updates
          // TODO when we change the ids of the syllabus parts to be unique, we can remove the network-only fetchPolicy
        })
        .then(result => {
          const syllabus = result.data.syllabus;
          const assets = result.data.syllabus.assets;
          const resolveAsset = userProgramHelper.makeResolveAssetFnFromAssets(
            assets,
            api
          );
          // console.log('syllabus', syllabus);
          dispatch(loadSyllabusSuccess(syllabus, resolveAsset, !!maybeId));
        })
        .catch(e => {
          dispatch(
            loadSyllabusFailure(`error loading syllabus for ${userProgramId}`)
          );
          logAndSendError(e, `error loading syllabus for ${userProgramId}`);
        });
    }
  };
};

export const loadSyllabusFailure = error => ({
  type: types.LOAD_SYLLABUS_FAILURE,
  error
});

export const loadSyllabusStart = () => ({
  type: types.LOAD_SYLLABUS_START
});

export const loadSyllabusSuccess = (data, resolveAsset, idProvided) => ({
  type: types.LOAD_SYLLABUS_SUCCESS,
  data,
  resolveAsset,
  idProvided
});

function findWeekForDate(syllabus, date) {
  let d = date.toISOString();

  for (let id in syllabus.weeks) {
    let week = syllabus.weeks[id];

    if (week) {
      if (dayStart(week.startDate) <= d && d <= dayEnd(week.endDate)) {
        return week;
      }
    }
  }

  console.warn('findWeekForDate: no week found');
  return null;

  // ---------------------

  function dayStart(d) {
    return moment(d)
      .startOf('day')
      .toISOString();
  }

  function dayEnd(d) {
    return moment(d)
      .endOf('day')
      .toISOString();
  }
}

export const markAsDone = (item, type) => {
  return (dispatch, getState, { lessonApi }) => {
    return lessonApi
      .addConfirm(item, type)
      .then(() => dispatch({ type: types.MARK_AS_DONE_SUCCESS }))
      .catch(e => logAndSendError(e, 'error adding confirm'));
  };
};

export const removeMarkAsDone = (item, type) => {
  return (dispatch, getState, { lessonApi }) => {
    return lessonApi
      .removeConfirm(item, type)
      .then(() => dispatch({ type: types.REMOVE_MARK_AS_DONE_SUCCESS }))
      .catch(e => logAndSendError(e, 'error removing confirm'));
  };
};

// make it a factory, so we can configure the function to open a window
export const navigateFactory = openInWindowFn => payload => {
  // console.log('navigateFactory', payload);
  return (dispatch, getState, { apolloClient }) => {
    if (isAssignmentItem(payload)) {
      const item = getItem(apolloClient, payload);
      if (!item) {
        console.warn('Item not found: ', payload);
      } else {
        if (item.assignmentType === 'watch') {
          if (item.resolvedVideo) {
            openInModal(item);
          } else {
            // legacy programs had their external urls in videoUrl
            // cms programs can use videoUrl for assets only
            // and externalUrl for youtube, ..
            openNewBrowser(item.videoUrl || item.externalUrl);
          }

          // don't navigate to lesson view
          return;
        }

        if (
          item.assignmentType === 'read' ||
          item.assignmentType === 'listen'
        ) {
          openNewBrowser(item.externalUrl);
          return;
        }

        if (item.assignmentType === 'iframe') {
          openIFrame(item);
          return;
        }
      }
    }
    dispatch(lessonActions.gotoLesson(payload, ACTION_ID.SCROLL_TO_ASSIGNMENT));

    // -------------------------------------

    function openInModal(item) {
      const state = getState();
      const resolveAsset = getSyllabusResolveAssetFn(state);
      resolveSubTitles(resolveAsset, [item]).then(() => {
        dispatch(relatedVideoActions.showPlayer(item.title, item));
      });
    }

    function resolveSubTitles(resolveAssetFn, videos) {
      const subTitles = (videos || []).reduce((acc, v) => {
        if (v && v.subTitles) {
          v.subTitles.forEach(s => acc.push(s));
        }
        return acc;
      }, []);

      return Promise.all(
        subTitles.map(async s => {
          s.originalName = s.url;
          s.url = await resolveAssetFn(s.url);
          // console.log('resolved subtitle', s.url);
          return s;
        })
      );
    }

    function isAssignmentItem(payload) {
      return payload && payload.itemType === 'assignment';
    }

    function openNewBrowser(url) {
      if (url && url.indexOf('http') === 0) {
        openInWindowFn(url);
      } else {
        console.warn('ignoring local videoUrl: ', url);
      }
    }

    function openIFrame(assignment) {
      let url = assignment.externalUrl;
      if (url && url.indexOf('http') === 0) {
        showIframe(assignment.externalUrl, assignment);
        return;
      }
      url = assignment.instructionsPdfUrl;
      if (url) {
        const state = getState();
        const resolveAsset = getSyllabusResolveAssetFn(state);
        resolveAsset(url).then(resolvedUrl => {
          if (resolvedUrl) {
            showIframe(resolvedUrl, assignment);
          }
        });
        return;
      }
      throw new Error('iframe assignment is missing url');

      function showIframe(url, assignment) {
        dispatch(
          showIframeAction({
            src: url,
            superTitle: 'Assignment',
            title: assignment.title,
            description: assignment.description
          })
        );
      }
    }

    function getItem(apolloClient, payload) {
      return apolloClient.readFragment({
        id: 'SyllabusAssignment:' + payload.itemId,
        fragment: gql`
          fragment a on SyllabusAssignment {
            annotations {
              ${ANNOTATION_FRAGMENT}
            }
            assignmentType
            assignmentTypeLabel
            description
            externalUrl
            isCrewAssignment
            instructionsPdfUrl
            hideSubmitButton
            transscriptUrl
            title
            videoUrl
            ${RESOLVED_VIDEO_FRAGMENT}
            ${SUBTITLES_FRAGMENT}
          }
        `
      });
    }
  };
};

export const openThisWeek = () => {
  return openWeekContainingDate(new Date());
};

export const openWeekContainingDate = date => {
  return (dispatch, getState) => {
    let state = getState();
    let syllabus = getSyllabusData(state);
    let week = findWeekForDate(syllabus, date);
    if (week) {
      dispatch({ type: types.OPEN_THIS_WEEK, weekIndex: week.index });
    }
  };
};

export const previewSyllabus = (contentId, programId) => ({
  type: types.PREVIEW_SYLLABUS,
  contentId,
  programId
});

export const openWeek = weekIndex => ({
  type: types.OPEN_THIS_WEEK,
  weekIndex
});

export const toggleHelpModal = text => ({
  type: types.HELP_MODAL_TOGGLE,
  text
});

export const toggleSubmitProject = (item, itemType) => ({
  type: types.SUBMIT_PROJECT_MODAL_TOGGLE,
  item,
  itemType
});

export const actions = {
  loadSyllabus,
  loadSyllabusFailure,
  loadSyllabusStart,
  loadSyllabusSuccess,
  markAsDone,
  navigate: navigateFactory(window.open),
  openThisWeek,
  openWeek,
  previewSyllabus,
  removeMarkAsDone,
  toggleHelpModal,
  toggleSubmitProject
};

// ------------------------------------------------

const defaultValueArr = [];
const defaultValueObj = {};

function getSyllabus(state) {
  return state.dashboard.syllabus;
}

function getSyllabusData(state) {
  let syllabus = getSyllabus(state);
  return (syllabus && syllabus.data) || defaultValueObj;
}

function resolveToUndefined(/*name*/) {
  console.warn(
    'resolveAsset not set (yet), this function resolves to undefined'
  );
  // whatever is passed in as name, we will always return undefined
  return undefined;
}

function getSyllabusResolveAssetFn(state) {
  let syllabus = getSyllabus(state);
  return (syllabus && syllabus.resolveAsset) || resolveToUndefined;
}

function getSyllabusSpec(state) {
  return state.dashboard.syllabusSpec;
}

function getSyllabusWeeks(state) {
  let syllabus = getSyllabusData(state);
  return syllabus.weeks || defaultValueArr;
}

function getSyllabusWeek(state, weekIndex) {
  let weeks = getSyllabusWeeks(state);
  return weeks[weekIndex];
}

function getSyllabusOpenedWeekIndex(state) {
  let syllabus = getSyllabus(state);

  return syllabus.openedWeek;
}

function getSyllabusOpenedWeek(state) {
  let syllabus = getSyllabus(state);

  return syllabus.weeks[syllabus.openedWeek] || defaultValueObj;
}

function getSubmitProjectModalStatus(state) {
  const syllabus = getSyllabus(state);

  return syllabus.submitProjectOpened;
}

function getHelpModalStatus(state) {
  const syllabus = getSyllabus(state);

  return syllabus.helpModal;
}

function getSyllabusWeekIsOpen(state, weekIndex) {
  let syllabus = getSyllabus(state);

  return syllabus.openedWeek === weekIndex;
}

function getSyllabusWeekLessons(state, weekIndex) {
  let week = getSyllabusWeek(state, weekIndex);
  return (week && week.lessons) || defaultValueArr;
}

function getSyllabusWeekLesson(state, weekIndex, lessonId) {
  let lessons = getSyllabusWeekLessons(state, weekIndex);
  return lessons[lessonId] || defaultValueObj;
}

export const selectors = {
  getSyllabus,
  getSyllabusData,
  getSyllabusResolveAssetFn,
  getSyllabusSpec,
  getSyllabusWeek,
  getSyllabusWeeks,
  getSyllabusWeekIsOpen,
  getSyllabusOpenedWeekIndex,
  getSyllabusOpenedWeek,
  getSyllabusWeekLesson,
  getSyllabusWeekLessons,
  getSubmitProjectModalStatus,
  getHelpModalStatus
};
