export const ADD_PROJECT_TO_USER_PROGRAM = 'ADD_PROJECT_TO_USER_PROGRAM';
export const clickAddPin = 'ADD_PIN';
export const addTextComment = 'ADD_TEXT_COMMENT';
export const addTextReply = 'ADD_TEXT_REPLY';
export const addVideoComment = 'ADD_VIDEO_COMMENT';
export const addVideoReply = 'ADD_VIDEO_REPLY';
export const APPLY_USER_TO_PROGRAM = 'APPLY_USER_TO_PROGRAM';
export const APPLY_USER_TO_PROGRAM_COMPLETE = 'APPLY_USER_TO_PROGRAM_COMPLETE';
export const APPLY_USER_TO_PROGRAM_ERROR = 'APPLY_USER_TO_PROGRAM_ERROR';
export const cancelAddingComment = 'CANCEL_ADDING_COMMENT';
export const cancelEditingComment = 'CANCEL_EDITING_COMMENT';
export const cancelEditingReply = 'CANCEL_EDITING_REPLY';
export const cancelRecordVideo = 'CANCEL_RECORD_VIDEO';
export const cancelReplying = 'CANCEL_REPLYING';
export const cancelShareMedia = 'CANCEL_SHARE_MEDIA';
export const clearAnnotation = 'CLEAR_ANNOTATION';
export const COMMENT_SECTION_LOADED = 'COMMENT_SECTION_LOADED';
export const COMMENT_SECTION_START_LOADING = 'COMMENT_SECTION_START_LOADING';
export const connectionClosed = 'CONNECTION_CLOSED';
export const connectionOpened = 'CONNECTION_OPENED';
export const createUser = 'CREATE_USER_REQUESTED';
export const createUserComplete = 'CREATE_USER_COMPLETE';
export const createUserError = 'CREATE_USER_ERROR';
export const createUserSendEmailVerification =
  'CREATE_USER_SEND_VERIFICATION_EMAIL';
export const createUserSendEmailVerificationComplete =
  'CREATE_USER_SEND_VERIFICATION_EMAIL_COMPLETE';
export const createUserSendEmailVerificationError =
  'CREATE_USER_SEND_VERIFICATION_EMAIL_ERROR';
export const deleteComment = 'DELETE_COMMENT';
export const deleteReply = 'DELETE_REPLY';
export const deleteSelectedAnnotation = 'DELETE_SELECTED_ANNOTATION';
export const dismissEmailVerificationWarning =
  'DISMISS_EMAIL_VERIFICATION_WARNING';
export const firebaseUp = 'FIREBASE_UP';
export const gotoComment = 'GOTO_COMMENT';
export const gotoNextComment = 'GOTO_NEXT_COMMENT';
export const gotoPreviousComment = 'GOTO_PREVIOUS_COMMENT';
export const gotoTimecode = 'GOTO_TIMECODE';
export const playCommentRange = 'PLAY_COMMENT_RANGE';
export const playVideoRange = 'PLAY_VIDEO_RANGE';
export const initApp = 'INIT_APP';
export const INSERT_USER_DATA = 'INSERT_USER_DATA';
export const INSERT_ENTITY_COMMENTS = 'INSERT_ENTITY_COMMENTS';
export const INSERT_ENTITY_COUNTS = 'INSERT_ENTITY_COUNTS';
export const INSERT_ENTITY_DISCUSSION = 'INSERT_ENTITY_DISCUSSION';
export const INSERT_ENTITY_DISCUSSIONS = 'INSERT_ENTITY_DISCUSSIONS';
export const INSERT_ENTITY_IMAGEPROJECTS = 'INSERT_ENTITY_IMAGEPROJECTS';
export const INSERT_ENTITY_INTRO_VIDEOS = 'INSERT_ENTITY_INTROVIDEOS';
export const INSERT_ENTITY_DISCUSSIONS_LAST_READS = 'INSERT_ENTITY_LASTREADS';
export const INSERT_ENTITY_DISCUSSIONS_MESSAGES =
  'INSERT_ENTITY_DISCUSSIONS_MESSAGES';
export const INSERT_ENTITY_LAST_READ = 'INSERT_ENTITY_LAST_READ';
export const INSERT_ENTITY_PROGRAM_INSTANCES = 'INSERT_ENTITY_PROGRAMINSTANCES';
export const INSERT_ENTITY_PROGRAMS = 'INSERT_ENTITY_PROGRAMS';
export const INSERT_ENTITY_PROJECTS = 'INSERT_ENTITY_PROJECTS';
export const INSERT_ENTITY_RELEASES = 'INSERT_ENTITY_RELEASES';
export const INSERT_ENTITY_REPLIES = 'INSERT_ENTITY_REPLIES';
export const INSERT_ENTITY_SCREENPLAYS = 'INSERT_ENTITY_SCREENPLAYS';
export const INSERT_ENTITY_USER_PROGRAMS = 'INSERT_ENTITY_USERPROGRAMS';
export const INSERT_ENTITY_USERS = 'INSERT_ENTITY_USERS';
export const INSERT_USER_PROGRAM = 'INSERT_USER_PROGRAM';
export const insertComment = 'INSERT_COMMENT';
export const insertCounts = 'INSERT_COUNTS';
export const insertProgram = 'INSERT_PROGRAM';
export const insertProgramInstance = 'INSERT_PROGRAM_INSTANCE';
export const insertProject = 'INSERT_PROJECT';
export const insertRelease = 'INSERT_RELEASE';
export const insertReply = 'INSERT_REPLY';
export const insertUser = 'INSERT_USER';
export const LOAD_ONCE_FROM_DATABASE = 'LOAD_ONCE_FROM_DATABASE';
export const LOAD_ONCE_FROM_DATABASE_COMPLETE =
  'LOAD_ONCE_FROM_DATABASE_COMPLETE';
export const LOAD_ONCE_FROM_DATABASE_ERROR = 'LOAD_ONCE_FROM_DATABASE_ERROR';
export const loadAnnotation = 'LOAD_ANNOTATION';
export const loadAnnotationComplete = 'LOAD_ANNOTATION_COMPLETE';
export const loadAppSettingsData = 'LOAD_APP_SETTINGS_DATA';
export const loadAppSettingsDataComplete = 'LOAD_APP_SETTINGS_DATA_COMPLETE';
export const loadInitialData = 'LOAD_INITIAL_DATA';
export const MARKER_CLICK = 'MARKER_CLICK';
export const MARKER_HIDE = 'MARKER_HIDE';
export const MARKER_SHOW = 'MARKER_SHOW';
export const messageReceived = 'MESSAGE_RECEIVED';
export const playerIsPaused = 'PLAYER_IS_PAUSED';
export const playerIsPlaying = 'PLAYER_IS_PLAYING';
export const playerReady = 'PLAYER_READY';
export const playerResized = 'PLAYER_RESIZED';
export const playVideoComment = 'PLAY_VIDEO_COMMENT';
export const playVideoReply = 'PLAY_VIDEO_REPLY';
export const INSERT_ENTITY_NEW_DISCUSSIONS_MESSAGE =
  'INSERT_ENTITY_NEW_DISCUSSIONS_MESSAGE';
export const REMOVE_ENTITY_DISCUSSIONS_MESSAGE =
  'REMOVE_ENTITY_DISCUSSIONS_MESSAGE';
export const REMOVE_ENTITY_COMMENTS = 'REMOVE_ENTITY_COMMENTS';
export const REMOVE_ENTITY_COUNTS = 'REMOVE_ENTITY_COUNTS';
export const REMOVE_ENTITY_DISCUSSIONS = 'REMOVE_ENTITY_DISCUSSIONS';
export const REMOVE_ENTITY_INTRO_VIDEOS = 'REMOVE_ENTITY_INTROVIDEOS';
export const REMOVE_ENTITY_IMAGEPROJECTS = 'REMOVE_ENTITY_IMAGEPROJECTS';
export const REMOVE_ENTITY_LAST_READS = 'REMOVE_ENTITY_LASTREADS';
export const REMOVE_ENTITY_MESSAGES = 'REMOVE_ENTITY_MESSAGES';
export const REMOVE_ENTITY_PROGRAM_INSTANCES = 'REMOVE_ENTITY_PROGRAMINSTANCES';
export const REMOVE_ENTITY_PROGRAMS = 'REMOVE_ENTITY_PROGRAMS';
export const REMOVE_ENTITY_PROJECTS = 'REMOVE_ENTITY_PROJECTS';
export const REMOVE_ENTITY_RELEASES = 'REMOVE_ENTITY_RELEASES';
export const REMOVE_ENTITY_REPLIES = 'REMOVE_ENTITY_REPLIES';
export const REMOVE_ENTITY_SCREENPLAYS = 'REMOVE_ENTITY_SCREENPLAYS';
export const REMOVE_ENTITY_USER_PROGRAMS = 'REMOVE_ENTITY_USERPROGRAMS';
export const REMOVE_ENTITY_USERS = 'REMOVE_ENTITY_USERS';
export const REMOVE_USER_PROGRAM = 'REMOVE_USER_PROGRAM';
export const removeComment = 'REMOVE_COMMENT';
export const removeCounts = 'REMOVE_COUNTS';
export const removeProgram = 'REMOVE_PROGRAM';
export const removeProgramInstance = 'REMOVE_PROGRAM_INSTANCE';
export const removeProject = 'REMOVE_PROJECT';
export const removeRelease = 'REMOVE_RELEASE';
export const removeReply = 'REMOVE_REPLY';
export const removeUser = 'REMOVE_USER';
export const replayAction = 'REPLAY_ACTION';
export const resetPoints = 'RESET_POINTS';
export const resetPassword = 'RESET_PASSWORD';
export const resetPasswordComplete = 'RESET_PASSWORD_COMPLETE';
export const resetPasswordError = 'RESET_PASSWORD_ERROR';
export const resetPasswordFlow = 'RESET_PASSWORD_FLOW';
export const saveAnnotation = 'SAVE_ANNOTATION';
export const saveRecordedVideo = 'SAVE_RECORDED_VIDEO';
export const SEARCH_COMMENTS = 'SEARCH_COMMENTS';
export const SEEK_START = 'SEEK_START';
export const seekVideoPosition = 'SEEK_VIDEO_POSITION';
export const selectAnnotationTool = 'SELECT_ANNOTATION_TOOL';
export const selectProject = 'SELECT_PROJECT';
export const setAnnotationColor = 'SET_ANNOTATION_COLOR';
export const setInPoint = 'SET_IN_POINT';
export const setOutPoint = 'SET_OUT_POINT';
export const shareMedia = 'SHARE_MEDIA';
export const startAddingComment = 'START_ADDING_COMMENT';
export const startAnnotating = 'START_ANNOTATING';
export const startEditingComment = 'START_EDITING_COMMENT';
export const startEditingReply = 'START_EDITING_REPLY';
export const startRecordingVideo = 'START_RECORDING_VIDEO';
export const startReplying = 'START_REPLYING';
export const stopAnnotating = 'STOP_ANNOTATING';
export const submitEditedComment = 'SUBMIT_EDITED_COMMENT';
export const submitEditedReply = 'SUBMIT_EDITED_REPLY';
export const toggleAnnotating = 'TOGGLE_ANNOTATING';
export const toggleIsPlaying = 'TOGGLE_IS_PLAYING';
export const UNLOAD_COMMENT_SECTION = 'UNLOAD_COMMENT_SECTION';
export const UPDATE_ENTITY_COMMENTS = 'UPDATE_ENTITY_COMMENTS';
export const UPDATE_ENTITY_COUNTS = 'UPDATE_ENTITY_COUNTS';
export const UPDATE_ENTITY_DISCUSSIONS = 'UPDATE_ENTITY_DISCUSSIONS';
export const UPDATE_ENTITY_DISCUSSIONS_MESSAGE =
  'EDIT_ENTITY_DISCUSSIONS_MESSAGE';
export const UPDATE_ENTITY_IMAGEPROJECTS = 'UPDATE_ENTITY_IMAGEPROJECTS';
export const UPDATE_ENTITY_INTRO_VIDEOS = 'UPDATE_ENTITY_INTROVIDEOS';
export const UPDATE_ENTITY_LAST_READ = 'UPDATE_ENTITY_LASTREAD';
export const UPDATE_ENTITY_MESSAGES = 'UPDATE_ENTITY_MESSAGES';
export const UPDATE_ENTITY_PROGRAM_INSTANCES = 'UPDATE_ENTITY_PROGRAMINSTANCES';
export const UPDATE_ENTITY_PROGRAMS = 'UPDATE_ENTITY_PROGRAMS';
export const UPDATE_ENTITY_PROJECTS = 'UPDATE_ENTITY_PROJECTS';
export const UPDATE_ENTITY_RELEASES = 'UPDATE_ENTITY_RELEASES';
export const UPDATE_ENTITY_REPLIES = 'UPDATE_ENTITY_REPLIES';
export const UPDATE_ENTITY_SCREENPLAYS = 'UPDATE_ENTITY_SCREENPLAYS';
export const UPDATE_ENTITY_USER_PROGRAMS = 'UPDATE_ENTITY_USERPROGRAMS';
export const UPDATE_ENTITY_USER = 'UPDATE_ENTITY_USER';
export const UPDATE_ENTITY_USERS = 'UPDATE_ENTITY_USERS';
export const UPDATE_USER_APPLICATION = 'UPDATE_USER_APPLICATION';
export const UPDATE_USER_APPLICATION_FAIL = 'UPDATE_USER_APPLICATION_FAIL';
export const UPDATE_USER_APPLICATION_SUCCESS =
  'UPDATE_USER_APPLICATION_SUCCESS';
export const UPDATE_USER_PROGRAM = 'UPDATE_USER_PROGRAM';
export const UPDATE_USER_PROGRAM_DETAILS = 'UPDATE_USER_PROGRAM_DETAILS';
export const UPDATE_USER_PROGRAM_DETAILS_FAIL =
  'UPDATE_USER_PROGRAM_DETAILS_FAIL';
export const UPDATE_USER_PROGRAM_DETAILS_SUCCESS =
  'UPDATE_USER_PROGRAM_DETAILS_SUCCESS';
export const UPDATE_PROJECT_STATUS_IN_SYLLABUS =
  'UPDATE_PROJECT_STATUS_IN_SYLLABUS';
export const updateComment = 'UPDATE_COMMENT';
export const updateCounts = 'UPDATE_COUNTS';
export const updateCurrentTime = 'UPDATE_CURRENT_TIME';
export const updateDuration = 'UPDATE_DURATION';
export const updatePin = 'UPDATE_PIN';
export const updateProgram = 'UPDATE_PROGRAM';
export const updateProgramInstance = 'UPDATE_PROGRAM_INSTANCE';
export const updateProject = 'UPDATE_PROJECT';
export const updatePushPermissionRequired = 'UPDATE_PUSH_PERMISSION_REQUIRED';
export const updateRelease = 'UPDATE_RELEASE';
export const updateReply = 'UPDATE_REPLY';
export const updateUser = 'UPDATE_USER';
export const updateUserSettings = 'UPDATE_USER_SETTINGS';
export const updateVideoUploadProgress = 'UPDATE_VIDEO_UPLOAD_PROGRESS';
export const USER_PROGRAM_LOADED = 'USER_PROGRAM_LOADED';
export const USER_PROGRAMS_LOADED = 'USER_PROGRAMS_LOADED';
export const VIDEO_SKIP_BACKWARDS = 'VIDEO_SKIP_BACKWARDS';
export const VIDEO_SKIP_FORWARD = 'VIDEO_SKIP_FORWARD';
export const VIDEO_SKIP_STOP = 'VIDEO_SKIP_STOP';
export const videoCommentEnded = 'VIDEO_COMMENT_ENDED';
export const LOCATION_CHANGE = 'LOCATION_CHANGE';
