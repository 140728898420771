import { actionType } from '../constants';
import { logAndSendError } from 'utils/sentryHelper';
import { getCurrentUserProgram } from 'smashcut-client-lib/selectors';
import { getFileType } from '../../utils/getFileType';

export const selectProject = (id, isReplay) => {
  return (dispatch, getState, { api }) => {
    let fullState = getState();
    const currentUserProgram = getCurrentUserProgram(fullState);
    const currentUserProgramId = currentUserProgram && currentUserProgram.id;
    if (fullState.currentProjectId === id) {
      return;
    }

    api
      .loadMyFile(id, currentUserProgramId)
      .then(prj => {
        if (prj.isPreloaded) {
          const fileType = getFileType(prj.baseUrl);
          dispatch({
            type: actionType.selectProject,
            id,
            isReplay: !!isReplay,
            project: {
              ...prj,
              baseUrl: prj.baseUrl,
              downloadUrl: prj.baseUrl,
              fileType
            }
          });
          return;
        }
        dispatch({
          type: actionType.selectProject,
          id,
          isReplay: !!isReplay,
          project: prj
        });
      })
      .catch(e => logAndSendError(e, `error loading project ${id}`));
  };
};
