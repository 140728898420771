import { gql } from '@apollo/client';

export function addEnrollee(apolloClient) {
  const ADD_ENROLLEE = gql`
    mutation addEnrollee($input: AddEnrolleeInput!) {
      addEnrollee(input: $input) {
        id
        userId
        program {
          id
        }
      }
    }
  `;

  return async input => {
    console.info('addEnrollee', input);
    const result = await apolloClient.mutate({
      mutation: ADD_ENROLLEE,
      variables: {
        input
      },
      refetchQueries: ['getAdminData', 'adminUsers']
    });
    return result.data.addEnrollee;
  };
}
