import { isEqual } from 'lodash';
import { actionType } from '../smashcut-client-lib/constants';
import { types as screenplayTypes } from 'reducers/screenplayView';
const PREFIX = 'LESSON_TAB';

const types = {
  SET_TAB: `${PREFIX}/SET_TAB`
};

const INFO = 0;
const COMMENTS = 1;
const ASSIGNMENTS = 2;
const SUPPLEMENTALS = 3;

const initialState = {
  tabIndex: INFO
};

export const lessonTabReducer = (state = initialState, action) => {
  let nextState = state;

  switch (action.type) {
    case actionType.gotoComment:
    case actionType.addTextComment:
    case actionType.addVideoComment:
    case screenplayTypes.SELECT_CHUNK:
    case screenplayTypes.SELECT_NEXT_CHUNK:
    case screenplayTypes.SELECT_PREV_CHUNK:
      if (state.tabIndex != COMMENTS) {
        nextState = { ...state, tabIndex: COMMENTS };
      }
      break;

    case types.SET_TAB:
      if (state.tabIndex !== action.tabIndex) {
        nextState = { ...state, tabIndex: action.tabIndex };
      }
      break;
  }

  return nextState;
};

function syncWithUrl(params) {
  const tabIndex = getIndexForLocation(getLocation(params));
  return { type: types.SET_TAB, tabIndex };
}

function setTabIndex(tabIndex, params, history) {
  return (dispatch, getState) => {
    const state = getState();
    const lessonTab = getLessonTab(state);
    if (lessonTab.tabIndex != tabIndex) {
      history.replace(getLocationForIndex(params, tabIndex));
      dispatch({ type: types.SET_TAB, tabIndex });
    }
  };
}

export const actions = {
  setTabIndex,
  syncWithUrl
};

function getLessonTab(state) {
  return state.lessonTab;
}

export const selectors = {
  getLessonTab
};

function getLocationForIndex(params, index) {
  const { programId, courseId, lessonId, mainAreaType, mainAreaId } = params;
  const prefix = `/program/${programId}/course/${courseId}/lesson/${lessonId}/${mainAreaType}/${mainAreaId}`;
  switch (index) {
    case INFO:
      return prefix + '/info';
    case COMMENTS:
      return prefix + '/comments';
    case ASSIGNMENTS:
      return prefix + '/assignments';
    case SUPPLEMENTALS:
      return prefix + '/supplementals';
  }
}

function getLocation(params) {
  // console.log('getLocation', params)
  return params.lessonTab || params.itemType;
}

function getIndexForLocation(location) {
  switch (location) {
    case 'comment':
    case 'comments':
      return COMMENTS;
    case 'assignment':
    case 'assignments':
      return ASSIGNMENTS;
    case 'supplemental':
    case 'supplementals':
      return SUPPLEMENTALS;

    default:
      return INFO;
  }
}
