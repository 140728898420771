export const footerLinks = {
  copyrightOwner: 'ATI',
  facebookUrl: 'https://www.facebook.com/thoughtandindustry',
  flickrUrl: '',
  instagramUrl: 'https://www.instagram.com/thoughtandindustry',
  mediumUrl: '',
  privacyPolicyUrl: 'https://www.smashcut.com/privacy',
  termsOfServiceUrl: 'https://www.smashcut.com/terms',
  twitterUrl: '',
  vimeoUrl: '',
  youtubeUrl: ''
};

export const getInTouch = `If you have questions, you can get in touch
      with a student advisor by emailing help@smashcut.com or by clicking the
      "Help Chat" button in the header of any Smashcut webpage.`;

export const helpCenterUrl = 'https://intercom.help/smashcut';

export const highlightColor = '#5F95AF';

export const showDisciplines = false;

export const showProgramStartDate = true;

export const useScreenplayForPdfUploads = false;

export const showLms = true;

export const topNavLinks = {
  theme: 'ati',
  blog: {
    blogComponent: false,
    blogUrl: 'https://medium.com/smashcut-film'
  },
  helpCenter: {
    helpCenterKeyMentor: 'help center',
    helpCenterTitleMentor: 'Help Center',
    helpCenterUrlMentor: 'https://intercom.help/smashcut',
    helpCenterKey: 'help center',
    helpCenterTitle: 'Help Center',
    helpCenterUrl: 'https://intercom.help/smashcut'
  },
  availability: {
    availabilityComponent: false
  }
};

export const requiredAccountDetailsFields = {};

export const minHoursBeforeBookingSlotAvailable = 24;

export const preStartModules = {};
