import { accountDetailsSaga } from './sagas/accountDetailsSaga';
import { all } from 'redux-saga/effects';
import { availabilitySaga } from './sagas/scheduleAvailability';
import { bookingSaga } from './sagas/scheduleBookings';
import { crewsSaga } from './sagas/crewSaga';
import { fileDetailsSaga } from './sagas/fileDetailsSaga';
import { flatten } from 'lodash';
import { lessonSagas } from 'sagas/lessonSagas';
import { markerClickSaga } from 'sagas/markerClickSaga';
import { notesSaga } from 'sagas/notesSaga';
import { projectsForReviewSagas } from 'sagas/projectsForReviewSagas';
import { shareableFilesSaga } from 'sagas/shareableFilesSaga';
import { videoConferenceSaga } from 'sagas/videoConferenceSaga';
import { deleteUserSaga } from './admin/sagas/deleteUserSaga';

export const allSagas = () => {
  return function*(context) {
    yield all(
      flatten([
        accountDetailsSaga,
        availabilitySaga,
        bookingSaga,
        crewsSaga,
        deleteUserSaga,
        fileDetailsSaga,
        lessonSagas,
        markerClickSaga,
        notesSaga,
        projectsForReviewSagas,
        shareableFilesSaga,
        videoConferenceSaga
      ]).map(s => s(context))
    );
  };
};
