import moment from 'moment';

function validateEmail(email) {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

const validatePassword = pw => pw && pw.length >= 6;

const validateUrl = url => {
  const re = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gm;
  url = String(url).toLowerCase(url);
  return url === undefined || re.test(url);
};

const validatePhone = number => {
  if (number[0] === '+') {
    number = number.substring(1);
  }
  const regex = /^[0-9]+$/;
  return regex.test(number) && number.length >= 10;
};

const validateDateOfBirth = (year, month, day) => {
  const m = moment(`${year}-${month}-${day}`);
  return (
    m.isValid() &&
    m.isAfter(moment('1900-01-01')) &&
    m.isBefore(moment().subtract(10, 'years'))
  );
};

export {
  validateEmail,
  validatePassword,
  validateUrl,
  validatePhone,
  validateDateOfBirth
};
