import { findKey, isEmpty } from 'lodash';

export const DASHBOARD_TAB_INDEX = [
  'ACTIVITIES',
  'PROJECTS_FOR_REVIEW',
  'SYLLABUS',
  'PRE_START',
  'NO_PROGRAM',
  'STUDENTS',
  'PROJECTS',
  'MY_FILES',
  'UPCOMING_LMS',
  'CREWS',
  'CONFERENCES',
  'SCHEDULE_MEETINGS',
  'NOTIFICATIONS',
  'COMMUNITY',
  'DISCUSSIONS'
];

export const DASHBOARD_TAB_PATH = {
  ACTIVITIES: 'activities',
  PROJECTS_FOR_REVIEW: 'projects-for-review',
  SYLLABUS: 'syllabus',
  STUDENTS: 'students',
  PROJECTS: 'projects',
  MY_FILES: 'myfiles',
  UPCOMING_LMS: 'upcoming-video-meetings',
  CREWS: 'crews',
  SCHEDULE_MEETINGS: 'schedule-meetings',
  NOTIFICATIONS: 'notifications',
  DISCUSSIONS: 'discussions',
  PRE_START: 'pre-start',
  CONFERENCES: 'conferences',
  COMMUNITY: 'community',
  NO_PROGRAM: 'welcome'
};

export const getLocationForIndex = (
  index,
  shouldShowNoProgram,
  shouldShowPreProgram,
  isMentor
) =>
  '/dashboard/' + DASHBOARD_TAB_PATH[DASHBOARD_TAB_INDEX[index]] ||
  (() => {
    if (shouldShowNoProgram) {
      return DASHBOARD_TAB_PATH.NO_PROGRAM;
    }
    if (isMentor) {
      return DASHBOARD_TAB_PATH.PROJECTS_FOR_REVIEW;
    }
    if (shouldShowPreProgram) {
      return DASHBOARD_TAB_PATH.PRE_START;
    }
    return DASHBOARD_TAB_PATH.SYLLABUS;
  })();

export const getIndexForLocation = (
  location,
  shouldShowNoProgram,
  shouldShowPreProgram,
  isMentor
) => {
  const index = DASHBOARD_TAB_INDEX.indexOf(
    findKey(DASHBOARD_TAB_PATH, key => key === location)
  );
  return index > 0
    ? index
    : (() => {
        if (shouldShowNoProgram) {
          return DASHBOARD_TAB_INDEX.indexOf('NO_PROGRAM');
        }
        if (isMentor) {
          return DASHBOARD_TAB_INDEX.indexOf('PROJECTS_FOR_REVIEW');
        }
        if (shouldShowPreProgram) {
          return DASHBOARD_TAB_INDEX.indexOf('PRE_START');
        }
        return DASHBOARD_TAB_INDEX.indexOf('SYLLABUS');
      })();
};
