import * as authUtils from 'common/authUtils';
import config from 'config';
import { default as authServiceFactory } from 'smashcut-client-lib/services/authService';
import { ApolloClient, ApolloLink, split } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from 'apollo-link-ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { getRefreshTokenLink } from 'apollo-link-refresh-token';
import { get } from 'lodash';
import axios from 'axios';
import { auth0Lock } from './login/components/Auth0Lock';
import { onError } from 'apollo-link-error';
import { logAndSendError } from './utils/sentryHelper';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.error(`[Network error]: ${networkError}`);
});

let jwtDecode = require('jwt-decode');
let authService;

const getAccessToken = () => authUtils.getToken();
const getRefreshToken = () => authUtils.getRefreshToken();

const loggerLink = new ApolloLink((operation, forward) => {
  const startTime = Date.now();

  const operationType = get(operation, 'query.definitions[0].operation');
  const operationName = get(operation, 'query.definitions[0].name.value') || '';
  const operationName2 =
    get(
      operation,
      'query.definitions[0].selectionSet.selections[0].name.value'
    ) || '';

  const opName = operationName.length > 1 ? operationName : operationName2;

  operation.setContext({
    ...operation.getContext(),
    uri: `${config.apolloUri}?${operationType.charAt(0)}=${opName}`
  });
  return forward(operation).map(result => {
    const ellapsed = Date.now() - startTime;

    if (config.logGraphql && opName !== 'user') {
      // user is called too often
      console.log(operationType, opName, ellapsed, operation.variables, result);
    }
    return result;
  });
});

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  if (!authService) {
    authService = authServiceFactory(config);
  }

  const token = getAccessToken();
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const authLinkNoAuth = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: 'Bearer noAuthRegistrationToken'
    }
  };
});

const subClient = new SubscriptionClient(
  config.wssUrl,
  { lazy: true, reconnect: true },
  null,
  []
);
const wsLink = new WebSocketLink(subClient);

const isTokenValid = token => {
  try {
    const date = new Date(0);
    const decoded = jwtDecode(token);
    date.setUTCSeconds(decoded.exp);
    return date.valueOf() > new Date().valueOf();
  } catch (err) {
    return false;
  }
};

const refreshTokenLink = getRefreshTokenLink({
  authorizationHeaderKey: 'Authorization',
  fetchNewAccessToken: async refreshToken => {
    try {
      const data = await axios.post(
        config.apolloUri,
        {
          query: `
            mutation renewToken($refreshToken: String) {
              renewToken(refreshToken: $refreshToken){
                token
                refreshToken
              }
            }`,
          variables: {
            refreshToken
          }
        },
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
            'Content-Type': 'application/json'
          }
        }
      );
      const response = get(data, 'data.data.renewToken');
      authUtils.updateTokens(response.token, response.refreshToken);
      return response.token;
    } catch (e) {
      throw new Error('Failed to fetch fresh access token');
    }
  },
  getAccessToken,
  getRefreshToken,
  isAccessTokenValid: isTokenValid,
  isUnauthenticatedError: graphQLError => {
    const isAccessTokenExpired = graphQLError.message === 'Not Authorised!';
    const isRefreshTokenExpired = !isTokenValid(getRefreshToken());
    if (isRefreshTokenExpired && authUtils.getUser() && authUtils.getToken()) {
      logAndSendError(graphQLError, 'refresh token expired, user logged out');
      authUtils.signOut();
      window.location = '/';
    }

    return isAccessTokenExpired || false;
  }
});

const refreshTokenLinkAuth0 = getRefreshTokenLink({
  authorizationHeaderKey: 'Authorization',
  fetchNewAccessToken: refreshToken => {
    return new Promise((resolve, reject) => {
      auth0Lock.checkSession({}, function(error, authResult) {
        console.log('checkSession', error, authResult, window.location);
        if (error || !authResult) {
          window.location = '/logout';
          if (error && error.code === 'login_required') {
            reject(new Error('sso: Login required'));
          } else {
            reject(new Error('sso: Failed to fetch fresh access token'));
          }
        } else {
          authUtils.updateTokens(
            authResult.accessToken,
            authResult.refreshToken
          );
          resolve(authResult.accessToken);
        }
      });
    });
  },
  getAccessToken,
  getRefreshToken: () => 'dummy-refresh-token',
  isAccessTokenValid: isTokenValid,
  isUnauthenticatedError: graphQLError => {
    const isAccessTokenExpired = graphQLError.message === 'Not Authorised!';
    console.log('isAccessTokenExpired', isAccessTokenExpired);
    return isAccessTokenExpired;
  }
});

const httpLink = ApolloLink.from([
  loggerLink,
  errorLink,
  config.auth0Enabled ? refreshTokenLinkAuth0 : refreshTokenLink,
  authLink,
  createUploadLink({ uri: config.apolloUri })
]);

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      DiscussionMessage: {
        fields: {
          sharedComment: {
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming);
            }
          }
        }
      }
    }
  }),
  link
});

export const apolloClientNoAuth = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLinkNoAuth.concat(createUploadLink({ uri: config.apolloUri }))
});
